import { Timestamp } from "firebase/firestore";
import { Tutor } from "./tutor.entity";
import { TutorFinancalDetails } from "./tutorFinancalDetails.entity";

export enum WithdrawalRequestStatus {
	PENDING = "Pending",
	REJECTED = "Rejected",
	APPROVED = "Approved",
	APPROVED_ON_STAGING = "ApprovedOnStaging",
}

export type WithdrawalRequest = {
	id: string;
	status: WithdrawalRequestStatus;
	amount: number;
	date: Timestamp;
	userId: string;
	acceptedAt: Timestamp;
	tutor: Tutor;
	tutorFinancalDetails: TutorFinancalDetails;
};
