export function timeAgo(date: Date): string {
	const now = new Date();
	const diffInMs = now.getTime() - date.getTime();

	const secondInMs = 1000;
	const minuteInMs = secondInMs * 60;
	const hourInMs = minuteInMs * 60;
	const dayInMs = hourInMs * 24;
	const yearInMs = dayInMs * 365;

	let duration: number;
	let unit: string;

	if (diffInMs < minuteInMs) {
		duration = Math.round(diffInMs / secondInMs);
		unit = "s";
	} else if (diffInMs < hourInMs) {
		duration = Math.round(diffInMs / minuteInMs);
		unit = "m";
	} else if (diffInMs < dayInMs) {
		duration = Math.round(diffInMs / hourInMs);
		unit = "h";
	} else if (diffInMs < yearInMs) {
		duration = Math.round(diffInMs / dayInMs);
		unit = "d";
	} else {
		duration = Math.round(diffInMs / yearInMs);
		unit = "y";
	}

	return `${duration}${unit} ago`;
}
export function dateToHH_MM(date: Date): string {
	const hours = date.getHours();
	const minutes =
		date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
	const amPm = hours >= 12 ? "pm" : "am";

	// Adjust based on your desired time format (e.g., include seconds)
	const formattedTime = `${hours % 12}:${minutes}${amPm}`;

	return formattedTime;
}

// export function formatDateLabel(date: Date): string {
//   const today = new Date();

//   // Calculate difference in days
//   const diffInDays = Math.floor(
//     (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
//   );

//   // Check for today, yesterday, or current week
//   if (diffInDays === 0) {
//     return 'Today';
//   } else if (diffInDays === 1) {
//     return 'Yesterday';
//   } else if (diffInDays >= 0 && diffInDays < 7) {
//     const weekday = date.toLocaleDateString('en-US', { weekday: 'short' }); // Get weekday name (e.g., Mon, Tue)
//     return weekday;
//   } else {
//     // Fallback for non-current week dates
//     return date.toLocaleDateString('en-US', {
//       day: 'numeric',
//       month: 'numeric',
//       year: 'numeric'
//     }); // Standard date format (e.g., 30/6/2024)
//   }
// }

function getWeek(date: Date): number {
	const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
	const daysSinceFirstDay = Math.floor(
		(date.getTime() - firstDayOfYear.getTime()) / (1000 * 60 * 60 * 24)
	);
	return Math.ceil((firstDayOfYear.getDay() + 1 + daysSinceFirstDay) / 7);
}

export function formatDateLabel(date: Date): string {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	date.setHours(0, 0, 0, 0);

	// Calculate difference in days
	const diffInDays = Math.floor(
		(today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
	);

	// Check for today, yesterday, or current week
	if (diffInDays === 0) {
		return "Today";
	} else if (diffInDays === 1) {
		return "Yesterday";
	} else if (getWeek(today) === getWeek(date)) {
		const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
		return weekday;
	} else {
		// Fallback for non-current week dates
		return date.toLocaleDateString("en-US", {
			day: "numeric",
			month: "numeric",
			year: "numeric",
		});
	}
}

export function getOldestByDate<T extends {}, K extends keyof T>(
  data: T[],
  dateKey: K
): T | undefined {
  if (!data || data.length === 0) {
    return undefined;
  }

  // Initialize with the first element
  let oldestItem = data[0];

  // Iterate through the array and compare dates
  for (let i = 1; i < data.length; i++) {
    if (data[i][dateKey] < oldestItem[dateKey]) {
      oldestItem = data[i];
    }
  }

  return oldestItem;
}
