export function extractFields<T extends {}, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> {
  const pickedObj: Pick<T, K> = {} as Pick<T, K>;
  for (const key of keys) {
    if (obj.hasOwnProperty(key)) {
      pickedObj[key] = obj[key];
    }
  }
  return pickedObj;
}

export function removeFields<T extends {}, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  const filterdObj: Partial<T> = { ...obj };
  for (const key of keys) {
    delete filterdObj[key];
  }
  return filterdObj as Omit<T, K>;
}
