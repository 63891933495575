import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from "@reduxjs/toolkit/query";
import { baseService } from "./base.store";

export const store = configureStore({
	reducer: {
		[baseService.reducerPath]: baseService.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(
			baseService.middleware
		),
});
export type RootState = ReturnType<typeof store.getState>;

export type GenericQueryReturnType<T extends string, D> =
	| {
			data: {
				[key in T]: D;
			};
	  }
	| { error: boolean };

export type GenericUpdateReturnType =
	| {
			data: void;
	  }
	| { error: string };

export type GenericCreateReturnType<T extends string, D> =
	| {
			data: {
				[key in T]: D;
			};
	  }
	| { error: string };
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
