export type Colors =
	| "red"
	| "green"
	| "blue"
	| "yellow"
	| "purple"
	| "pink"
	| "gray";
const getBgColor200 = (color: Colors): string => {
	switch (color) {
		case "yellow":
			return "tw-bg-yellow-200";
		case "blue":
			return "tw-bg-blue-200";
		case "gray":
			return "tw-bg-gray-200";
		case "green":
			return "tw-bg-green-200";
		case "pink":
			return "tw-bg-pink-200";
		case "purple":
			return "tw-bg-pink-200";
		case "red":
			return "tw-bg-red-200";
	}
};
const getBgColor400 = (color: Colors): string => {
	switch (color) {
		case "yellow":
			return "tw-bg-yellow-400";
		case "blue":
			return "tw-bg-blue-400";
		case "gray":
			return "tw-bg-gray-400";
		case "green":
			return "tw-bg-green-400";
		case "pink":
			return "tw-bg-pink-400";
		case "purple":
			return "tw-bg-pink-400";
		case "red":
			return "tw-bg-red-400";
	}
};
const getTextColor800 = (color: Colors): string => {
	switch (color) {
		case "yellow":
			return "tw-text-yellow-800";
		case "blue":
			return "tw-text-blue-800";
		case "gray":
			return "tw-text-gray-800";
		case "green":
			return "tw-text-green-800";
		case "pink":
			return "tw-text-pink-800";
		case "purple":
			return "tw-text-pink-800";
		case "red":
			return "tw-text-red-800";
	}
};

export const tailwindVars = {
	getTextColor800,
	getBgColor200,
	getBgColor400,
};
