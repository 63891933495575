import { Navigate } from 'react-router-dom';
import BookingsFilterForm from '../../components/bookings/bookingsFilterForm';
import BookingsTable from '../../components/bookings/bookingsTable';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import usePagination from '../../customHooks/usePagination.hook';
import { Booking } from '../../entities/booking.entity';
import {
  useCountBookingsQuery,
  useGetBookingsQuery
} from '../../store/booking.store';
import CompleteBookingAdministrationButton from '../../components/bookings/completeAdministration';

const Bookings = () => {
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError
  } = usePagination<Booking>({
    useGetDataQuery: useGetBookingsQuery,
    useCountData: useCountBookingsQuery
  });

  return (
    <div className='page_container'>
      <PageHeader text='Bookings' />
      <div className='mb-3'>
        <BookingsFilterForm
          setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
        />
      </div>
      <div className='mb-3 text-center'>
        <CompleteBookingAdministrationButton />
      </div>
      {isSuccess ? (
        <>
          <BookingsTable bookings={data} />
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            next={next}
            prev={prev}
          />
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Bookings;
