import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TutorTransactionHistory } from "../../entities/transactionHistory.entity";
import { displayDate } from "../../utils/dateHandler.util";
const TutorsTransactionsHistoryTable = ({ transactions } : { transactions: TutorTransactionHistory[] }) => {
    
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>student</th>
                        <th>date</th>
                        <th>amount</th>
                        <th>type</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr key={index} className="text-center">
                            <td><Link to={`/tutors/${transaction.tutor?.id}`} className="text-decoration-none">{transaction.tutor?.name}</Link></td>
                            <td>{displayDate(transaction.date)}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.type?.toLowerCase()}</td>
                            <td>{transaction.status?.toLowerCase()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { transactions.length === 0 ? <Alert variant="info">no matched transactions</Alert> : null}
        </>
    )
}

export default TutorsTransactionsHistoryTable;