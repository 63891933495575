import Cookies from 'js-cookie';
import { Admin } from '../entities/admin.entity';
import { isJsonString } from './util';

export const getLoggedInKey = () => Cookies.get('loggedIn');

export const setLoggedInKey = (loggedInKey: string) =>
  Cookies.set('loggedIn', loggedInKey);

export const removeLoggedInKey = () => Cookies.remove('loggedIn');

type CookiesStorage = {
  'current-admin': Admin;
};
export function setCookieWithKey<K extends keyof CookiesStorage>(
  key: K,
  value: CookiesStorage[K]
) {
  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  Cookies.set(key, stringValue);
}
export function getCookieWithKey<K extends keyof CookiesStorage>(
  key: K
): CookiesStorage[K] {
  const stringValue = Cookies.get(key)!;

  return isJsonString(stringValue) ? JSON.parse(stringValue) : stringValue;
}

// export const getLoggedInKey = () => localStorage.getItem("loggedIn");

// export const setLoggedInKey = (loggedInKey: string) => localStorage.setItem("loggedIn", loggedInKey);

// export const removeLoggedInKey = () => localStorage.removeItem("loggedIn");
// browserStorage.util.js
