import { useEffect, useRef, useState } from 'react';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import { useIntersectionObserver } from '../../../customHooks/useIntersectionOpserver';
import { FaArrowCircleDown } from 'react-icons/fa';
import { Badge } from '../../shared/atoms/badge';
import { scrollToBottom } from '../../../utils/util';

export const NewMsgScrollHelper = () => {
  const { ref, isIntersecting } = useIntersectionObserver();
  const [showButton, setShowButton] = useState(false);
  const {
    states: {
      messages: {
        msgsContainerRef,
        setNewRecivedMessagesCount,
        newRecivedMessagesCount
      }
    }
  } = useChatSupportActiveMessages();

  const handleButtonClick = () => {
    scrollToBottom(msgsContainerRef);
    resetStates();
  };
  const resetStates = () => {
    setShowButton(false);
    setNewRecivedMessagesCount(0);
  };

  useEffect(() => {
    if (isIntersecting && showButton) {
      resetStates();
      return;
    }
    if (newRecivedMessagesCount && !isIntersecting) setShowButton(true);
  }, [isIntersecting, newRecivedMessagesCount, showButton]);
  return (
    <>
      <button
        onClick={handleButtonClick}
        className={`tw-fixed tw-end-10   tw-bottom-20 ${
          showButton
            ? 'tw-w-fit tw-z-20 tw-opacity-1 tw-h-auto'
            : 'tw-w-0 tw-h-0 tw-opacity-0'
        }`}
      >
        <Badge className='tw-px-1 tw-py-0 tw-font-bold' color='red'>
          {newRecivedMessagesCount}
        </Badge>
        <FaArrowCircleDown className=' tw-text-2xl tw-text-gray-600' />
      </button>
      <div ref={ref}></div>
    </>
  );
};
