import { Navigate, useParams } from "react-router-dom"
import { useGetCancellationRequestByIdQuery } from "../../../store/cancellationRequest.store";
import CancellationRequestInfo from "../../../components/cancellationRequests/cancellationRequest/CancellationRequestInfo";
import PageLoading from "../../../components/pageLoading";
import PageHeader from "../../../components/pageHeader";


const CancellationRequestPage = () => {
    const { id = "" } = useParams();
    const { data, isSuccess, isError } = useGetCancellationRequestByIdQuery(id, { skip: !id });
    return (
        isSuccess ? (
            <div className='page_container'>
                <PageHeader text='Cancellation Request' />
                <CancellationRequestInfo request={data.cancellationRequest} />
            </div>
        ) : isError ? 
            <Navigate to="/404"/>
         : <PageLoading />
    )
}
export default CancellationRequestPage;