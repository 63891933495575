import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AvailableSlot } from "../../entities/availableSlot.entity";
import { displayDate } from "../../utils/dateHandler.util";

const AvailableSlotsTable = ({ slots } : { slots: AvailableSlot[] }) => {
    
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>tutor</th>
                        <th>created At</th>
                        <th>date</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    {slots.map((slot, index) => (
                        <tr key={index} className="text-center">
                            <td><Link to={`/tutors/${slot.tutorId}`} className="text-decoration-none">{slot.tutor?.name}</Link></td>
                            <td>{displayDate(slot.createdAt)}</td>
                            <td>{displayDate(slot.date)}</td>
                            <td>{slot.status?.toLowerCase()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { slots.length === 0 ? <Alert variant="info">no bookings</Alert> : null}
        </>
    )
}

export default AvailableSlotsTable;