import { Navigate, useSearchParams } from 'react-router-dom';
import TutorsTransactionsFilterForm from '../../components/TutorsTransactionHistory/tutorsTransactionsHistoryFilterForm';
import TutorsTransactionsHistoryTable from '../../components/TutorsTransactionHistory/tutorsTransactionsHistoryTable';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import usePagination from '../../customHooks/usePagination.hook';
import { useGetTutorsTransactionsQuery, useCountTutorsTransactionsQuery } from '../../store/transactionHistory.store';
import { TutorTransactionHistory } from '../../entities/transactionHistory.entity';

const TutorsTransactionHistory = () => {
    const [searchParams] = useSearchParams();

    const {
        data,
        numberOfPages,
        currentPage,
        next,
        prev,
        updateQuery,
        isSuccess,
        isError
    } = usePagination<TutorTransactionHistory>({
        useGetDataQuery: useGetTutorsTransactionsQuery,
        useCountData: useCountTutorsTransactionsQuery,
        initQuery: { tutorId: searchParams.get('tutorId') || undefined }
    })
    
    return (
        <div className='page_container'>
            <PageHeader text='tutors transactions' />
            <div className='mb-3'>
                <TutorsTransactionsFilterForm
                    setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
                />
            </div>
            {isSuccess ? (
                <>
                    <TutorsTransactionsHistoryTable transactions={data} />
                    <Pagination
                        currentPage={currentPage}
                        numberOfPages={numberOfPages}
                        next={next}
                        prev={prev}
                    />
                </>
            ) : isError ? (
                <Navigate to='/505' />
            ) : (
                <PageLoading />
            )}
        </div>
    );
};

export default TutorsTransactionHistory;
