import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Organization } from "./organization.entity";

export enum VoucherCodeStatus {
    AVAILABLE = "AVAILABLE",
    REDEEMED = "REDEEMED"
}

export type VoucherCode = {
    id: string;
    code: string;
    expiryDate: Timestamp;
    noOfPoints: number;
    redeemedBy?: string;
    redeemedDate?: Timestamp;
    status: VoucherCodeStatus;
    organizationId?: string;
    student?: Pick<Student, "id" | "name" | "email" | "points" | "organizationId"> | null;
    organization?: Organization | null;
}

export type CreateVoucherCode = Pick<VoucherCode, "code" | "expiryDate" | "organizationId" | "noOfPoints">;
