import { useMemo } from 'react';
import { ChatSummary } from '../../../types/chat-support/chat-support.type';
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5';

type ReadStatus = 'admin-notReaded' | 'client-readed' | 'client-notReaded';
type Props = {
  unreadCount: ChatSummary['unreadCount'];
  isActiveChat?: boolean;
};

export const ChatSummaryReadingIcon = ({
  unreadCount,
  isActiveChat
}: Props) => {
  const readStatus = useMemo((): ReadStatus => {
    if (unreadCount.support > 0 && !isActiveChat) return 'admin-notReaded';
    if (unreadCount.user > 0) return 'client-notReaded';
    return 'client-readed';
  }, [unreadCount, isActiveChat]);

  switch (readStatus) {
    case 'admin-notReaded':
      return (
        <span className='  tw-size-[30px] tw-text-xs  flex_center tw-rounded-full tw-bg-red-400 tw-text-white '>
          {unreadCount.support > 10 ? '10+' : unreadCount.support}
        </span>
      );

    case 'client-notReaded':
      return <IoCheckmark className='   tw-text-green-500' />;
    case 'client-readed':
      return <IoCheckmarkDone className='   tw-text-green-500' />;
  }
};
