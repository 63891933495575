import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useAddNewVoucherCodeMutation, useGenerateRandomVouchersCodesByCountQuery } from "../../../store/voucherCode.store";
import { getDateAfterYear } from "../../../utils/dateHandler.util";
import CopyTextToClipBoard from "../../copyTextToCilpboard";

type Props = {
    setHide: () => void
}
type VoucherCodeFormParams = {
    noOfPoints: string;
}
const CreateVoucherCodeForm = ({ setHide} : Props) => {
    const [createVoucherCode, createVoucherCodeResult] = useAddNewVoucherCodeMutation();
    const randomVoucherCode = useGenerateRandomVouchersCodesByCountQuery(1, { refetchOnMountOrArgChange: true });
    
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<VoucherCodeFormParams>();

    const submitHandler : SubmitHandler<VoucherCodeFormParams> = ({ noOfPoints }) => {
        const expiryDate = getDateAfterYear();
        
        if (randomVoucherCode.data && randomVoucherCode.data?.codes.length > 0 )
            createVoucherCode({
                noOfPoints: parseInt(noOfPoints),
                code: randomVoucherCode.data?.codes[0],
                expiryDate
            });
    }

    useEffect(() => {
        if (createVoucherCodeResult.isSuccess)
            setHide()
    }, [createVoucherCodeResult, setHide]);

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <input className={`form-control form-control-lg mb-3 ${errors.noOfPoints ? 'border-danger' : ''}`} 
                type="number"
                placeholder="Enter number of points"
                {...register('noOfPoints', { required: true })}
            />
            { 
                randomVoucherCode.isSuccess ?
                    <CopyTextToClipBoard text={randomVoucherCode.data.codes[0]} />
                : null 
            }
            {
                createVoucherCodeResult.error ? <div className="alert alert-danger mb-3" role="alert">something went wrong</div> : null
            }
            <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg w-25" disabled={createVoucherCodeResult.isLoading}>add</button>
            </div>
        </form>
    )
};

export default CreateVoucherCodeForm;