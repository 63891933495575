import { VoucherCodeStatus } from "../entities/voucherCode.entity";
import { bookingRepository } from "../repositories/booking.repository";
import { sessionRepository } from "../repositories/session.repository";
import { cancellationRequestRepository } from "../repositories/cancellationRequest.repository";
import { organizationRepository } from "../repositories/organization.repository";
import { studentRepository } from "../repositories/student.repository";
import { tutorRepository } from "../repositories/tutor.repository";
import { tutorWithdrawalRequestRepository } from "../repositories/tutorWithdrawalRequest.repository";
import { voucherCodeRepository } from "../repositories/voucherCode.repository";
import { GenericQueryReturnType } from "../store";

export const findStatistics = async (): Promise<
	GenericQueryReturnType<"statistics", any>
> => {
	try {
		const [
			organizationStudents,
			students,
			tutors,
			bookings,
			withdrawalRequests,
			redeemedVouchersCodes,
			cancellationRequests,
			organizations,
			sessions,
		] = await Promise.all([
			studentRepository.count({ organizationId: null }),
			studentRepository.count(),
			tutorRepository.count({ isAccepted: true }),
			bookingRepository.count(),
			tutorWithdrawalRequestRepository.count(),
			voucherCodeRepository.count({
				status: VoucherCodeStatus.AVAILABLE,
			}),
			cancellationRequestRepository.count(),
			organizationRepository.count(),
			sessionRepository.count(),
		]);

		return {
			data: {
				statistics: {
					organizations,
					"organization students": organizationStudents,
					students,
					tutors,
					bookings,
					"withdrawal requests": withdrawalRequests,
					"redeemed vouchers codes": redeemedVouchersCodes,
					"cancellation requests": cancellationRequests,
					sessions,
				},
			},
		};
	} catch (error) {
		console.log(error);
		return {
			error: true,
		};
	}
};
