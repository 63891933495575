import {
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  OrderByDirection,
  Query,
  where as whereQuery
} from 'firebase/firestore';
import { FilterQueryBuilder } from '../firebase/firestore/filterQueryBuilder';
import { Session } from '../entities/session.entity';
import { SessionStatus, SessionType } from '../store/session.store';
import { Repository } from '../firebase/firestore/repository';

export class SessionRepository extends Repository<Session> {
  constructor() {
    super('sessions');
  }
  async findAll(
    where?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<Session[]> {
    const query = await this.buildFilterQuery(where, offset, 10);
    const snapshot = await getDocs(query);
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const filterQuery = await this.buildFilterQuery(where || {});
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }
  private async buildFilterQuery(
    where?: any,
    offset?: string,
    limit?: number
  ): Promise<Query> {
    const queryBuilder = new FilterQueryBuilder(this.collection);
    if (where.type == SessionType.FREE) {
      queryBuilder.where(whereQuery('studentDeductedPoints', '==', 0));
    }
    if (where.type == SessionType.PAID) {
      queryBuilder.orderBy('studentDeductedPoints');
      queryBuilder.where(whereQuery('studentDeductedPoints', '>', 0));
    }
    if (where.status == SessionStatus.BOOKED) {
      queryBuilder.where(whereQuery('bookingId', '!=', null));
    }
    if (where.status == SessionStatus.UNBOOKED) {
      queryBuilder.where(whereQuery('bookingId', '==', null));
    }
    queryBuilder.where(orderBy('date', 'desc'));

    if (limit) queryBuilder.limit(limit);

    if (offset) {
      const document = await getDoc(doc(this.collection, offset));
      if (document.exists()) {
        queryBuilder.afterAt(document);
      }
    }
    return queryBuilder.build();
  }
}
export const sessionRepository = new SessionRepository();
