import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Booking } from "../../entities/booking.entity";
import { displayDate } from "../../utils/dateHandler.util";

const BookingsTable = ({ bookings } : { bookings: Booking[] }) => {
    
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>created At</th>
                        <th>date</th>
                        <th>status</th>
                        <th>tutor</th>
                        <th>student</th>
                        <th>administration</th>
                    </tr>
                </thead>
                <tbody>
                    {bookings.map((booking, index) => (
                        <tr key={index} className="text-center">
                            <td>{displayDate(booking.createdAt)}</td>
                            <td>{displayDate(booking.date)}</td>
                            <td>{booking.bookingStatus?.toLowerCase()}</td>
                            <td><Link to={`/tutors/${booking.tutorId}`} className="text-decoration-none">{booking.tutor?.name}</Link></td>
                            <td><Link to={`/students/${booking.studentId}`} className="text-decoration-none">{booking.student?.name}</Link></td>
                            <td>{booking.isCompletedAdministration == false ? "not completed" : "completed"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { bookings.length === 0 ? <Alert variant="info">no bookings</Alert> : null}
        </>
    )
}

export default BookingsTable;