import { useGenerateRandomVouchersCodesByCountQuery } from "../../../../store/voucherCode.store";
import { Button, Form, InputGroup } from "react-bootstrap";
import CreateOrganizationVoucherCodeForm from "./createOrganizationVoucherCodeForm";
import { useState } from "react";

type Props = {
    setHide: () => void
}
const CreateOrganizationVouchersCodes = ({ setHide } : Props) => {
    const [generateRandomVouchersCodes, setGenerateRandomVouchersCodes] = useState<boolean>();
    const [numberOfVouchersCodes, setNumberOfVouchersCodes] = useState<number>();
    
    const randomVouchersCodes = useGenerateRandomVouchersCodesByCountQuery(numberOfVouchersCodes || 1, { skip: !generateRandomVouchersCodes });
    
    return ( 
        generateRandomVouchersCodes && randomVouchersCodes.isSuccess? 
            <CreateOrganizationVoucherCodeForm handleSuccess={setHide} vouchersCodes={randomVouchersCodes.data.codes}/>
        :
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Enter number of vouchers codes"
                    type="number"
                    onChange={(e) => setNumberOfVouchersCodes(parseInt(e.target.value))}
                />
                <Button variant="outline-secondary" onClick={() => setGenerateRandomVouchersCodes(true)}>genrate</Button>
            </InputGroup>
    )
};

export default CreateOrganizationVouchersCodes;