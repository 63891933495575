import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  isShow: boolean;
  handleClose: () => void;
  children: ReactNode;
  headerBody?: ReactNode;
  footerBody?: ReactNode;
};
export const ModalWrapper = ({
  handleClose,
  children,
  isShow,
  headerBody,
  footerBody
}: Props) => {
  return (
    <Modal show={isShow} onHide={handleClose}>
      <Modal.Header closeButton>
        {headerBody && <Modal.Title>{headerBody}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footerBody && <Modal.Footer>{footerBody}</Modal.Footer>}
    </Modal>
  );
};
