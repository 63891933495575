//  this hook for solving strict mode useEffect run twice

import { EffectCallback, useEffect, useRef } from 'react';

export const useEffectOnce = (effect: EffectCallback) => {
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (isCalledRef.current) return;
    isCalledRef.current = true;
    const cleanUp = effect();
    return () => {
      if (cleanUp) cleanUp();
    };
  }, []);
};
