import { Repository } from "../firebase/firestore/repository";
import { WithdrawalRequest } from "../entities/withdrawalRequest.entity";
import {
	OrderByDirection,
	getCountFromServer,
	getDocs,
} from "firebase/firestore";
import { buildFilterQuery } from "../firebase/firestore/filterQueryBuilder";
import {
	TransactionHistoryType,
	TutorTransactionStatus,
} from "../entities/transactionHistory.entity";

export class TutorWithdrawalRequestRepository extends Repository<WithdrawalRequest> {
	constructor() {
		super("tutorsTransactionHistory");
	}

	async findAll(
		where?: any,
		offset?: string,
		orderBy?: string | [string, OrderByDirection],
		limit?: number
	): Promise<WithdrawalRequest[]> {
		const filterQuery = await buildFilterQuery(
			this.collection,
			{ ...where, type: TutorTransactionStatus.PENDING },
			limit || 10,
			offset,
			orderBy
		);
		const snapshot = await getDocs(filterQuery);
		return snapshot.docs.map((doc) => this.map(doc));
	}

	async count(where?: any): Promise<number> {
		const filterQuery = await buildFilterQuery(this.collection, {
			...where,
			status: TransactionHistoryType.DEBIT,
		});
		const countResult = await getCountFromServer(filterQuery);
		return countResult.data().count;
	}
}
export const tutorWithdrawalRequestRepository =
	new TutorWithdrawalRequestRepository();
