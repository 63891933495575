import { Col } from "react-bootstrap";

type Props = {
    name: string;
    count: number
}
const Statistic = ({ name, count } : Props) => {
    return (
        <Col sm={6} lg={4}>
            <div className="text-center bg-primary py-5 mb-4 text-white">
                <p>{name}</p>
                <p className="fs-5">{count}</p>
            </div>
        </Col>
    )
}

export default Statistic;