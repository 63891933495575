import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import {
	removeLoggedInKey,
	setCookieWithKey,
	setLoggedInKey,
} from "../utils/browserStorage.util";
import { adminRepository } from "../repositories/admin.repository";

export const login = async ({
	email,
	password,
}: {
	email: string;
	password: string;
}): Promise<{ data: undefined } | { error: boolean }> => {
	try {
		await signInWithEmailAndPassword(auth, email, password);
		const isAdmin = await adminRepository.findByEmail(email);

		if (!isAdmin) throw new Error();
		setCookieWithKey("current-admin", isAdmin);
		setLoggedInKey("true");
		return { data: undefined };
	} catch (error) {
		return { error: true };
	}
};
export const logout = async (): Promise<
	{ data: undefined } | { error: boolean }
> => {
	try {
		await signOut(auth);
		removeLoggedInKey();
		return { data: undefined };
	} catch (error) {
		return { error: true };
	}
};
