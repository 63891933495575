import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { FireDoc } from '../types/util.types';

export const fireStoreCollectionName = {
  chat_support: 'chatSupport',
  creditVouchers: 'creditVouchers',
  students: 'students',
  organizations: 'organizations',
  sessions: 'sessions',
  tutor: 'tutors'
};

export const mapDocsWithIdAndRef = <T extends DocumentData>(
  querySnapshot: QuerySnapshot<T>
): FireDoc<T>['DocWithIdAndRef'][] => {
  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    docRef: docSnapshot.ref,
    ...(docSnapshot.data() as T)
  }));
};
