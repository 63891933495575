import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { buildFilterQuery_v2 } from '../../../firebase/firestore/filterQueryBuilder';
import { Student } from '../../../entities/student.entity';
import { FireDoc } from '../../../types/util.types';
import { fireStoreCollectionName } from '../../../utils/firebase.util';
import { VoucherCode } from '../../../entities/voucherCode.entity';

import { Spinner } from '../../../components/shared/atoms/spinner';
import { OrganizationStudents } from '../../../components/students/organization-students/organization-students';
import { Session } from '../../../entities/session.entity';
import { OrganizationStudent } from '../../../types/organization/organization-students.types';

export const OrganizationStudentsPage = () => {
  let { orgId } = useParams();
  const voucherQuery = useMemo(() => {
    const voucherRef = collection(db, fireStoreCollectionName.creditVouchers);
    const voucherQuery = buildFilterQuery_v2<VoucherCode>(
      voucherRef,
      [['organizationId', '==', orgId]],
      undefined,
      undefined,
      ['redeemedBy', 'asc']
    );
    return voucherQuery;
  }, [orgId]);
  const [vouchersSnapshots, loading, error] = useCollectionOnce(voucherQuery);
  const [isLoading, setIsLoading] = useState(true);
  const [studentData, setStudentsData] = useState<OrganizationStudent[]>([]);
  const handleData = useCallback(async () => {
    //  promises creditsVoucher by redeemedby
    if (!vouchersSnapshots) return;

    const studentsPromises = vouchersSnapshots.docs.map(
      async (voucherSnapshot) => {
        // studentDoc
        const studentRef = doc(
          db,
          fireStoreCollectionName.students,
          voucherSnapshot.data().redeemedBy!
        );
        const studentsDoc = await getDoc(studentRef);
        const studentData: FireDoc<Student>['DocWithIdAndRef'] = {
          ...(studentsDoc.data() as Student),
          docRef: studentsDoc.ref,
          id: studentsDoc.id
        };

        const sessionRef = collection(db, fireStoreCollectionName.sessions);
        const sessionQuery = buildFilterQuery_v2<Session>(sessionRef, [
          ['studentId', '==', studentData.id]
        ]);
        const sessionDocs = await getDocs(sessionQuery);
        const sessionSecs = sessionDocs.docs.reduce(
          (prev, next) =>
            prev + (isNaN(+next.data().duration) ? 0 : +next.data().duration),
          0
        );
        const sessionMins = +(sessionSecs / 60).toFixed(2);
        const activationDate = voucherSnapshot
          .data()
          .redeemedDate!.toDate()
          .toLocaleDateString();
        const totalPoints = +voucherSnapshot.data().noOfPoints.toFixed(2);
        const organizationStudent: OrganizationStudent = {
          activationDate,
          minsCount: sessionMins,
          totalPoints,
          ...studentData,
          points: !studentData.points ? 0 : +studentData.points.toFixed(2)
        };
        return organizationStudent;
      }
    );

    const orgnaizationStudetnsData = await Promise.all(studentsPromises);
    //
    setStudentsData(orgnaizationStudetnsData);
    setIsLoading(false);
  }, [vouchersSnapshots]);
  useEffect(() => {
    if (vouchersSnapshots) handleData();
  }, [vouchersSnapshots, handleData]);
  if (!orgId) return null;
  if (isLoading)
    return (
      <div className='flex_center tw-h-full'>
        <Spinner className=' tw-size-20' />
      </div>
    );
  return <OrganizationStudents allStudentsData={studentData} />;
};
