import { Button } from "react-bootstrap";
import { useApplyTutorMutation } from "../../store/tutor.store";
import { Navigate } from "react-router-dom"
const ApproveApplyingTutorButton = ({ tutorId, redirectToTutorPage  }: { tutorId: string, redirectToTutorPage?: boolean }) => {
    const [approveApplyingTutor, approveApplyingTutorResult] = useApplyTutorMutation(); 
    
    const approveApplyingTutorHandler = (id: string) => {
        approveApplyingTutor(id);
    }
    
    return (
        <>
            {
                redirectToTutorPage && approveApplyingTutorResult.isSuccess && <Navigate to={`tutors/${tutorId}`}/> 
            }
            <Button 
                onClick={() => approveApplyingTutorHandler(tutorId)} 
                variant="primary" 
                disabled={approveApplyingTutorResult.isLoading}
            >approve</Button>
        </>
    )
}
export default ApproveApplyingTutorButton;