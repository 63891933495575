import { Button } from "react-bootstrap";
import { useRejectApplyingTutorMutation } from "../../store/tutor.store";

const RejectApplyingTutorButton = ({ tutorId }: { tutorId: string }) => {
    const [rejectApplyingTutor, rejectApplyingTutorResult] = useRejectApplyingTutorMutation(); 
    const rejectAppltingTutorHandler = (id: string) => {
        rejectApplyingTutor(id);
    }
    return (
        <Button 
            onClick={() => rejectAppltingTutorHandler(tutorId)} 
            variant="danger" 
            disabled={rejectApplyingTutorResult.isLoading}
            >reject</Button>
    )
}
export default RejectApplyingTutorButton;