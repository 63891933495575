import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { StudentTransactionHistory } from "../../entities/transactionHistory.entity";
import { displayDate } from "../../utils/dateHandler.util";
const StudentsTransactionsHistoryTable = ({ transactions } : { transactions: StudentTransactionHistory[] }) => {
    
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>student</th>
                        <th>date</th>
                        <th>points</th>
                        <th>type</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr key={index} className="text-center">
                            <td><Link to={`/students/${transaction.student?.id}`} className="text-decoration-none">{transaction.student?.name || "..."}</Link></td>
                            <td>{displayDate(transaction.date)}</td>
                            <td>{transaction.noOfPoints}</td>
                            <td>{transaction.type.toLowerCase()}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { transactions.length === 0 ? <Alert variant="info">no matched transactions</Alert> : null}
        </>
    )
}

export default StudentsTransactionsHistoryTable;