import NavBar from './Navbar';
import SideBar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { ChatNotification } from '../chat-support/organisms/chat-notifcation';
import { Col, Container, Row } from 'react-bootstrap';

const Layout = () => {
  return (
    <div>
      <NavBar />
      <Container fluid>
        <Row>
          <Col lg={3} xl={2} className='d-none d-lg-block sidebar'>
            <SideBar />
          </Col>
          <Col lg={{ span: 9, offset: 3 }} xl={{ span: 10, offset: 2 }}>
            <div>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
      <ChatNotification />
    </div>
  );
};

export default Layout;
