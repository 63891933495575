import { Repository } from '../firebase/firestore/repository';
import { StudentTransactionHistory } from '../entities/transactionHistory.entity';
import {
  getCountFromServer,
  getDocs,
  OrderByDirection,
  where as whereCondation
} from 'firebase/firestore';
import { buildFilterQuery } from '../firebase/firestore/filterQueryBuilder';
export class StudentTransactionHistoryRepository extends Repository<StudentTransactionHistory> {
  constructor() {
    super('transactionHistory');
  }
  async findAll(
    where?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<StudentTransactionHistory[]> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      limit || 10,
      offset,
      orderBy,
      this.builderFilteCondations(where)
    );
    const snapshot = await getDocs(query);
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      undefined,
      undefined,
      ['date', 'desc'],
      this.builderFilteCondations(where)
    );
    const countResult = await getCountFromServer(query);
    return countResult.data().count;
  }
  private builderFilteCondations(where: any): any[] {
    const condations = [];
    if (where.studentId)
      condations.push(whereCondation('userId', '==', where.studentId));
    if (where.type) condations.push(whereCondation('type', '==', where.type));
    if (where.date) {
      condations.push(whereCondation('date', '>=', where.date[0]));
      condations.push(whereCondation('date', '<=', where.date[1]));
    }
    return condations;
  }
}

export const studentTransactionHistoryRepository =
  new StudentTransactionHistoryRepository();
