import { Button } from "react-bootstrap";
import { CancellationRequest, CancellationRequestStatus } from "../../entities/cancellationRequest.entity";
import { useApproveCancellationRequestsMutation } from "../../store/cancellationRequest.store";

interface Props {
    cancellationRequests?: CancellationRequest[];
}

const ApproveAllCancellationRequestsButton = ({ cancellationRequests }: Props) => {
    const [approveCancellationRequests, approveCancellationRequestsResult] = useApproveCancellationRequestsMutation();

    const pendingCancellationRequests = cancellationRequests?.filter(request => request.status === CancellationRequestStatus.PendingCancellationApproval)
        .map(({ id: cancellationRequestId, bookingId }) => ({ cancellationRequestId, bookingId }));

    const handleApproveAll = () => {
        if (pendingCancellationRequests) {
            approveCancellationRequests({ cancellationRequests: pendingCancellationRequests });
        }
    };

    const isDisabled = !pendingCancellationRequests || pendingCancellationRequests.length === 0;

    return (
        <Button 
            variant="primary" 
            size="lg" 
            onClick={handleApproveAll} 
            disabled={isDisabled || approveCancellationRequestsResult.isLoading}
        >
            Approve all
        </Button>
    );
};

export default ApproveAllCancellationRequestsButton;



// import { Button } from "react-bootstrap";
// import { CancellationRequest, CancellationRequestStatus } from "../../entities/cancellationRequest.entity";
// import { useApproveCancellationRequestsMutation } from "../../store/cancellationRequest.store";

// interface Props {
//     cancellationRequests?: CancellationRequest[];
// }

// const ApproveAllCancellationRequestsButton = ({ cancellationRequests }: Props) => {
//     const [approveCancellationRequests, approveCancellationRequestsResult] = useApproveCancellationRequestsMutation();

//     const pendingCancellationRequests = cancellationRequests?.filter(request => request.status === CancellationRequestStatus.PendingCancellationApproval)
//         .map(({ id: cancellationRequestId, bookingId }) => ({ cancellationRequestId, bookingId }));

//     const handleApproveAll = () => {
//         if (pendingCancellationRequests) {
//             approveCancellationRequests({ cancellationRequests: pendingCancellationRequests });
//         }
//     };

//     const isDisabled = !pendingCancellationRequests || pendingCancellationRequests.length === 0;

//     return (
//         <Button 
//             variant="primary" 
//             size="lg" 
//             onClick={handleApproveAll} 
//             disabled={isDisabled}
//         >
//             Approve all
//         </Button>
//     );
// };

// export default ApproveAllCancellationRequestsButton;
