import { Tutor } from "../../../../entities/tutor.entity"
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
type Props = {
    tutors: Tutor[];
    additionalColumnActionType: "select" | "unselect" | "remove";
    additionalColumnAction: (tutor: Tutor) => void;
}
const StudentTutorsTableRows = ({ tutors, additionalColumnAction, additionalColumnActionType }: Props) => {

    return (
        <>
            {
                tutors
                    .map(tutor => (
                        <tr className="text-center" key={tutor.id}>
                            <td data-title="name" className="fs-6">
                                {tutor.name}
                            </td>
                            <td data-title="email" className="fs-6">
                                {tutor.email}
                            </td>
                            <td data-title="phoneNum" className="fs-6">
                                {tutor.mobileNo}
                            </td>
                            <td data-title="show" className="fs-6">
                                <Link to={"/tutors/" + tutor.id}  className="text-decoration-none">show</Link>
                            </td>
                            <td data-title="show" className="fs-6">
                                {
                                    additionalColumnActionType == "select" ?
                                        <Form.Check
                                            type="checkbox"
                                            onChange={() => additionalColumnAction(tutor)}
                                        />
                                    : additionalColumnActionType == "unselect" ?
                                        <Form.Check
                                            type="checkbox"
                                            defaultChecked={true}
                                            onChange={() => additionalColumnAction(tutor)}
                                        />
                                    : additionalColumnActionType == "remove" ? 
                                        <Button variant="danger" onClick={() => additionalColumnAction(tutor)}>
                                            remove
                                        </Button>
                                    : null
                                }
                            </td> 
                        </tr>
                    ))
            }
        </>
    )
}

export default StudentTutorsTableRows;