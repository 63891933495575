import { Navigate, useSearchParams } from 'react-router-dom';
import StudentsTransactionsFilterForm from '../../components/studentsTransactionHistory/studentsTransactionsHistoryFilterForm';
import StudentsTransactionsHistoryTable from '../../components/studentsTransactionHistory/studentsTransactionsHistoryTable';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import usePagination from '../../customHooks/usePagination.hook';
import { useGetStudentsTransactionsQuery, useCountStudentsTransactionsQuery } from '../../store/transactionHistory.store';
import { StudentTransactionHistory } from '../../entities/transactionHistory.entity';

const StudentsTransactionHistory = () => {
    const [searchParams] = useSearchParams();

    const { 
        data,
        numberOfPages,
        currentPage,
        next,
        prev,
        updateQuery,
        isSuccess,
        isError
    } = usePagination<StudentTransactionHistory>({
        useGetDataQuery: useGetStudentsTransactionsQuery,
        useCountData: useCountStudentsTransactionsQuery,
        initQuery: { studentId: searchParams.get('studentId') || undefined }
    })
    
    return (
        <div className='page_container'>
            <PageHeader text='students transactions' />
            <div className='mb-3'>
                <StudentsTransactionsFilterForm
                    setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
                />
            </div>
            {isSuccess ? (
                <>
                    <StudentsTransactionsHistoryTable transactions={data} />
                    <Pagination
                        currentPage={currentPage}
                        numberOfPages={numberOfPages}
                        next={next}
                        prev={prev}
                    />
                </>
            ) : isError ? (
                <Navigate to='/505' />
            ) : (
                <PageLoading />
            )}
        </div>
    );
};

export default StudentsTransactionHistory;
