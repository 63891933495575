import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseService = createApi({
	reducerPath: "baseService",
	baseQuery: fakeBaseQuery(),
	tagTypes: [
		"Admin",
		"Organization",
		"VoucherCode",
		"WithdrawalRequest",
		"CancellationRequest",
		"Booking",
		"PrivateStudent",
		"PrivateTutor",
		"Tutor",
		"Student",
	],
	endpoints: (builder) => ({}),
});
