import { Link } from "react-router-dom";
import { CancellationRequest, CancellationRequestBy } from "../../../entities/cancellationRequest.entity";
import { displayDate } from "../../../utils/dateHandler.util";

const CancellationRequestInfo = ({ request } : { request: CancellationRequest}) => {
    return (
        <div className="user-info">
            <div className="info-row">
                <label className="info-label">Student Name:</label>
                <div className="info-value">
                    <Link to={`/tutors/${request.student?.id}`} className="text-decoration-none">{request.student?.name}</Link>
                </div>
            </div>
            <div className="info-row">
                <label className="info-label">Student Email:</label>
                <span className="info-value">{request.student?.email}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Tutor Name:</label>
                <div className="info-value">
                    <Link to={`/tutors/${request.tutor?.id}`} className="text-decoration-none">{request.tutor?.name}</Link>
                </div>
            </div>
            <div className="info-row">
                <label className="info-label">Tutor Email:</label>
                <span className="info-value">{request.tutor?.email}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Cancelled By:</label>
                <span className="info-value">{request.cancelledBy == CancellationRequestBy.STUDENT ? "STUDENT" : "TUTOR"}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Date:</label>
                <span className="info-value">{displayDate(request.cancellationDate)}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Reason:</label>
                <span className="info-value">{request.cancellationReasonText || request.cancellationReasonOtherText}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Status:</label>
                <span className="info-value">{request.status.toLowerCase()}</span>
            </div>
            {
                request.booking && <>
                <div className="info-row">
                    <label className="info-label">Booking Created At:</label>
                    <span className="info-value">{displayDate(request.booking?.createdAt)}</span>
                </div>
                <div className="info-row">
                    <label className="info-label">Slot Date:</label>
                    <span className="info-value">{displayDate(request.booking.date)}</span>
                </div>
                </>
            }
        </div>
    );
}

export default CancellationRequestInfo;