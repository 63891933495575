import { ReactNode } from 'react';
import { UserGeneralDetails } from '../../../types/chat-support/chat-support.type';

type Props = {
  user: UserGeneralDetails;
  children: ReactNode;
};

export const UserCardWithImageAndName = ({ user, children }: Props) => {
  return (
    <div className=' tw-flex tw-gap-2   tw-items-center '>
      <img
        className=' tw-size-[40px] tw-rounded-full  '
        src={user.profileImage}
        alt='profile_Image'
      />
      <div className='tw-items-center'>
        <p className=' tw-font-bold   tw-text-sm'> {user.name}</p>
        {children}
      </div>
    </div>
  );
};
