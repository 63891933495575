import { createUserWithEmailAndPassword } from "firebase/auth";
import {
	CreateOrganization,
	Organization,
	defaultOrganization,
} from "../entities/organization.entity";
import { organizationRepository } from "../repositories/organization.repository";
import { auth } from "../firebase";
import { GenericCreateReturnType, GenericQueryReturnType } from "../store";

export const createOrganization = async ({
	email,
	name,
	password,
}: CreateOrganization): Promise<
	GenericCreateReturnType<"newOrganization", Organization>
> => {
	try {
		const validationResult = await validateOrganizationParams(email, name);
		if (validationResult?.field) return { error: validationResult.field };
		await createUserWithEmailAndPassword(auth, email, password);
		const newOrganizationId = await organizationRepository.create({
			email,
			name,
			...defaultOrganization,
		});
		return {
			data: {
				newOrganization: {
					id: newOrganizationId,
					email,
					name,
					...defaultOrganization,
				},
			},
		};
	} catch (_) {
		return { error: "unknown" };
	}
};

export const findOrganizations = async (): Promise<
	GenericQueryReturnType<"organizations", Organization[]>
> => {
	try {
		const organizations = await organizationRepository.findAll();
		return { data: { organizations } };
	} catch (_) {
		return { error: true };
	}
};

export const deleteOrganizationById = async (id: string): Promise<boolean> => {
	return true;
};
export const findOrganizationById = async (
	id: string
): Promise<GenericQueryReturnType<"organization", Organization>> => {
	try {
		const organization = await organizationRepository.findById(id);
		if (!organization) return { error: true };

		return { data: { organization } };
	} catch (_) {
		return { error: true };
	}
};

const validateOrganizationParams = async (
	email: string,
	name: string
): Promise<{ field: string } | undefined> => {
	const emailIsUnique = await organizationRepository.checkFieldIsUnique(
		"email",
		email
	);
	if (!emailIsUnique) return { field: "email" };
	const nameIsUnique = await organizationRepository.checkFieldIsUnique(
		"name",
		name
	);
	if (!nameIsUnique) return { field: "name" };
};
