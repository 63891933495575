import { useCallback, useEffect, useState } from 'react';
import { ChatSupport } from '../../../entities/chat-support.entity';
import { Dropdown } from 'react-bootstrap';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { RepoistoryFilter } from '../../../types/util.types';
import { Spinner } from '../../shared/atoms/spinner';
import { SortIconBtn } from '../../shared/atoms/sort-icon.btn';
import { ChatStatusFilter } from '../../../types/chat-support/chat-support.type';

export const ChatNavHeader = () => {
  const [status, setStatus] = useState<ChatStatusFilter>('All');
  const [totalDocsCount, setTotalDocsCount] = useState<number>();
  const {
    actions,
    states: {
      chats: { isfetching, setIsFetching },
      filter: { filter, updateFilter }
    }
  } = useChatSupport();

  const fetchChatData = useCallback(async () => {
    const res = await actions.getChatSummaryies(filter, status);
    if (!res) return;
    setTotalDocsCount(res.totalDocsCount);
  }, [actions, filter, status]);
  useEffect(() => {
    if (isfetching) {
      fetchChatData();
    }
  }, [isfetching, fetchChatData]);
  const handleFilterChange = (
    newStatus?: ChatStatusFilter,
    newFilter?: RepoistoryFilter<ChatSupport>
  ) => {
    setIsFetching(true);
    if (newStatus) {
      setStatus(newStatus);
    }

    const lastFilter = { ...filter, ...newFilter, status: newStatus ?? status };

    updateFilter({ ...lastFilter });
  };

  return (
    <div className=' tw-flex gap-2 tw-items-center  tw-shadow   '>
      <Dropdown>
        <Dropdown.Toggle variant='outline' id='dropdown-basic'>
          {status}
        </Dropdown.Toggle>
        {!isfetching && (
          <span className='  tw-bg-brand-light_blue/20 tw-rounded-full  ms-2 px-3'>
            {totalDocsCount}
          </span>
        )}
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleFilterChange('All')}>
            All
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilterChange('Open')}>
            Open
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleFilterChange('Closed')}>
            Closed
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {isfetching && <Spinner />}

      <div className=' ms-auto me-1 hover:tw-bg-slate-200 tw-size-8 flex_center tw-rounded-lg '>
        <SortIconBtn
          className=' tw-text-2xl tw-cursor-pointer '
          sort={filter.orderBy![1]}
          onClick={(newSort) =>
            handleFilterChange(status, { orderBy: ['createdAt', newSort] })
          }
        />
      </div>
    </div>
  );
};
