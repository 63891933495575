import { Repository } from "../firebase/firestore/repository";
import { Booking } from "../entities/booking.entity";

export class BookingRepository extends Repository<Booking> {
	constructor() {
		super("tutorBookings");
	}
}

export const bookingRepository = new BookingRepository();
