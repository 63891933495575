import React, { ReactNode } from 'react';
import Table from 'react-bootstrap/Table';

export type TableHeader<T> = {
  label: string;
  key: keyof T;
  customGetter?(data: T): ReactNode;
};
interface TableProps<T extends {}> {
  columns: TableHeader<T>[];
  rows: (T & { classNames?: string })[];
  onLineCick?: (value: T) => void;
}

export const ReusableTable = <T extends {}>({
  columns,
  rows,
  onLineCick
}: TableProps<T>) => {
  return (
    <Table bordered responsive hover className=''>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key as string}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            className={`${
              onLineCick ? ' tw-cursor-pointer hover:!tw-bg-gray-100' : ''
            } `}
            onClick={() => {
              onLineCick && onLineCick(row);
            }}
            key={index}
          >
            {columns.map((column) => (
              <td className={` ${row.classNames}`} key={column.key as string}>
                {column.customGetter
                  ? column.customGetter(row)
                  : (row[column.key as keyof T] as ReactNode)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
