import { httpsCallable } from 'firebase/functions';
import { Student } from '../entities/student.entity';
import { studentRepository } from '../repositories/student.repository';
import { GenericQueryReturnType, GenericUpdateReturnType } from '../store';
import { functions } from '../firebase';
import { Tutor, TutorAccountType } from '../entities/tutor.entity';
import { organizationRepository } from '../repositories/organization.repository';

export const findStudents = async (
  where?: any,
  offset?: string
): Promise<GenericQueryReturnType<'students', Student[]>> => {
  try {
    const students = await studentRepository.findAll(where, offset, 'name');
    const studentsWithAttachments = await Promise.all(
      students.map(async (student) => {
        const organization = student.organizationId
          ? await organizationRepository.findById(student.organizationId)
          : undefined;
        return {
          ...student,
          organization: organization || undefined
        };
      })
    );
    return { data: { students: studentsWithAttachments } };
  } catch (error) {
    return { error: true };
  }
};

export const findStudentById = async (
	id: string
): Promise<GenericQueryReturnType<"student", Student>> => {
	try {
		const student = await studentRepository.findById(id);
		if (!student) return { error: true };
		return {
			data: {
				student,
			},
		};
	} catch (error) {
		return { error: true };
	}
};

export const updateStudentById = async (
	id: string,
	newDate: Partial<Student>
): Promise<GenericUpdateReturnType> => {
	try {
		await studentRepository.update(id, newDate);
		return { data: undefined };
	} catch (error) {
		console.log(error);
		return { error: "unknown" };
	}
};

export const deleteStudentById = async (
	id: string
): Promise<GenericUpdateReturnType> => {
	try {
		await studentRepository.delete(id);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const countStudents = async (
  where?: any
): Promise<GenericQueryReturnType<'count', number>> => {
  try {
    const count = await studentRepository.count(where);
    return {
      data: {
        count
      }
    };
  } catch (_) {
    return { error: true };
  }
};

export const convertStudentToTutor = async (
	studentId: string,
	accountType: TutorAccountType
): Promise<GenericUpdateReturnType> => {
	try {
		const convertStudentToTutor = httpsCallable(
			functions,
			"convertStudentToTutor"
		);
		await convertStudentToTutor({ studentId, accountType });
		return {
			data: undefined,
		};
	} catch (_) {
		return {
			error: "unknown",
		};
	}
};

export const addTutorsToPrivateStudent = async (
	studentId: string,
	tutors: Partial<Tutor>[]
): Promise<GenericUpdateReturnType> => {
	try {
		const newTutorsData = tutors.map((tutor) => ({
			id: tutor.id,
			email: tutor.email,
			mobileNo: tutor.mobileNo,
			name: tutor.name,
		}));
		await studentRepository.addTutorsToPrivateStudent(
			studentId,
			newTutorsData
		);
		return { data: undefined };
	} catch (error) {
		return {
			error: "unknown",
		};
	}
};
export const findPrivateStudentTutors = async (
	id: string
): Promise<GenericQueryReturnType<"tutors", Tutor[]>> => {
	try {
		const tutors = await studentRepository.findPrivateStudentTutors(id);
		return {
			data: {
				tutors,
			},
		};
	} catch (error) {
		return {
			error: true,
		};
	}
};
export const removeTutorFromPrivateStudent = async (
	id: string,
	tutorId: string
): Promise<GenericUpdateReturnType> => {
	try {
		await studentRepository.deleteTutorFromPrivateStudent(id, tutorId);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};
