import { SubmitHandler, useForm } from "react-hook-form";
import { useAddNewAdminMutation } from "../../store/admin.store";
import { useEffect, useState } from "react";

type NewAdminData = {
    email: string;
    name: string;
    password: string;
}
type Props = {
    setHide: () => void
}
const CreateAdminForm = ({ setHide} : Props) => {
    const [createAdmin, createAdminResult] = useAddNewAdminMutation();
    const [passwordIsConfirmed, setPasswordIsConfirmed] = useState<boolean>(true);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<NewAdminData & {passwordConfirmation: string }>();

    const submitHandler : SubmitHandler<NewAdminData & { passwordConfirmation: string } > = ({ email, password, name, passwordConfirmation }) => {
        if (password !== passwordConfirmation) {
            setPasswordIsConfirmed(false);
            return;
        }
        createAdmin({ email, password, name })    
    }

    useEffect(() => {
        if (createAdminResult.isSuccess)
            setHide()
    }, [createAdminResult, setHide]);

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <input className={`form-control form-control-lg mb-3 ${errors.name ? 'border-danger' : ''}`} type="text"
                placeholder="name" aria-label="name"
                {...register("name", { required: true })}
            />
            <input className={`form-control form-control-lg mb-3 ${errors.email ? 'border-danger' : ''}`} type="email"
                placeholder="email" aria-label="email"
                {...register("email", { required: true })}
            />
            <input className={`form-control form-control-lg mb-3 ${errors.password ? 'border-danger' : ''}`} type="password"
                placeholder="password" aria-label="password"
                {...register("password", { required: true })}
            />
            <input className={`form-control form-control-lg mb-3 ${errors.passwordConfirmation || !passwordIsConfirmed ? 'border-danger' : ''}`} type="password"
                placeholder="passwordConfirmation" aria-label="passwordConfirmation"
                {...register("passwordConfirmation", { required: true, minLength: 6 })}
                onChange={(_) =>  setPasswordIsConfirmed(true) }
            />
            {
                createAdminResult.error ? <div className="alert alert-danger mb-3" role="alert">Email is used before</div> : null
            }
            <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg w-25">add</button>
            </div>
        </form>
    )
};

export default CreateAdminForm;