import {  Alert, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Session } from "../../entities/session.entity";
import { displayDate } from "../../utils/dateHandler.util";

const SessionsTable = ({ sessions } : { sessions: Session[] }) => {
    
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>start date</th>
                        <th>end date</th>
                        <th>meeting provider</th>
                        <th>deducated points</th>
                        <th>added credit</th>
                        <th>tutor</th>
                        <th>student</th>
                        <th>type</th>
                    </tr>
                </thead>
                <tbody>
                    {sessions.map((session, index) => (
                        <tr key={index} className="text-center">
                            <td>{displayDate(session.startTime)}</td>
                            <td>{displayDate(session.endTime)}</td>
                            <td>{session.type || "openTok"}</td>
                            <td>{session.studentDeductedPoints}</td>
                            <td>{session.tutorAddedCredit}</td>
                            <td><Link to={`/tutors/${session.tutorId}`} className="text-decoration-none">{session.tutor?.name}</Link></td>
                            <td><Link to={`/students/${session.studentId}`} className="text-decoration-none">{session.student?.name}</Link></td>
                            <td>{session.studentDeductedPoints == 0 ? "FREE" : "PAID"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { sessions.length === 0 ? <Alert variant="info">no sessions</Alert> : null}
        </>
    )
}

export default SessionsTable;