import { getCountFromServer, OrderByDirection } from 'firebase/firestore';
import { FindForMeNowRequest } from '../entities/findForMeNow.entity';
import { Repository } from '../firebase/firestore/repository';
import { buildFilterQuery } from '../firebase/firestore/filterQueryBuilder';
import { getDocs, where as whereCondation } from 'firebase/firestore';
export class FindForMeNowRequestRepository extends Repository<FindForMeNowRequest> {
  constructor() {
    super('findForMeNowRequest');
  }
  async findAll(
    where?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<FindForMeNowRequest[]> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      limit || 10,
      offset,
      orderBy,
      this.builderFilteCondations(where)
    );
    const snapshot = await getDocs(query);
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      undefined,
      undefined,
      undefined,
      this.builderFilteCondations(where)
    );
    const countResult = await getCountFromServer(query);
    return countResult.data().count;
  }
  private builderFilteCondations(where: any): any[] {
    const condations = [];
    if (where.tutorId)
      condations.push(whereCondation('tutorId', '==', where.tutorId));
    if (where.studentId)
      condations.push(whereCondation('studentId', '==', where.studentId));
    if (where.status)
      condations.push(whereCondation('status', '==', where.status));
    if (where.createdAt) {
      condations.push(whereCondation('createdAt', '>=', where.createdAt[0]));
      condations.push(whereCondation('createdAt', '<=', where.createdAt[1]));
    }
    return condations;
  }
}

export const findForMeNowRequestRepository =
  new FindForMeNowRequestRepository();
