import { baseService } from "./base.store";
import {
	findAvailableSlots,
	countAvailableSlots,
} from "../services/availableSlots.service";
import { AvailableSlot } from "../entities/availableSlot.entity";

export const availableSlotService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getAvailableSlots: builder.query<
			{ slots: AvailableSlot[] },
			{ where?: any; offset?: string }
		>({
			queryFn: async ({ where = {}, offset }) =>
				await findAvailableSlots(where, offset),
		}),
		countAvailableSlots: builder.query<{ count: number }, { where?: any }>({
			queryFn: async ({ where }) => await countAvailableSlots(where),
		}),
	}),
});

export const { useGetAvailableSlotsQuery, useCountAvailableSlotsQuery } =
	availableSlotService;
