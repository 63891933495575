import { tutorRepository } from "../repositories/tutor.repository";
import { GenericQueryReturnType, GenericUpdateReturnType } from "../store";
import { bookingRepository } from "../repositories/booking.repository";
import { Booking } from "../entities/booking.entity";
import { studentRepository } from "../repositories/student.repository";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

export const findBookings = async (
	where?: any,
	offset?: string
): Promise<GenericQueryReturnType<"bookings", Booking[]>> => {
	try {
		const bookings = await bookingRepository.findAll(where, offset, [
			"date",
			"desc",
		]);

		const bookingsWithParticipants = await Promise.all(
			bookings.map(async (booking) => {
				const tutor = await tutorRepository.findById(booking.tutorId);
				const student = await studentRepository.findById(
					booking.studentId
				);

				return {
					...booking,
					tutor: tutor || undefined,
					student: student || undefined,
				};
			})
		);
		return {
			data: {
				bookings: bookingsWithParticipants,
			},
		};
	} catch (error) {
		return {
			error: true,
		};
	}
};

export const countBookings = async (
	where?: any
): Promise<GenericQueryReturnType<"count", number>> => {
	try {
		const count = await bookingRepository.count(where);

		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};

export const completeUnCompletedBookingsAdministration =
	async (): Promise<GenericUpdateReturnType> => {
		try {
			const completeUnCompletedBookingsAdministrationFunction =
				httpsCallable(functions, "bookingsAdminstartionFunction");
			await completeUnCompletedBookingsAdministrationFunction({});
			return { data: undefined };
		} catch (_) {
			return {
				error: "unknown",
			};
		}
	};
