import { Dispatch, SetStateAction } from 'react';
import { PrintButton } from '../../../shared/atoms/print-button';

type Props = {
  setSearchFilter: Dispatch<SetStateAction<string>>;
  handlePrint: () => void;
};

export const OrganizationsStudentsTableHeader = ({
  setSearchFilter,
  handlePrint
}: Props) => {
  return (
    <div className=' tw-w-full  tw-h-[40px]  tw-flex tw-gap-2 '>
      <PrintButton handlePrint={handlePrint} />

      <input
        className=' tw-block custom-input tw-rounded-xl '
        onChange={({ target }) => setSearchFilter(target.value)}
        name='search'
        placeholder='search by name or email'
      />
    </div>
  );
};
