import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logout from './Logout';

const NavBar = () => {
  return (
    <Navbar
      variant='dark'
      expand='lg'
      className='d-block d-lg-none bg-main-color'
    >
      <Container>
        <Navbar.Brand>Taaly</Navbar.Brand>
        <div className='d-flex justify-content-center'>
          <Logout />
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className='ms-3'
          />
        </div>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/'
            >
              statistcs
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/admins'
            >
              admins
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/organizations'
            >
              organizations
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/students'
            >
              students
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/tutors'
            >
              tutors
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/vouchers-codes'
            >
              vouchers codes
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/cancellation-requests'
            >
              cancellation requests
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/withdrawal-requests'
            >
              withdrawal requests
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/bookings'
            >
              bookings
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/applying-tutors'
            >
              applying tutors
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/sessions'
            >
              sessions
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/find-for-me-requests'
            >
              find for me requests
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/available-slots'
            >
              available slots
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/tutors-transactions'
            >
              tutors transactions
            </Nav.Link>
            <Nav.Link
              className='nav-link pb-1'
              style={{ color: 'white', fontSize: '12px' }}
              as={Link}
              to='/students-transactions'
            >
              students transactions
            </Nav.Link>
            <Nav.Link
              className='nav-link mb-3 tw-text-white'
              as={Link}
              to='/chat-support'
            >
              chat-support
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
