import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Tutor } from "./tutor.entity";
import { Booking } from "./booking.entity";

export enum CancellationRequestStatus {
	PENDIN = "pending",
	PendingCancellationApproval = "PendingCancellationApproval",
	CANCELED = "cancelled",
}
export enum CancellationRequestType {
	WITHIN_24_HOURS = "with24h",
	BEFORE_24_HOURS = "before24h",
}
export enum CancellationRequestBy {
	TUTOR = 0,
	STUDENT = 1,
}
export type CancellationRequest = {
	id: string;
	bookingId: string;
	cancellationDate: Timestamp;
	cancelledBy: CancellationRequestBy;
	status: CancellationRequestStatus;
	type: CancellationRequestType;
	tutorId: string;
	studentId: string;
	cancellationReasonId: string;
	cancellationReasonText: string;
	cancellationReasonOtherText: string;
	student?: Student;
	tutor?: Tutor;
	booking?: Booking;
};
