import { Navigate, useSearchParams } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import usePagination from "../../customHooks/usePagination.hook";
import { FindForMeNowRequest } from "../../entities/findForMeNow.entity";
import { useFindForMeNowRequestQuery, useCountFindForMeNowRequestsQuery } from "../../store/findForMeNowRequest.store"
import PageLoading from "../../components/pageLoading";
import Pagination from "../../components/pagination";
import { Alert } from "react-bootstrap";
import FindForMeNowRequestsTable from "../../components/findForMeNowRequests/findForMeNowRequestsTable";
import FindForMeNowRequestsFilterForm from "../../components/findForMeNowRequests/findForMeNowRequestsFilterForm";

const FindForMeNowRequests = () => {
    const [searchParams] = useSearchParams();
    const {
        data,
        numberOfPages,
        currentPage,
        updateQuery,
        next,
        prev,
        isSuccess,
        isError
    } = usePagination<FindForMeNowRequest>({
        useGetDataQuery: useFindForMeNowRequestQuery,
        useCountData: useCountFindForMeNowRequestsQuery,
        initQuery: {
            tutorId: searchParams.get('tutorId') || undefined,
            studentId: searchParams.get('studentId') || undefined,
        }
    });

    return (
        <div className='page_container'>
            <PageHeader text='Find For Me Requests' />
            <div className='mb-3'>
                <FindForMeNowRequestsFilterForm
                setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
                />
            </div>
            {
                isSuccess ? (
                <>
                    <FindForMeNowRequestsTable requests={data} />
                    {
                        data.length == 0 ? (
                            <Alert variant='info'>no matched find for me requests</Alert>
                        ) : null
                    }
                    <Pagination
                        numberOfPages={numberOfPages}
                        currentPage={currentPage}
                        prev={prev}
                        next={next}
                    />
                </>
            ) : isError ? (
                <Navigate to='/505' />
            ) : (
                <PageLoading />
            )}
        </div>
  );

}
export default FindForMeNowRequests;