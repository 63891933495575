import { tutorRepository } from '../repositories/tutor.repository';
import { GenericQueryReturnType, GenericUpdateReturnType } from '../store';
import { availableSlotRepository } from '../repositories/availableSlot.repository';
import { AvailableSlot } from '../entities/availableSlot.entity';

export const findAvailableSlots = async (
  where?: any,
  offset?: string
): Promise<GenericQueryReturnType<'slots', AvailableSlot[]>> => {
  try {
    const slots = await availableSlotRepository.findAll(where, offset, [
      'date',
      'desc'
    ]);

    const slotsWithTutors = await Promise.all(
      slots.map(async (slot) => {
        const tutor = slot.tutorId
          ? await tutorRepository.findById(slot.tutorId)
          : undefined;
        return {
          ...slot,
          tutor: tutor || undefined
        };
      })
    );
    return {
      data: {
        slots: slotsWithTutors
      }
    };
  } catch (error) {
    console.log(error);
    return {
      error: true
    };
  }
};

export const countAvailableSlots = async (
  where?: any
): Promise<GenericQueryReturnType<'count', number>> => {
  try {
    const count = await availableSlotRepository.count(where);

    return {
      data: {
        count: count
      }
    };
  } catch (_) {
    return { error: true };
  }
};
