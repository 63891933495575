import { CancellationRequestStatus } from "../../entities/cancellationRequest.entity";

const CancellationRequestsSelectByStatus = ({ setNewStatus }: { setNewStatus: (newValue?: CancellationRequestStatus) => void}) => {
    const handleChange = (newStatus: string) => {
        setNewStatus(newStatus == "all" ? undefined : newStatus as CancellationRequestStatus);
    }
    return (
        <select 
            className="form-control form-control-lg"
            defaultValue="all"
            onChange={(e) => handleChange(e.target.value)}
        >
            <option value="all">All Cancellation Requests</option>
            <option value={CancellationRequestStatus.PendingCancellationApproval}>Pending Cancellation Approval</option>
            <option value={CancellationRequestStatus.CANCELED}>Cancelled</option>
        </select>
    )
}

export default CancellationRequestsSelectByStatus;