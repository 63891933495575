import { ReactNode } from 'react';
import { ToastOptions, toast } from 'react-hot-toast';
import { WarrningToast } from '../components/shared/molecules/warning-toast';
export function showToast(
  type: 'success' | 'error' | 'warrning',
  message: string | ReactNode,
  options?: ToastOptions
): void {
  switch (type) {
    case 'success':
      toast.success(message as string, options);
      break;
    case 'error':
      toast.error(message as string, options);
      break;
    case 'warrning':
      toast.custom((t) => <WarrningToast t={t} message={message} />);

      break;
  }
}
