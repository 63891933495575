import { FindForMeNowRequest } from "../entities/findForMeNow.entity";
import { baseService } from "../store/base.store";
import {
	findFindForMeNowRequests,
	countFindForMeNowRequests,
} from "../services/findForMeNowRequest.service";

export const findForMeNowRequestService = baseService.injectEndpoints({
	endpoints: (build) => ({
		findForMeNowRequest: build.query<
			{ requests: FindForMeNowRequest[] },
			{ where?: any; offset?: string }
		>({
			queryFn: async ({ where = {}, offset }) => {
				return await findFindForMeNowRequests(where, offset);
			},
		}),
		countFindForMeNowRequests: build.query<
			{ count: number },
			{ where?: any }
		>({
			queryFn: async ({ where = {} }) => {
				return await countFindForMeNowRequests(where);
			},
		}),
	}),
});

export const {
	useCountFindForMeNowRequestsQuery,
	useFindForMeNowRequestQuery,
} = findForMeNowRequestService;
