import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  createContext,
  useContext
} from 'react';
import {
  ActiveChatMessage,
  PendingMessage
} from '../../types/chat-support/chat-support.type';

export interface ChatSupportActiveChatContextType {
  states: {
    messages: {
      messages: ActiveChatMessage[];
      setMessages: Dispatch<SetStateAction<ActiveChatMessage[]>>;
      limit: number;
      newRecivedMessagesCount: number;
      setNewRecivedMessagesCount: Dispatch<SetStateAction<number>>;
      msgsContainerRef: RefObject<HTMLDivElement>;
      initialFechedRef: MutableRefObject<boolean | null>;
    };
    pending: {
      pendingMsgs: PendingMessage[];
      setPendingMsgs: Dispatch<SetStateAction<PendingMessage[]>>;
    };
    isOldMessages: {
      isOldMessages: boolean;
      setIsOldMessages: Dispatch<SetStateAction<boolean>>;
    };
  };
}
export const ChatSupportActiveContext =
  createContext<ChatSupportActiveChatContextType>(
    {} as ChatSupportActiveChatContextType
  );

export const useChatSupportActiveMessages = () => {
  const chatSupportContextActiveMessages = useContext(ChatSupportActiveContext);

  if (!chatSupportContextActiveMessages) {
    throw new Error(
      'ChatSupportActiveContext has to be used within <ChatSupportActiveContext.Provider>'
    );
  }

  return chatSupportContextActiveMessages;
};
