import { FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import { TutorAccountType } from "../../../entities/tutor.entity";
import { useConvertStudentToTutorMutation } from "../../../store/student.store";
import { Student } from "../../../entities/student.entity";
import { Navigate } from "react-router-dom";

interface ConvertStudentToTutorProps {
    onHide: () => void;
    student: Student;
    [key: string]: any;
}

const ConvertStudentToTutorModal: FC<ConvertStudentToTutorProps> = ({ onHide, student, ...props }) => {
    const [convertStudentToTutor, convertStudentToTutorResult] = useConvertStudentToTutorMutation();
    const [isCorrectName, setIsCorrectName] = useState<boolean>();
    const [accountType, setAccountType] = useState<TutorAccountType>()
    useEffect(() => {
        if (convertStudentToTutorResult.isSuccess)
            onHide()
    },[convertStudentToTutorResult, onHide]);

    const handleAccept = () => {
        if (!accountType) return; 
        convertStudentToTutor({ id: student.id, accountType })
    }
   
    return (
        <Modal
            {...props}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-6'>Do you accept convert student to tutor ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>student name: {student.name}</p>
                <InputGroup className="mt-2 mb-3">
                    <Form.Control
                        placeholder="Enter Tutor Name"
                        onChange={(e) => setIsCorrectName(e.target.value == student.name)}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <Form.Select
                        onChange={(e) => setAccountType(e.target.value as TutorAccountType)}
                    >
                        <option value="">choose account type</option>
                        <option value={TutorAccountType.FREE}>{TutorAccountType.FREE}</option>
                        <option value={TutorAccountType.PAID}>{TutorAccountType.PAID}</option>
                    </Form.Select>
                </InputGroup>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button 
                    variant="danger" 
                    onClick={handleAccept} 
                    disabled={!isCorrectName || !accountType || convertStudentToTutorResult.isLoading}
                >Convert</Button>
            </Modal.Footer>
            { convertStudentToTutorResult.isSuccess && <Navigate to={`/tutors/${student.id}`}/>}
        </Modal>
    );
};

const ConvertStudentToTutor = ({ student }: { student: Student}) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Button variant="danger" onClick={() => setModalShow(true)}>
                Convert To Tutor
            </Button>

            <ConvertStudentToTutorModal
                show={modalShow}
                student={student}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default ConvertStudentToTutor;