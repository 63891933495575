import { Link, Navigate, useParams } from 'react-router-dom';
import PageLoading from '../../../components/pageLoading';
import PageHeader from '../../../components/pageHeader';
import { useGetTutorByIdQuery } from '../../../store/tutor.store';
import TutorInfo from '../../../components/tutors/tutor/tutorInfo';
import TutorTransactionHistory from '../../../components/tutors/tutor/tutorTransactionHistory';
import ConvertTutorToStudent from '../../../components/tutors/tutor/convertTutorToStudent';
import { Col, Row } from 'react-bootstrap';

const ShowTutor = () => {
  let { id = '' } = useParams();
  const { data, isSuccess, isLoading } = useGetTutorByIdQuery(id, {
    skip: id ? false : true
  });

  return isLoading ? (
    <PageLoading />
  ) : isSuccess ? (
    <div className='page_container'>
      <PageHeader text='Tutor' />
      <TutorInfo tutor={data.tutor} />
      <div className='text-center mt-5'>
        <h3 className='mb-4 text'>Actions</h3>
        <Row className='justify-content-center'>
            <Col xs={12} sm={6} lg={3} className='mb-3'>
              <ConvertTutorToStudent tutor={data.tutor} />
            </Col>
            <Col xs={12} sm={6} lg={3} className='mb-3'>
              <Link to={`/tutors-transactions?tutorId=${data.tutor.id}`} className='btn btn-info w-100'>
                Transactions
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={3} className='mb-3'>
              <Link to={`/available-slots?tutorId=${data.tutor.id}`} className='btn btn-info w-100'>
                Available slots
              </Link>
            </Col>
            <Col xs={12} sm={6} lg={3} className='mb-3'>
              <Link to={`/find-for-me-requests?tutorId=${data.tutor.id}`} className='btn btn-secondary w-100'>
                Find For Me Requests
              </Link>
            </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Navigate to='/404' />
  );
};

export default ShowTutor;
