import { increment } from 'firebase/firestore';
import {
  CreateVoucherCode,
  VoucherCode,
  VoucherCodeStatus
} from '../entities/voucherCode.entity';
import { organizationRepository } from '../repositories/organization.repository';
import { studentRepository } from '../repositories/student.repository';
import { voucherCodeRepository } from '../repositories/voucherCode.repository';
import {
  GenericCreateReturnType,
  GenericQueryReturnType,
  GenericUpdateReturnType
} from '../store';
import { generateRandomVouchersCodesByCount } from '../utils/generateRandomVoucherCode.util';

export const createVoucherCode = async (
  data: CreateVoucherCode
): Promise<GenericCreateReturnType<'newVoucherCode', VoucherCode>> => {
  try {
    const voucherCodeIsUnique = await voucherCodeRepository.checkFieldIsUnique(
      'code',
      data.code
    );
    if (!voucherCodeIsUnique) return { error: 'code' };
    const newVocuherCodeId = await voucherCodeRepository.create({
      ...data,
      status: VoucherCodeStatus.AVAILABLE
    });
    return {
      data: {
        newVoucherCode: {
          id: newVocuherCodeId,
          ...data,
          status: VoucherCodeStatus.AVAILABLE
        }
      }
    };
  } catch (error) {
    return { error: 'unknown' };
  }
};
export const bulkCreateVouchersCodes = async (
  data: CreateVoucherCode[]
): Promise<GenericCreateReturnType<'newVouchersCodes', VoucherCode[]>> => {
  try {
    const newVouchersCodesData = data.map((voucherCode) => ({
      ...voucherCode,
      status: VoucherCodeStatus.AVAILABLE
    }));
    const newVouchersCodes =
      await voucherCodeRepository.bulkCreate<VoucherCode>(newVouchersCodesData);
    // increase organization vouchersCodesCount
    if (data[0].organizationId) {
      await organizationRepository.update(data[0].organizationId, {
        vouchersCodesCount: increment(
          newVouchersCodesData.length
        ) as unknown as number
      });
    }
    return {
      data: { newVouchersCodes }
    };
  } catch (err) {
    return { error: 'unknown' };
  }
};

export const generateReandomCodesByCount = async (
  count: number
): Promise<GenericCreateReturnType<'codes', string[]>> => {
  try {
    const unexpiredExistingCodesVouchersCodes =
      await voucherCodeRepository.findUnExpiredVouchersCodes();
    const unexpiredExistingCodesVouchersCodesSet = new Set(
      unexpiredExistingCodesVouchersCodes.map((item) => item.code)
    );
    const generatedVouchersCodes = generateRandomVouchersCodesByCount(
      unexpiredExistingCodesVouchersCodesSet,
      count
    );
    return {
      data: {
        codes: generatedVouchersCodes
      }
    };
  } catch (err) {
    return {
      error: 'unknown'
    };
  }
};

export const findVouchersCodes = async (
  where: any = {},
  offset?: string
): Promise<GenericQueryReturnType<'vouchersCodes', VoucherCode[]>> => {
  try {
    const { organizationId, code } = where;
    const searchQuery =
      where.id && where.code ? { or: { code } } : { organizationId };
    const vouchersCodes = await voucherCodeRepository.findAll(
      searchQuery,
      offset,
      !code ? 'expiryDate' : undefined,
      organizationId ? 1_000 : 10
    );
    const filteredVouchersCodes = organizationId
      ? filterVouchersCodesReturnedFromSearchByQuerByOrganizationId(
          vouchersCodes,
          organizationId
        )
      : vouchersCodes;
    const vouchersCodeWithStudents = await findVouchersCodesDetails(
      filteredVouchersCodes
    );
    return { data: { vouchersCodes: vouchersCodeWithStudents } };
  } catch (err) {
    return { error: true };
  }
};
const filterVouchersCodesReturnedFromSearchByQuerByOrganizationId = (
  vouchersCodes: VoucherCode[],
  organizationId: string
): VoucherCode[] => {
  return vouchersCodes.filter(
    (voucherCode) => voucherCode.organizationId === organizationId
  );
};
const findVouchersCodesDetails = async (
  vouchersCodes: VoucherCode[]
): Promise<VoucherCode[]> => {
  return Promise.all(
    vouchersCodes.map(async (voucherCode) => {
      const student = voucherCode.redeemedBy
        ? await studentRepository.findById(voucherCode.redeemedBy)
        : undefined;
      const organization = voucherCode.organizationId
        ? await organizationRepository.findById(voucherCode.organizationId)
        : undefined;

      return {
        ...voucherCode,
        student,
        organization
      };
    })
  );
};
export const findVoucherCodeById = async (
  id: string
): Promise<GenericQueryReturnType<'voucherCode', VoucherCode>> => {
  try {
    const voucherCode = await voucherCodeRepository.findById(id);
    if (!voucherCode) return { error: true };
    const student = voucherCode.redeemedBy
      ? await studentRepository.findById(voucherCode.redeemedBy)
      : undefined;
    const organization = voucherCode.organizationId
      ? await organizationRepository.findById(voucherCode.organizationId)
      : undefined;
    return {
      data: {
        voucherCode: {
          ...voucherCode,
          student,
          organization
        }
      }
    };
  } catch (error) {
    return { error: true };
  }
};

export const updateVoucherCodeById = async (
  id: string,
  newData: Partial<VoucherCode>
): Promise<GenericUpdateReturnType> => {
  try {
    await voucherCodeRepository.update(id, newData);
    return { data: undefined };
  } catch (error) {
    return { error: 'unknown' };
  }
};

export const deleteVoucherCode = async (
  voucherCode: VoucherCode
): Promise<GenericUpdateReturnType> => {
  try {
    await voucherCodeRepository.delete(voucherCode.id);
    return { data: undefined };
  } catch (error) {
    return { error: 'unknown' };
  }
};

export const countVouchersCodes = async (
  where?: any
): Promise<GenericQueryReturnType<'count', number>> => {
  try {
    const searchQuery = where.code
      ? { code: where.code }
      : { organizationId: where.organizationId };
    const count = await voucherCodeRepository.count(searchQuery);

    return {
      data: {
        count
      }
    };
  } catch (_) {
    return { error: true };
  }
};
