import { ReactNode } from 'react';
import { Toast } from 'react-hot-toast';
import { PiSealWarningFill } from 'react-icons/pi';

type Props = {
  t: Toast;
  message: ReactNode;
};
export const WarrningToast = ({ t, message }: Props) => {
  return (
    <div
      className={`${
        t.visible ? 'animate-enter' : 'animate-leave'
      } tw-max-w-md tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-flex tw-ring-1 tw-ring-black tw-ring-opacity-5`}
    >
      <div className='tw-flex  p-4'>
        <PiSealWarningFill className='tw-text-orange-500  tw-text-2xl tw-me-4' />

        <div> {message}</div>
      </div>
    </div>
  );
};
