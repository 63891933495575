import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import {
  CollectionReference,
  collection,
  limit,
  orderBy,
  query
} from 'firebase/firestore';
import { ActiveChatMessage } from '../../../types/chat-support/chat-support.type';
import { ActiveChatMsg } from '../atoms/active-chat-msg';
import { ActiveChatDateLabel } from '../atoms/active-chat-date-label';
import { useCollection } from 'react-firebase-hooks/firestore';
import {
  removeDuplicatesFromOldArrById,
  scrollToBottom
} from '../../../utils/util';
import { Spinner } from '../../shared/atoms/spinner';
import { ChatMessageDoc } from '../../../entities/chat-support.entity';

export const ActiveChatMessagesContainer = () => {
  const {
    states: {
      messages: {
        messages,
        setMessages,
        limit: limitedDocs,
        msgsContainerRef,
        setNewRecivedMessagesCount,
        initialFechedRef
      },
      isOldMessages: { setIsOldMessages }
    }
  } = useChatSupportActiveMessages();
  const {
    actions,
    states: {
      activeChatRoom: { activeChatRoom }
    }
  } = useChatSupport();
  const collectionQuery = useMemo(() => {
    const currentChatDocRef = activeChatRoom.docRef;
    const messagesRef = collection(
      currentChatDocRef,
      'messages'
    ) as CollectionReference<ChatMessageDoc>;
    const latestMessageQuery = query(
      messagesRef,
      orderBy('createdAt', 'desc'),
      limit(limitedDocs)
    );
    return latestMessageQuery;
  }, [activeChatRoom, limitedDocs]);

  const [snapshot, loading, error] = useCollection(collectionQuery, {
    snapshotListenOptions: {
      includeMetadataChanges: true
    }
  });

  const handleData = useCallback(() => {
    let newMessagesWithoutUser: Omit<ActiveChatMessage, 'user'>[] = [];
    const userData = activeChatRoom.user;
    const currentActiveChatMessageIds = messages.map((msg) => msg.id);

    snapshot!
      .docChanges({ includeMetadataChanges: true })
      .forEach((changeDoc) => {
        const change = changeDoc.type;
        if (
          change === 'removed' ||
          currentActiveChatMessageIds.includes(changeDoc.doc.id) ||
          changeDoc.doc.metadata.hasPendingWrites
        )
          return;
        const docWithoutUser: Omit<ActiveChatMessage, 'user'> = {
          ...(changeDoc.doc.data() as Omit<ActiveChatMessage, 'user'>),
          docRef: changeDoc.doc.ref,
          id: changeDoc.doc.id
        };

        newMessagesWithoutUser.push(docWithoutUser);
      });

    if (!newMessagesWithoutUser.length) return;

    const newMessages = actions.mapChatMessageWithUser(
      newMessagesWithoutUser,
      userData
    );
    setMessages((old) => {
      const filterOldFromDuplicateOfNewMSGS = removeDuplicatesFromOldArrById(
        old,
        newMessages
      );

      const allMessages = [...newMessages, ...filterOldFromDuplicateOfNewMSGS];

      const userRecivedMSGs = newMessages.filter((user) => user.user);
      setNewRecivedMessagesCount((old) => old + userRecivedMSGs.length);
      newMessagesWithoutUser = [];
      return allMessages;
    });
    setIsOldMessages(limitedDocs === newMessagesWithoutUser.length);
    if (!initialFechedRef.current) {
      setTimeout(() => {
        scrollToBottom(msgsContainerRef);
        initialFechedRef.current = true;
      }, 100);
    }
  }, [activeChatRoom, snapshot, actions, setMessages]);

  useEffect(() => {
    if (snapshot && !loading) {
      handleData();
    }
  }, [snapshot]);

  const renderMessages = useCallback(() => {
    const reveredMessage = messages.slice().reverse();
    let currentDate = '';
    return reveredMessage.map((chatMessage: ActiveChatMessage) => {
      const localeDate = chatMessage.createdAt.toDate().toLocaleDateString();
      if (localeDate !== currentDate) {
        currentDate = localeDate;
        return (
          <Fragment key={Math.random()}>
            <ActiveChatDateLabel date={chatMessage.createdAt.toDate()} />
            <ActiveChatMsg
              key={chatMessage.id}
              user={chatMessage.user}
              message={chatMessage}
            />
          </Fragment>
        );
      } else {
        return (
          <ActiveChatMsg
            key={chatMessage.id}
            user={chatMessage.user}
            message={chatMessage}
          />
        );
      }
    });
  }, [messages]);
  if (loading)
    return (
      <div className='flex_center tw-h-full'>
        <Spinner className='tw-size-16 ' />;
      </div>
    );
  return (
    <div className=' tw-flex tw-flex-col tw-gap-4'>{renderMessages()}</div>
  );
};
