import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Tutor } from "./tutor.entity";

export enum BookingStatus {
	INITIATED = "INITIATED",
	CANCELED = "CANCELED",
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	COMPLETED = "COMPLETED",
	PENDING_CANCELLATION_APPROVAL = "PendingCancellationApproval",
}

export type Booking = {
	id: string;
	bookingStatus: BookingStatus;
	createdAt: Timestamp;
	date: Timestamp;
	sessionId?: string;
	slotId: string;
	slotOrder: number;
	studentId: string;
	tutorId: string;
	isCompletedAdministration: boolean;
	student?: Student;
	tutor?: Tutor;
};
