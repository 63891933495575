import { getDocs, OrderByDirection } from "firebase/firestore";
import { WithdrawalRequest } from "../entities/withdrawalRequest.entity";
import { Repository } from "../firebase/firestore/repository";
import { buildFilterQuery } from "../firebase/firestore/filterQueryBuilder";
import { TutorTransactionStatus } from "../entities/transactionHistory.entity";
import { where as whereCondation } from "firebase/firestore";
import { TutorFinancalDetails } from "../entities/tutorFinancalDetails.entity";
export class TutorFinancalDetailsRepository extends Repository<TutorFinancalDetails> {
	constructor() {
		super("financialDetails");
	}
}

export const tutorFinancalDetailsRepository =
	new TutorFinancalDetailsRepository();
