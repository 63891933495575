import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Tutor } from "./tutor.entity";

export enum TransactionHistoryType {
	CREDIT = "CREDIT",
	DEBIT = "DEBIT",
	REFUND = "REFUND",
	RESERVED = "RESERVED",
}
export enum TutorTransactionStatus {
	PENDING = "Pending",
	Approved = "Approved",
}

type TransactionHistory = {
	id: string;
	date: Timestamp;
	type: TransactionHistoryType;
	userId: string;
	sessionId?: string;
};

export type StudentTransactionHistory = TransactionHistory & {
	noOfPoints: number;
	student?: Student;
};

export type TutorTransactionHistory = TransactionHistory & {
	amount: number;
	status: TutorTransactionStatus;
	tutor?: Tutor;
};
