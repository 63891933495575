import { baseService } from "./base.store";
import { findStatistics } from "../services/statistics.service";

export type Statistics = {
	organizations: number;
	organizationStudents: number;
	students: number;
	tutors: number;
	withdrawalRequests: number;
	bookings: number;
	cancellationRequests: number;
	redeemedVouchersCodes: number;
	sessions: number;
};

export const statisticsService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getStatistics: builder.query<{ statistics: Statistics }, void>({
			queryFn: async () => await findStatistics(),
			providesTags: ["Booking"],
		}),
	}),
});

export const { useGetStatisticsQuery } = statisticsService;
