export type Organization = {
	id: string;
	name: string;
	email: string;
	vouchersCodesCount: number;
	activeStudentsCount: number;
};
export const defaultOrganization = {
	vouchersCodesCount: 0,
	activeStudentsCount: 0,
};

export type CreateOrganization = Pick<Organization, "name" | "email"> & {
	password: string;
};
