enum Gender {
  MALE = 'm',
  FEMALE = 'f'
}
export enum TutorAccountType {
  FREE = 'FREE',
  PAID = 'PAID'
}
export type Tutor = {
  id: string;
  name: string;
  email: string;
  totalAvailableCredit: number;
  totalCredit: number;
  gender: Gender;
  noOfSessions: number;
  mobileNo: string;
  accountType: TutorAccountType;
  isPrivate?: boolean;
  country: boolean;
  nativeLanguage?: string;
  profileImage: string;
  about?: string;
  isAccepted: boolean;
};
