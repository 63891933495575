import { SubmitHandler, useForm } from "react-hook-form";
import { getDateAfterYear } from "../../../../utils/dateHandler.util";
import { useGetOrganizationsQuery } from "../../../../store/organization.store";
import { useEffect } from "react";
import { useBulkAddVouchersCodesMutation } from "../../../../store/voucherCode.store";
import CopyTextToClipBoard from "../../../copyTextToCilpboard";

type CreateVoucherCodeParam = {
    noOfPoints: string;
    organizationId: string;
}

type Props = {
    vouchersCodes: string[],
    handleSuccess: () => void
}

const CreateOrganizationVoucherCodeForm = ({ vouchersCodes, handleSuccess }: Props) => {
    const organizations = useGetOrganizationsQuery();
    const [addOrganizationVouchersCode, addOrganizationVouchersCodeResult] = useBulkAddVouchersCodesMutation()
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<CreateVoucherCodeParam>();
    
    useEffect(() => {
        if (addOrganizationVouchersCodeResult.isSuccess)
            handleSuccess()
    }, [addOrganizationVouchersCodeResult, handleSuccess]);

    const submitHandler : SubmitHandler<CreateVoucherCodeParam> = ({ organizationId, noOfPoints }) => {
        const expiryDate = getDateAfterYear();
        const vouchersCodesData = vouchersCodes.map(voucherCode => ({ code: voucherCode, organizationId, noOfPoints: parseInt(noOfPoints), expiryDate }));
        addOrganizationVouchersCode(vouchersCodesData);
    }

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <input className={`form-control form-control-lg mb-3 ${errors.noOfPoints ? 'border-danger' : ''}`} 
                type="number"
                placeholder="Enter number of points"
                {...register('noOfPoints', { required: true })}
            />
            {
                organizations.isSuccess ?
                    <select 
                        className={`form-control form-control-lg mb-3 ${errors.noOfPoints ? 'border-danger' : ''}`} 
                        aria-label="select organization"
                        {...register("organizationId", { required: true })}
                        onChange={(e) => setValue("organizationId", e.target.value)}
                    >
                        <option value="">select organization</option>
                        {
                            organizations.data.organizations?.map(organization => (
                                <option 
                                value={organization.id} 
                                key={organization.id}
                                >{organization.name}</option>
                            ))
                        }
                    </select>
                    : null
            }
            <CopyTextToClipBoard text={vouchersCodes.join("\n")} />
            {
                addOrganizationVouchersCodeResult.error ? <div className="alert alert-danger mb-3" role="alert">something went wrong</div> : null
            }
            <div className="text-center">
                <button 
                    type="submit" 
                    className="btn btn-primary btn-lg w-25" 
                    disabled={addOrganizationVouchersCodeResult.isLoading}
                >add</button>
            </div>
        </form>
    )
}
export default CreateOrganizationVoucherCodeForm;