import { useEffect, useState } from 'react';

interface PaginationResultIsLoadingOrIsIsError<T> {
  data?: undefined;
  numberOfPages?: number;
  next: () => void;
  updateQuery: (newQuery: any) => void;
  prev: () => void;
  currentPage: number;
  isLoading: boolean;
  isSuccess: false;
  isError: boolean;
}
interface PaginationResultIsLoading<T> {
  data: T[];
  numberOfPages: number;
  next: () => void;
  updateQuery: (newQuery: any) => void;
  prev: () => void;
  currentPage: number;
  isLoading: boolean;
  isSuccess: true;
  isError: boolean;
}

type Props = {
  useGetDataQuery: any;
  useCountData: any;
  initQuery?: any;
};
const getDataFromQueryData = <T extends any>(data: any): T[] => {
  return Object.entries(data)[0][1] as T[];
};

const usePagination = <T extends { id: string }>({
  useGetDataQuery,
  useCountData,
  initQuery
}: Props):
  | PaginationResultIsLoadingOrIsIsError<T>
  | PaginationResultIsLoading<T> => {
  const [pagesIds, setPagesIds] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [query, setquery] = useState<any>(initQuery || {});
  const {
    isLoading: isLoadingData,
    isSuccess: isSuccessData,
    data,
    isErrorData
  } = useGetDataQuery({ where: query, offset: pagesIds[currentIndex - 1] });
  const {
    isLoading: isLoadingCount,
    isSuccess: isSuccessCount,
    data: count,
    isError: isErrorCount
  } = useCountData({ where: query });
  const updateQuery = async (newQuery: any) => {
    setquery(newQuery);
    setPagesIds([]);
    setCurrentIndex(0);
  };
  useEffect(() => {
    if (data && getDataFromQueryData(data).length == 0 && currentIndex > 0) {
      setPagesIds([
        ...pagesIds.slice(0, currentIndex),
        ...pagesIds.slice(currentIndex + 1)
      ]);
      setCurrentIndex(currentIndex - 1);
    }
  }, [data]);

  const getNextPage = () => {
    const newPageIndex = currentIndex + 1;
    if (newPageIndex > Math.ceil(count.count / 10)) return;

    if (pagesIds[newPageIndex]) setCurrentIndex(newPageIndex);

    if (!pagesIds[newPageIndex]) {
      const dataValues = getDataFromQueryData<T>(data);
      setPagesIds([...pagesIds, dataValues[dataValues.length - 1].id]);
      setCurrentIndex(newPageIndex);
    }
  };
  const getPrevPage = () => {
    if (currentIndex == 0) return;
    setCurrentIndex(currentIndex - 1);
  };
  return {
    isError: isErrorData || isErrorCount,
    isLoading: isLoadingData && isLoadingCount,
    isSuccess: isSuccessData && isSuccessCount,
    data:
      isSuccessData && isSuccessCount
        ? getDataFromQueryData<T>(data)
        : undefined,
    numberOfPages: isSuccessCount ? Math.ceil(count.count / 10) : 0,
    next: () => getNextPage(),
    updateQuery: (newQuery: any) => updateQuery(newQuery),
    prev: () => getPrevPage(),
    currentPage: currentIndex + 1
  };
};
export default usePagination;

// import { useEffect, useState } from "react";
// import { Model } from "../firebase/firestore/base.model"

// interface PaginationResult<T> {
//     data: T[];
//     numberOfPages: number;
//     next: () => void;
//     updateQuery: (newQuery: any) => void;
//     prev: () => void;
//     currentPage: number;
// }

// const usePagination = <T extends any>(model: Model, orderBy: [string, "asc" | "desc"]) : PaginationResult<T> => {
//     const [pagesIds, setPagesIds] = useState<string[]>([]);
//     const [currentIndex, setCurrentIndex] = useState<number>(0);
//     const [numberOfPages, setNumberOfPages] = useState<number>(0);
//     const [query, setquery] = useState<any>({});
//     const [currentPageDocuments, setCurrentPageDocuments] = useState<any[]>([]);

//     useEffect(() => {
//         const fetchData = async () => {
//             const data = await model.findAll<T>(query, pagesIds[currentIndex -1], orderBy);
//             setCurrentPageDocuments(data);
//         }
//         fetchData();
//     }, [query, currentIndex]);

//     useEffect(() => {
//         const fetchData = async () => {
//             const documentsCount = await model.count(query);
//             setNumberOfPages(Math.ceil(documentsCount / 10))
//         };
//         fetchData();
//     }, [query, setNumberOfPages]);

//     const updateQuery = async (newQuery: any) => {
//         setquery(newQuery);
//         setPagesIds([]);
//         setCurrentIndex(0);
//     }

//     const getNextPage = () => {
//         const newPageIndex = currentIndex + 1;
//         if (newPageIndex> numberOfPages)
//             return;

//         if (pagesIds[newPageIndex])
//             setCurrentIndex(newPageIndex);

//         if (!pagesIds[newPageIndex]) {
//             setPagesIds([...pagesIds, currentPageDocuments[currentPageDocuments.length -1].id]);
//             setCurrentIndex(newPageIndex);
//         }
//     }
//     const getPrevPage = () => {
//         if (currentIndex == 0)
//             return;
//         setCurrentIndex(currentIndex - 1);
//     }
//     return {
//         data: currentPageDocuments,
//         numberOfPages,
//         next: () => getNextPage(),
//         updateQuery: (newQuery: any) => updateQuery(newQuery),
//         prev: () => getPrevPage(),
//         currentPage: currentIndex +1,
//     }
// }
// export default usePagination;
