import { Link } from 'react-router-dom';
import {
  VoucherCode,
  VoucherCodeStatus
} from '../../../entities/voucherCode.entity';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useUpdateVoucherCodeByIdMutation } from '../../../store/voucherCode.store';
import { displayDate } from '../../../utils/dateHandler.util';

const VoucherCodeDetails: React.FC<{ voucherCode: VoucherCode }> = ({
  voucherCode: {
    id,
    code,
    status,
    expiryDate,
    noOfPoints,
    redeemedBy,
    redeemedDate,
    student,
    organization
  }
}) => {
  const isExpired =
    status === VoucherCodeStatus.AVAILABLE && expiryDate.toDate() < new Date();
  const [openEditing, setOpenEditing] = useState<boolean>();
  const [updateVoucherCode, updateVoucherCodeResult] =
    useUpdateVoucherCodeByIdMutation();
  const [showSuccess, setShowSuccess] = useState<boolean>();
  const { register, handleSubmit, watch, setValue } =
    useForm<Partial<VoucherCode>>();

  const submitHandler: SubmitHandler<Partial<VoucherCode>> = (values) => {
    updateVoucherCode({ id, newData: { noOfPoints: values.noOfPoints } });
  };
  useEffect(() => {
    if (updateVoucherCodeResult.isSuccess) {
      setShowSuccess(true);
      setOpenEditing(false);
      const timeout = setTimeout(() => setShowSuccess(false), 3000);
      return () => clearTimeout(timeout);
    }
  }, [updateVoucherCodeResult.isSuccess]);
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className='mb-3'>
          <label className='form-label'>code</label>
          <input
            type='text'
            className='form-control'
            placeholder='code'
            value={code}
            disabled={true}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>status</label>
          <input
            type='text'
            className='form-control'
            placeholder='status'
            value={isExpired ? 'EXPIRED' : status}
            disabled={true}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>number of points</label>
          <input
            type='number'
            className='form-control'
            placeholder='noOfPoints'
            defaultValue={noOfPoints}
            disabled={!openEditing}
            {...register('noOfPoints', { required: true })}
          />
        </div>{' '}
        <div className='mb-3'>
          <label className='form-label'>remaining points</label>
          <span className='  form-control tw-bg-[#e9ecef]'>
            {student?.points ? student?.points : noOfPoints}
          </span>
        </div>
        <div className='mt-4 text-center'>
          {showSuccess && (
            <Alert className='mb-2' variant='success'>
              voucher code is updated successfully
            </Alert>
          )}
          {openEditing && !isExpired ? (
            <div>
              <Button
                variant='secondary'
                onClick={() => {
                  setValue('noOfPoints', noOfPoints);
                  setOpenEditing(false);
                }}
                className='me-3'
              >
                cancel
              </Button>
              <Button
                variant='primary'
                type='submit'
                disabled={
                  !watch('noOfPoints') || watch('noOfPoints') === noOfPoints
                    ? true
                    : false
                }
              >
                save
              </Button>
            </div>
          ) : (
            <Button
              variant='primary'
              onClick={() => setOpenEditing(true)}
              disabled={
                isExpired || status === VoucherCodeStatus.REDEEMED
                  ? true
                  : false
              }
            >
              edit
            </Button>
          )}
        </div>
      </form>
      {status === VoucherCodeStatus.REDEEMED && (
        <div className='mt-4'>
          <h4 className='text-center mb-3'>Redemption</h4>
          <p>
            <strong>Student:</strong>{' '}
            <Link
              to={`/students/${redeemedBy}`}
              className='text-decoration-none'
            >
              {student?.name}
            </Link>
          </p>
          <p>
            <strong>Student Email:</strong>{' '}
            {student?.email}
          </p>
          <p>
            <strong>Redeemed Date:</strong>{' '}
            {redeemedDate ? displayDate(redeemedDate) : ''}
          </p>
        </div>
      )}

      {organization && (
        <div className='mt-4'>
          <h4 className='text-center mb-3'>Organization</h4>
          <p>
            <strong>Name:</strong>{' '}
            <Link
              to={`/organizations/${organization.id}`}
              className='text-decoration-none'
            >
              {organization.name}
            </Link>
          </p>
          <p>
            <strong>Email:</strong> {organization.email}
          </p>
        </div>
      )}
    </>
  );
};

export default VoucherCodeDetails;

// import { Link } from "react-router-dom";
// import { VoucherCode, VoucherCodeStatus } from "../../entities/voucherCode.entity";

// const VoucherCodeDetails: React.FC<{voucherCode: VoucherCode}> = ({
//     voucherCode: {
//         code,
//         status,
//         expiryDate,
//         noOfPoints,
//         redeemedBy,
//         redeemedDate,
//         student,
//         organization,
//     }
// }) => {
//   const isExpired = status === VoucherCodeStatus.AVAILABLE && expiryDate.toDate() < new Date();

//   return (
//     <>
//         <div className="mb-3">
//             <p>
//                 <strong>Code:</strong> {code}
//             </p>
//             <p>
//                 <strong>Status:</strong> {isExpired ? 'EXPIRED' : status}
//             </p>
//             <p>
//                 <strong>Expiry Date:</strong> {expiryDate.toDate().toDateString()}
//             </p>
//             <p>
//                 <strong>Points:</strong> {noOfPoints}
//             </p>
//         </div>

//         {status === VoucherCodeStatus.REDEEMED && (
//             <div className="my-2">
//                 <h5>Redemption Details</h5>
//                 <p>
//                     <strong>Redeemed By:</strong> <Link to={`/students/${redeemedBy}`}>{student?.name}</Link>
//                 </p>
//                 <p>
//                     <strong>Redeemed Date:</strong> {redeemedDate?.toDate().toDateString()}
//                 </p>
//             </div>
//         )}

//         {
//             organization && (
//                 <div className="my-2">
//                     <h5>Organization Details</h5>
//                     <p>
//                         <strong>Name:</strong> <Link to={`/organizations/${organization.id}`}>{organization.name}</Link>
//                     </p>
//                     <p>
//                         <strong>Email:</strong> {organization.email}
//                     </p>
//                 </div>
//             )
//         }
//     </>
//   );
// };

// export default VoucherCodeDetails;
