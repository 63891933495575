import { Button } from "react-bootstrap";
import { useCompleteUnCompletedBookingsAdministrationMutation } from "../../store/booking.store"

const CompleteBookingAdministrationButton = () => {
    const [completeBookingsAdministration, completeBookingsAdministrationResult] = useCompleteUnCompletedBookingsAdministrationMutation();

    return (
        <Button 
            variant="primary" 
            onClick={() => completeBookingsAdministration()}
            disabled={completeBookingsAdministrationResult.isLoading}
        >complete un adminstration bookings</Button>
    )
}

export default CompleteBookingAdministrationButton;