import { Navigate, useParams } from 'react-router-dom';
import PageLoading from '../../../components/pageLoading';
import PageHeader from '../../../components/pageHeader';
import { useGetApplyingTutorByIdQuery } from '../../../store/tutor.store';
import TutorInfo from '../../../components/tutors/tutor/tutorInfo';
import ApproveApplyingTutorButton from '../../../components/applyingTutors/approveApplyingTutorButton';
import RejectApplyingTutorButton from '../../../components/applyingTutors/rejectApplyingTutorButton';

const ShowApplyingTutor = () => {
    let { id = '' } = useParams();
    const { data, isSuccess, isLoading } = useGetApplyingTutorByIdQuery(id, {
        skip: id ? false : true
    });
 
    return isLoading ? (
        <PageLoading />
    ) : isSuccess ? (
            <div className='page_container'>
            <PageHeader text='Applying Tutor' />
            <TutorInfo tutor={data.tutor} />
            <div className='text-center mt-4'>
                <span className='me-3'>
                    <ApproveApplyingTutorButton tutorId={data.tutor.id} redirectToTutorPage />
                </span>
                <span>
                    <RejectApplyingTutorButton tutorId={data.tutor.id}/>
                </span>
            </div>
        </div>
    ) : (
        <Navigate to='/404' />
    );
};

export default ShowApplyingTutor;
