import { IoSend } from 'react-icons/io5';

export const SendMSG_BTN = ({
  className,
  onClick
}: {
  className?: string;
  onClick?(): void;
}) => {
  return (
    <button
      className={` tw-size-8 circle-bg-hover tw-z-50  ${className} `}
      onClick={onClick}
    >
      <IoSend className={`tw-text-gray-600  tw-text-xl  `} />
    </button>
  );
};
