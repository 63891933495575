import { Timestamp } from "firebase/firestore";
import { Tutor } from "./tutor.entity";
export type AvailableSlot = {
	id: string;
	createdAt: Timestamp;
	date: Timestamp;
	order: number;
	slotName: string;
	status: SlotStatus;
	timeZone: string;
	tutorId: string;
	tutor?: Tutor;
};

export enum SlotStatus {
	REQUESTED = "REQUESTED",
	AVAILABLE = "AVAILABLE",
	CONFIRMED = "CONFIRMED",
	BOOKED = "BOOKED",
}
