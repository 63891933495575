import { useCallback, useEffect, useState } from 'react';
import { LuRadioTower } from 'react-icons/lu';

import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Query, collection } from 'firebase/firestore';
import { fireStoreCollectionName } from '../../../utils/firebase.util';
import { db } from '../../../firebase';
import { buildFilterQuery_v2 } from '../../../firebase/firestore/filterQueryBuilder';
import {
  ChatStatus,
  ChatSupport,
  ChatSupportDoc
} from '../../../entities/chat-support.entity';
import { chatSupportConverter } from '../../../services/chat-support/chat-support.mapper.service';
import { FireDoc } from '../../../types/util.types';
import { removeDuplicatesFromOldArrById } from '../../../utils/util';

export const ChatNavNewChats = () => {
  const {
    states: {
      chats: { setChats, chats },
      filter: { filter }
    },
    actions
  } = useChatSupport();

  const chat_supportQuery = useCallback(() => {
    const collectionRef = collection(db, fireStoreCollectionName.chat_support);
    const query = buildFilterQuery_v2<ChatSupport>(
      collectionRef,
      filter.status === 'Open'
        ? [['status', '==', ChatStatus.OPEN]]
        : undefined,
      30,
      undefined,
      ['createdAt', 'desc']
    );
    return query.withConverter(chatSupportConverter) as Query<
      FireDoc<ChatSupportDoc>['DocWithIdAndRef']
    >;
  }, [filter]);

  const [query, setQuery] =
    useState<Query<FireDoc<ChatSupportDoc>['DocWithIdAndRef']>>();

  useEffect(() => {
    setQuery(chat_supportQuery);
  }, [filter, chat_supportQuery]);
  const [values, loading, error, snapshot] = useCollectionData(query);
  const handleNewChatsListener = useCallback(async () => {
    if (!values || !values.length) return;

    const existingChatsIds = chats.map((doc) => doc.id);
    const filterdListenerChats = values.filter(
      (doc) => !existingChatsIds.includes(doc.id)
    );

    if (filterdListenerChats.length === 0) return;
    const newChatsWithStudentAndLastMsgs =
      await actions.getChatSummariesWithLastMessageAndUserData(
        filterdListenerChats
      );
    setChats((old) => {
      const filterdOld = removeDuplicatesFromOldArrById(
        old,
        newChatsWithStudentAndLastMsgs
      );
      return [...newChatsWithStudentAndLastMsgs, ...filterdOld];
    });
  }, [values, actions, chats, setChats]);
  useEffect(() => {
    if (values?.length) handleNewChatsListener();
  }, [values, handleNewChatsListener]);

  return (
    <LuRadioTower className=' tw-animate-ping   tw-text-md tw-mx-auto tw-absolute tw-top-[10px] tw-end-[50px] tw-text-green-500' />
  );
};
