import { SubmitHandler, useForm } from 'react-hook-form';
import { useCreateOrganizationMutation } from '../../store/organization.store';
import PageHeader from '../../components/pageHeader';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CreateOrganization as NewOrganization } from '../../entities/organization.entity';

const CreateOrganization = () => {
  const [redirect, setRedirect] = useState<boolean>();
  const [createOrganization, createOrganizationResult] =
    useCreateOrganizationMutation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NewOrganization>();

  const submitHandler: SubmitHandler<NewOrganization> = (data) => {
    createOrganization(data);
  };

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined;
    if (createOrganizationResult.isSuccess) {
      timeout = setTimeout(() => {
        setRedirect(true);
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [createOrganizationResult]);

  return (
    <div className='page_container'>
      <PageHeader text='Add organization' />
      <form onSubmit={handleSubmit(submitHandler)}>
        <input
          className={`form-control form-control-lg mb-3${
            errors.name ? ' border-danger' : ''
          }`}
          type='text'
          placeholder='name'
          aria-label='name'
          {...register('name', { required: true })}
        />
        <input
          className={`form-control form-control-lg mb-3${
            errors.email ? ' border-danger' : ''
          }`}
          type='email'
          placeholder='email'
          aria-label='email'
          {...register('email', { required: true })}
        />
        <input
          className={`form-control form-control-lg mb-3${
            errors.password ? ' border-danger' : ''
          }`}
          type='password'
          placeholder='password'
          aria-label='password'
          {...register('password', { required: true })}
        />
        <input
          className={`form-control form-control-lg mb-3`}
          type='password'
          placeholder='confirm password'
          aria-label='confirm password'
          required
        />

        {createOrganizationResult.error ? (
          <div className='alert alert-danger mb-3' role='alert'>
            there is organization with either email or name
          </div>
        ) : null}
        <div className='text-center'>
          <button type='submit' className='btn btn-primary btn-lg w-25'>
            save
          </button>
        </div>
      </form>
      {redirect && <Navigate to='/organizations'></Navigate>}
    </div>
  );
};
export default CreateOrganization;
