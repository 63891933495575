import { baseService } from "./base.store";
import { login, logout } from "../services/auth.service";

// Define a service using a base URL and expected endpoints
export const authService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<void, { email: string; password: string }>({
			queryFn: async ({ email, password }) =>
				await login({ email, password }),
		}),
		logout: builder.mutation<void, void>({
			queryFn: async () => await logout(),
		}),
	}),
});

export const { useLoginMutation, useLogoutMutation } = authService;
