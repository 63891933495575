import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import {
  ChatContextFilter,
  ChatSummary
} from '../../types/chat-support/chat-support.type';
import { ChatSupportServiceI } from '../../types/chat-support/ChatSupportServiceI';

export interface ChatSupportContextType {
  states: {
    chats: {
      chats: ChatSummary[];
      setChats: Dispatch<SetStateAction<ChatSummary[]>>;
      isfetching: boolean;
      setIsFetching: (val: boolean) => void;
    };
    activeChatRoom: {
      activeChatRoom: ChatSummary;
      setActiveChatRoom: Dispatch<SetStateAction<ChatSummary>>;
    };
    filter: {
      filter: ChatContextFilter;
      updateFilter: (newFilter: ChatContextFilter) => void;
    };
  };
  actions: ChatSupportServiceI;
}
export const ChatSupportContext = createContext<ChatSupportContextType>(
  {} as ChatSupportContextType
);

export const useChatSupport = () => {
  const chatSupportContext = useContext(ChatSupportContext);

  if (!chatSupportContext) {
    throw new Error(
      'chatSupportContext has to be used within <ChatSupportContext.Provider>'
    );
  }

  return chatSupportContext;
};
