import { Pagination as PaginationContainer } from "react-bootstrap";

type Props = {
    currentPage: number;
    numberOfPages: number;
    prev: () => void;
    next: () => void;
}

const Pagination = ({ prev, next, currentPage, numberOfPages }: Props ) => {
    return (
        <div className="d-flex justify-content-center">
            <PaginationContainer>
                <PaginationContainer.Prev disabled={currentPage === 1 ? true : false } onClick={() => prev()}>prev</PaginationContainer.Prev>
                <PaginationContainer.Item disabled>{currentPage}</PaginationContainer.Item>
                <PaginationContainer.Next disabled={currentPage < numberOfPages  ? false : true } onClick={() => next()}>Next</PaginationContainer.Next>
            </PaginationContainer>
        </div>
    )
}
export default Pagination;