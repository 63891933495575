import { Col, Row } from 'react-bootstrap';
import PageHeader from '../../components/pageHeader';
import CancellationRequestsTable from '../../components/cancellationRequests/cancellationRequestsTable';
import ApproveAllCancellationRequestsButton from '../../components/cancellationRequests/approveAllCancellationRequestsButtton';
import CancellationRequestsSelectByStatus from '../../components/cancellationRequests/CancellationRequestsSelectByStatus';
import { CancellationRequest } from '../../entities/cancellationRequest.entity';
import usePagination from '../../customHooks/usePagination.hook';
import {
  useCountCancellationRequestsQuery,
  useGetCancellationRequestsQuery
} from '../../store/cancellationRequest.store';
import { Navigate } from 'react-router-dom';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';

const CancellationRequests = () => {
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError
  } = usePagination<CancellationRequest>({
    useGetDataQuery: useGetCancellationRequestsQuery,
    useCountData: useCountCancellationRequestsQuery
  });

  const updateFilterQuery = (newValues: any) => {
    updateQuery({
      ...newValues
    });
  };

  return (
    <div className='page_container'>
      <PageHeader text='Bookings Cancellation Requests' />
      <Row className='pt-3 mb-3'>
        <Col sm={9}>
          <CancellationRequestsSelectByStatus
            setNewStatus={(newStatus) =>
              updateFilterQuery({ status: newStatus })
            }
          />
        </Col>
        <Col sm={3} className='text-center'>
          <ApproveAllCancellationRequestsButton cancellationRequests={data} />
        </Col>
      </Row>
      {isSuccess && data ? (
        <>
          <CancellationRequestsTable cancellationRequests={data} />
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            prev={prev}
            next={next}
          />
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default CancellationRequests;

// import { Col, Pagination, Row } from "react-bootstrap";
// import PageHeader from "../../components/pageHeader";
// import { useState } from "react";
// import CancellationRequestsTable from "../../components/cancellationRequests/cancellationRequestsTable";
// import ApproveAllCancellationRequestsButton from "../../components/cancellationRequests/approveAllCancellationRequestsButtton";
// import CancellationRequestsSelectByStatus from "../../components/cancellationRequests/CancellationRequestsSelectByStatus";
// import { CancellationRequest, CancellationRequestStatus } from "../../entities/cancellationRequest.entity";
// import usePagination from "../../utils/usePagination.hook";
// import { cancellationRequestModel } from "../../models/cancellationRequest.model";

// const CancellationRequests = () => {
//     const { data, numberOfPages, currentPage, next, prev, updateQuery } = usePagination<CancellationRequest>(cancellationRequestModel, ["cancellationDate", "desc"])

//     const updateFilterQuery = (newValues: any) => {
//         updateQuery({
//             ...newValues
//         });
//     }

//     return (
//         // isSuccess ?
//             <>
//                 <PageHeader text="Bookings Cancellation Requests"/>
//                 {
//                     // cancellationRequestsCount.isSuccess ? (
//                         <>
//                             <Row className="pt-3 mb-3">
//                                 <Col sm={9}>
//                                     <CancellationRequestsSelectByStatus setNewStatus={newStatus => updateFilterQuery({ status: newStatus }) } />
//                                 </Col>
//                                 <Col sm={3} className="text-center">
//                                     <ApproveAllCancellationRequestsButton cancellationRequests={data}/>
//                                 </Col>
//                             </Row>
//                             <CancellationRequestsTable cancellationRequests={data}/>
//                             <div className="d-flex justify-content-center">
//                                 <Pagination>
//                                     <Pagination.Prev disabled={currentPage === 1 ? true : false } onClick={() => prev()}>prev</Pagination.Prev>
//                                     <Pagination.Item disabled>{currentPage}</Pagination.Item>
//                                     <Pagination.Next disabled={currentPage < numberOfPages  ? false : true } onClick={() => next()}>Next</Pagination.Next>
//                                 </Pagination>
//                             </div>
//                         </>
//                     // ): null
//                 }
//             </>
//         // : isError ?  <Navigate to="/505" />
//         // : <PageLoading />
//     )
// }

// export default CancellationRequests;

// import { Col, Pagination, Row } from "react-bootstrap";
// import PageHeader from "../../components/pageHeader";
// import PageLoading from "../../components/pageLoading";
// import { useCountCancellationRequestsQuery, useGetCancellationRequestsQuery } from "../../store/cancellationRequest.store";
// import { useState } from "react";
// import { Navigate } from "react-router-dom";
// import CancellationRequestsTable from "../../components/cancellationRequests/cancellationRequestsTable";
// import ApproveAllCancellationRequestsButton from "../../components/cancellationRequests/approveAllCancellationRequestsButtton";
// import CancellationRequestsSelectByStatus from "../../components/cancellationRequests/CancellationRequestsSelectByStatus";
// import { CancellationRequestStatus } from "../../entities/cancellationRequest.entity";

// type CancellationRequestFilterQuery = {
//     status?: CancellationRequestStatus,
//     requestId?: string;
//     paginateType: "prev" | "next";
// };

// const CancellationRequests = () => {
//     const [currentPage, setCurrentPage] = useState<number>(1)
//     const [filterQuery, setFilterQuery] = useState<CancellationRequestFilterQuery>({ paginateType: "next"})
//     const { isError, isSuccess, data } = useGetCancellationRequestsQuery({
//         where: { status: filterQuery.status },
//         offset: filterQuery.requestId ? [filterQuery.requestId, filterQuery.paginateType] : undefined
//     });

//     const cancellationRequestsCount = useCountCancellationRequestsQuery({ where: { status: filterQuery.status }});
//     const updatePage = (value: number) => {
//         setCurrentPage(currentPage + value);
//         if (data && data.cancellationRequests.length > 0)
//             setFilterQuery({
//                 ...filterQuery,
//                 paginateType: value===1? "next" : "prev",
//                 requestId: data.cancellationRequests[value === 1 ? data.cancellationRequests.length -1 : 0].id
//             })
//     }
//     const updateFilterQuery = (newValues: any) => {
//         console.log("updateFilterQuery", newValues)
//         setCurrentPage(1);
//         setFilterQuery({
//             ...newValues,
//             paginateType: "next",
//         });
//     }
//     console.log(cancellationRequestsCount.data, filterQuery);
//     console.log(data?.cancellationRequests)
//     return (
//         isSuccess ?
//             <>
//                 <PageHeader text="Bookings Cancellation Requests"/>
//                 {
//                     cancellationRequestsCount.isSuccess ? (
//                         <>
//                             <Row className="pt-3 mb-3">
//                                 <Col sm={9}>
//                                     <CancellationRequestsSelectByStatus setNewStatus={newStatus => updateFilterQuery({ status: newStatus }) } />
//                                 </Col>
//                                 <Col sm={3} className="text-center">
//                                     <ApproveAllCancellationRequestsButton cancellationRequests={data.cancellationRequests}/>
//                                 </Col>
//                             </Row>
//                             <CancellationRequestsTable cancellationRequests={data.cancellationRequests}/>
//                             <div className="d-flex justify-content-center">
//                                 <Pagination>
//                                     <Pagination.Prev disabled={currentPage === 1 ? true : false } onClick={() => updatePage(-1)}>prev</Pagination.Prev>
//                                     <Pagination.Item disabled>{currentPage}</Pagination.Item>
//                                     <Pagination.Next disabled={currentPage < Math.ceil(cancellationRequestsCount.data.count / 10) ? false : true } onClick={() => updatePage(1)}>Next</Pagination.Next>
//                                 </Pagination>
//                             </div>
//                         </>
//                     ): null
//                 }
//             </>
//         : isError ?  <Navigate to="/505" />
//         : <PageLoading />
//     )
// }

// export default CancellationRequests;
