import { Table } from "react-bootstrap";
import { Tutor } from "../../entities/tutor.entity";
import ApproveApplyingTutorButton from "./approveApplyingTutorButton";
import RejectApplyingTutorButton from "./rejectApplyingTutorButton";
import { Link } from "react-router-dom";

const ApplyingTutorsTable = ({ tutors }: { tutors: Tutor[] }) => {
    return (
        <Table striped bordered hover responsive>
            <thead className="text-light">
                <tr className="text-center">
                    <th>name</th>
                    <th>email</th>
                    <th>gender</th>
                    <th>country</th>
                    <th>approve</th>
                    <th>reject</th>
                </tr>
            </thead>
            <tbody>
                {tutors.map(tutor => (
                    <tr className="text-center" key={tutor.id}>
                        <td data-title="name" className="fs-6">
                            <Link to={"/applying-tutors/" + tutor.id}  className="text-decoration-none">{tutor.name}</Link>
                        </td>
                        <td data-title="email" className="fs-6">
                            {tutor.email}
                        </td>
                        <td data-title="gender" className="fs-6">
                            {tutor.gender}
                        </td>
                        <td data-title="country" className="fs-6">
                            {tutor.country}
                        </td>
                        <td data-title="approve" className="fs-6">
                            <ApproveApplyingTutorButton tutorId={tutor.id}/>
                        </td>
                        <td data-title="reject" className="fs-6">
                            <RejectApplyingTutorButton tutorId={tutor.id} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default ApplyingTutorsTable;