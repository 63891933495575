import { db, storage } from '..'; // Import your Firebase configuration
import {
  DocumentSnapshot,
  CollectionReference,
  addDoc,
  DocumentData,
  getDoc,
  doc,
  Query,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  limit,
  getCountFromServer,
  writeBatch,
  collection,
  OrderByDirection,
  onSnapshot,
  QuerySnapshot,
  DocumentReference
} from 'firebase/firestore';
import { buildFilterQuery, buildFilterQuery_v2 } from './filterQueryBuilder';
import { RepoistoryFilter } from '../../types/util.types';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Dispatch, SetStateAction } from 'react';

export class Repository<T> {
  protected collection: CollectionReference;

  constructor(protected collectionName: string) {
    this.collection = collection(db, collectionName);
  }

  protected map(doc: DocumentSnapshot): T & { id: string } {
    const data = doc.data() as T;
    return { ...data, id: doc.id } as T & { id: string };
  }
  protected mapTo<E>(doc: DocumentSnapshot): E {
    const data = doc.data() as E;
    return { ...data, id: doc.id } as E;
  }
  protected mapToDataWithIdAndRef<E extends { docRef: DocumentReference }>(
    doc: DocumentSnapshot
  ): E {
    const data = doc.data() as E;
    data.docRef = doc.ref;
    return { ...data, id: doc.id } as E;
  }
  protected async getDocByCollectionAndDocId(
    collection: CollectionReference,
    docId: string
  ) {
    const docSnapshot = await getDoc(doc(collection, docId));
    return docSnapshot;
  }
  async create(data: Partial<Omit<T, 'id'>>): Promise<string> {
    const docRef = await addDoc(this.collection, data as DocumentData);
    return docRef.id;
  }

  async findById(id: string): Promise<T | null> {
    // const doc = await this.collection.doc(adminId).get();
    const result = await getDoc(doc(db, this.collectionName, id));

    return result.exists() ? this.map(result) : null;
  }

  protected async findByCollectionAndId<D extends {}>(
    collectionName: string,
    id: string
  ): Promise<D & { id: string }> {
    const result = await getDoc(doc(db, collectionName, id));
    const docData = result.data() as D;
    return { ...docData, id: result.id };
  }

  async findByFilter(
    query: Omit<RepoistoryFilter<T>, 'docSnapshot'>
  ): Promise<QuerySnapshot<T>> {
    const docSnapshotPromise = async () => {
      if (!query.offsetDocId) return undefined;
      return this.getDocByCollectionAndDocId(
        this.collection,
        query.offsetDocId
      );
    };

    const docSnapshot = await docSnapshotPromise();
    const filterQuery = (await buildFilterQuery_v2(
      this.collection,
      query.where as RepoistoryFilter['where'],
      query.limit || 10,
      docSnapshot,
      query.orderBy as RepoistoryFilter['orderBy']
    )) as Query<T>;
    return getDocs(filterQuery);
  }

  async findAll(
    where?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection],
    limit?: number
  ): Promise<T[]> {
    const filterQuery = await buildFilterQuery(
      this.collection,
      where,
      limit || 10,
      offset,
      orderBy
    );
    const snapshot = await getDocs(filterQuery);
    return snapshot.docs.map<T>((doc) => this.map(doc));
  }
  async update(id: string, updates: Partial<T>): Promise<void> {
    await updateDoc(doc(db, this.collectionName, id), updates);
  }
  async delete(id: string): Promise<void> {
    await deleteDoc(doc(db, this.collectionName, id));
  }
  async checkFieldIsUnique(key: string, value: string): Promise<boolean> {
    const snapshot = await getDocs(
      query(this.collection, where(key, '==', value), limit(1))
    );
    return snapshot.docs.length === 0;
  }
  async count(where?: any): Promise<number> {
    const filterQuery = await buildFilterQuery(this.collection, where);
    const countResult = await getCountFromServer(filterQuery);
    return countResult.data().count;
  }
  async countByQuery(filter: Query): Promise<number> {
    const countResult = await getCountFromServer(filter);
    return countResult.data().count;
  }
  async bulkCreate<D>(data: Partial<Omit<T, 'id'>>[]): Promise<D[]> {
    const batch = writeBatch(db);
    let newDocumentsIds: D[] = [];
    data.forEach((data) => {
      const newDoc = doc(this.collection);
      batch.set(newDoc, data);
      newDocumentsIds.push({ id: newDoc.id, ...data } as D);
    });
    await batch.commit();
    return newDocumentsIds;
  }
  async findBy(filter: Query): Promise<T[]> {
    const snapshot = await getDocs(filter);
    return snapshot.docs.map((doc) => this.map(doc));
  }

  subscribeToQuery(
    query: Query,
    onDataChange: (snapshot: QuerySnapshot) => void
  ) {
    const unsubscribe = onSnapshot(
      query,
      { includeMetadataChanges: true },
      (snapshot) => {
        onDataChange(snapshot);
      },
      (error) => {
        console.error(error, 'subscribe to query error listeng is stopped');
      }
    );

    return unsubscribe;
  }
  subscribeToDocRef(
    docRef: DocumentReference,
    onDataChange: (snapshot: DocumentSnapshot) => void
  ) {
    const unsubscribe = onSnapshot(
      docRef,
      { includeMetadataChanges: true },
      (snapshot) => {
        onDataChange(snapshot);
      },
      (error) => {
        console.error(error, 'subscribe to query error listeng is stopped');
      }
    );

    return unsubscribe;
  }

  uploadFileToStorage(
    file: File,
    folderAndFileNames: string,
    setProgress?: Dispatch<SetStateAction<string>>
  ): Promise<string> {
    const storageRef = ref(storage, folderAndFileNames);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise((resolve, rej) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress && setProgress(progress.toFixed(0));
        },
        (error) => {
          rej(error);
        },
        async () => {
          const fileUrl = await getDownloadURL(storageRef);
          resolve(fileUrl);
        }
      );
    });
  }
}
