import { Alert } from 'react-bootstrap';
import {
  useGetPrivateStudentTutorsQuery,
  useGetStudentByIdQuery
} from '../../../store/student.store';
import { Navigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/pageHeader';
import TutorsSearch from '../../../components/students/student/addTutorToPrivateStudent/tutorSearch';
import PageLoading from '../../../components/pageLoading';

const PrivateStudentTutors = () => {
  const { id = '' } = useParams();
  const { data, isSuccess, isLoading, isError } =
    useGetPrivateStudentTutorsQuery({ id }, { skip: !id });
  const student = useGetStudentByIdQuery(id, { skip: !id });
  return student.isSuccess ? (
    <div className='page_container'>
      <PageHeader text={student.data.student.name} />
      {isSuccess && <TutorsSearch studentTutors={data.tutors} studentId={id} />}
      {isLoading && <PageLoading />}
      {isError && <Alert variant='error'>something went wrong</Alert>}
    </div>
  ) : student.isError ? (
    <Navigate to='/404' />
  ) : (
    <PageLoading />
  );
};

export default PrivateStudentTutors;
