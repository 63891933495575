import {
	StudentTransactionHistory,
	TutorTransactionHistory,
} from "../entities/transactionHistory.entity";
import { studentRepository } from "../repositories/student.repository";
import { studentTransactionHistoryRepository } from "../repositories/studentTransactionHistory.repository";
import { tutorRepository } from "../repositories/tutor.repository";
import { tutorTransactionHistoryRepository } from "../repositories/tutorTransactionHistory.repository";
import { GenericQueryReturnType } from "../store";

export const findTutorsTransactionsHistory = async (
	where: any,
	offset?: string
): Promise<
	GenericQueryReturnType<"transactions", TutorTransactionHistory[]>
> => {
	try {
		const transactions = await tutorTransactionHistoryRepository.findAll(
			where,
			offset,
			["date", "desc"]
		);
		const tutorsTransactions = await Promise.all(
			transactions.map(async (transaction) => {
				const tutor = transaction.userId
					? await tutorRepository.findById(transaction.userId)
					: undefined;
				return {
					...transaction,
					tutor: tutor || undefined,
				};
			})
		);

		return {
			data: {
				transactions: tutorsTransactions,
			},
		};
	} catch (_) {
		console.log(_);
		return {
			error: true,
		};
	}
};
export const findStudentsTransactionsHistory = async (
	where: any,
	offset?: string
): Promise<
	GenericQueryReturnType<"transactions", StudentTransactionHistory[]>
> => {
	try {
		const transactions = await studentTransactionHistoryRepository.findAll(
			where,
			offset,
			["date", "desc"]
		);
		const studentsTransactions = await Promise.all(
			transactions.map(async (transaction) => {
				const student = transaction.userId
					? await studentRepository.findById(transaction.userId)
					: undefined;
				return {
					...transaction,
					student: student || undefined,
				};
			})
		);
		return {
			data: {
				transactions: studentsTransactions,
			},
		};
	} catch (_) {
		console.log(_);
		return {
			error: true,
		};
	}
};
export const findStudentTransactionHistoryById = async (
	userId: string
): Promise<
	GenericQueryReturnType<"transactions", StudentTransactionHistory[]>
> => {
	try {
		const transactions = await studentTransactionHistoryRepository.findAll({
			userId,
		});
		return {
			data: {
				transactions,
			},
		};
	} catch (_) {
		return {
			error: true,
		};
	}
};

export const findTutorTransactionHistoryById = async (
	userId: string
): Promise<
	GenericQueryReturnType<"transactions", TutorTransactionHistory[]>
> => {
	try {
		const transactions = await tutorTransactionHistoryRepository.findAll({
			userId,
		});
		return {
			data: {
				transactions,
			},
		};
	} catch (_) {
		return {
			error: true,
		};
	}
};

export const countTutorsTransactions = async (
	where?: any
): Promise<GenericQueryReturnType<"count", number>> => {
	try {
		const count = await tutorTransactionHistoryRepository.count(where);
		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};
export const countStudentsTransactions = async (
	where?: any
): Promise<GenericQueryReturnType<"count", number>> => {
	try {
		const count = await studentTransactionHistoryRepository.count(where);
		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};
