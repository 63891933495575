import { useRef, useState } from 'react';
import { ChatStatus } from '../../../entities/chat-support.entity';
import { SlOptionsVertical } from 'react-icons/sl';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useOutsideClick } from '../../../customHooks/use-outside-click';

type Props = {
  status: ChatStatus;
};

export const ActiveChatHeaderMenu = ({ status }: Props) => {
  const { actions } = useChatSupport();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const closeMenu = () => {
    if (!isOpen) return;
    setIsOpen(false);
  };
  useOutsideClick(menuRef, closeMenu, isOpen, [isOpen]);
  return (
    <div ref={menuRef} className='tw-relative tw-z-50 '>
      <div className='tw-size-8 flex_center  tw-rounded-full   hover:tw-bg-gray-200 '>
        <SlOptionsVertical
          className='tw-cursor-pointer'
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>

      <div
        className={`tw-absolute tw-w-[150px] tw-bg-white tw-p-2 tw-z-50 tw-end-[2px] tw-top-7  tw-drop-shadow-md tw-rounded-md ${
          isOpen ? 'tw-flex tw-flex-col' : 'tw-hidden'
        }`}
      >
        <span
          onClick={() => {
            actions.changeChatStatus(
              status === ChatStatus.OPEN ? ChatStatus.CLOSED : ChatStatus.OPEN
            );
            setIsOpen(false);
          }}
          className='tw-text-gray-800 tw-p-1  tw-px-2 tw-rounded-md tw-cursor-pointer hover:tw-bg-gray-100 '
        >
          Mark As {status === ChatStatus.OPEN ? 'Closed' : 'Open'}
        </span>
      </div>
    </div>
  );
};
