import { Navigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import usePagination from '../../customHooks/usePagination.hook';
import {
  useGetSessionsQuery,
  useCountSessionsQuery
} from '../../store/session.store';
import { Session } from '../../entities/session.entity';
import SessionFilterForm from '../../components/session/sessionsFilterForm';
import SessionsTable from '../../components/session/sessionsTable';
const Sessions = () => {
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isSuccess,
    isError
  } = usePagination<Session>({
    useGetDataQuery: useGetSessionsQuery,
    useCountData: useCountSessionsQuery
  });
  return (
    <div className='page_container'>
      <PageHeader text='sessions' />
      <div className='mb-3'>
        <SessionFilterForm
          setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
        />
      </div>
      {isSuccess ? (
        <>
          <SessionsTable sessions={data} />
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            next={next}
            prev={prev}
          />
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Sessions;
