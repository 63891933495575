import { ReactNode } from 'react';
import { Colors, tailwindVars } from '../../../utils/tailwind.util';

type Props = {
  isDot?: boolean;
  className?: string;
  color: Colors;
  children: ReactNode;
};

export const Badge = ({ children, isDot, color, className }: Props) => {
  return (
    <div
      className={`tw-py-1 tw-px-2  tw-text-xs   tw-font-medium  ${tailwindVars.getBgColor200(
        color
      )} ${className}`}
    >
      {isDot && (
        <span
          className={` tw-size-[7px] me-1 tw-rounded-full  ${tailwindVars.getBgColor400(
            color
          )}`}
        ></span>
      )}
      <span className={`   ${tailwindVars.getTextColor800(color)}`}>
        {children}
      </span>
    </div>
  );
};
