import { WithdrawalRequest } from "../entities/withdrawalRequest.entity";
import { Tutor } from "../entities/tutor.entity";
import { tutorRepository } from "../repositories/tutor.repository";
import { GenericQueryReturnType, GenericUpdateReturnType } from "../store";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";
import { withdrawalRequestRepository } from "../repositories/withdrawalRequest.repository";
import { TutorTransactionStatus } from "../entities/transactionHistory.entity";
import { tutorFinancalDetailsRepository } from "../repositories/tutorFinancalDetails.repository";
import { TutorFinancalDetails } from "../entities/tutorFinancalDetails.entity";

export const findWithdrawalRequests = async (
	where?: any,
	offset?: string
): Promise<
	GenericQueryReturnType<"withdrawalRequests", WithdrawalRequest[]>
> => {
	try {
		const withdrawalRequests = await withdrawalRequestRepository.findAll(
			where,
			offset,
			["date", "desc"]
		);

		const WithdrawalRequests = await Promise.all(
			withdrawalRequests.map(async (request) => {
				const tutor = (await tutorRepository.findById(
					request.userId
				)) as Tutor;
				const financialDetails =
					(await tutorFinancalDetailsRepository.findById(
						request.userId
					)) as TutorFinancalDetails;
				return {
					...request,
					tutor,
					tutorFinancalDetails: financialDetails,
				};
			})
		);
		return {
			data: {
				withdrawalRequests: WithdrawalRequests,
			},
		};
	} catch (error) {
		return {
			error: true,
		};
	}
};

export const countWithdrawalRequests = async (): Promise<
	GenericQueryReturnType<"count", number>
> => {
	try {
		const count = await withdrawalRequestRepository.count();

		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};

export const updateWithdrawalRequestById = async (
	id: string,
	newData: Partial<WithdrawalRequest>
): Promise<GenericUpdateReturnType> => {
	try {
		await withdrawalRequestRepository.update(id, newData);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const findWithdrawalRequestInvoicePdf = async (
	id: string
): Promise<GenericQueryReturnType<"invoice", string>> => {
	try {
		const pdfLink = process.env.REACT_APP_STORAGE_BUKET + id + ".pdf";
		const file = await getDownloadURL(ref(storage, pdfLink));

		return {
			data: {
				invoice: file,
			},
		};
	} catch (_) {
		return {
			error: true,
		};
	}
};
