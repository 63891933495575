
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FindForMeNowRequest, FindForMeNowRequestBy } from "../../entities/findForMeNow.entity";
import { displayDate } from "../../utils/dateHandler.util";

const VoucherCodesTable = ({ requests } : { requests: FindForMeNowRequest[] }) => {
    return (
        <Table striped bordered hover responsive>
            <thead className="text-light">
                <tr className="text-center">
                    <th>student</th>
                    <th>tutor</th>
                    <th>status</th>
                    <th>createdBy</th>
                    <th>createdAt</th>
                </tr>
            </thead>
            <tbody>
                {requests.map(request => (
                    <tr className="text-center" key={request.id}>
                        <td data-title="student" className="fs-6">
                            {request.student ? <Link to={"/students/" + request.student?.id } className="text-decoration-none">{request.student?.name}</Link> : "..."}
                        </td>
                        <td data-title="tutor" className="fs-6">
                            {request.tutor ? <Link to={"/tutors/" + request.tutor?.id } className="text-decoration-none">{request.tutor?.name}</Link> : "..."}
                        </td>
                        <td data-title="status" className="fs-6">
                            {request.status.toLowerCase()}
                        </td>
                        <td data-title="createdBy" className="fs-6">
                            { request.createdBy == FindForMeNowRequestBy.STUDENT  ? "student" : "tutor"}
                        </td>
                        <td data-title="createdBy" className="fs-6">
                            { displayDate(request.createdAt) }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default VoucherCodesTable;