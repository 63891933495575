import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import {
  useCountWithdrawalReqestsQuery,
  useGetWithdrawalRequestsQuery
} from '../../store/tutorWithdrawalRequest.store';
import { Navigate } from 'react-router-dom';
import TutorsWithdrawalRequestsTable from '../../components/withdrawalRequests/withdrawalRequestsTable';
import usePagination from '../../customHooks/usePagination.hook';
import Pagination from '../../components/pagination';
import { WithdrawalRequest } from '../../entities/withdrawalRequest.entity';

const WithdrawalRequests = () => {
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError
  } = usePagination<WithdrawalRequest>({
    useGetDataQuery: useGetWithdrawalRequestsQuery,
    useCountData: useCountWithdrawalReqestsQuery
  });
  return isSuccess ? (
    <div className='page_container'>
      <PageHeader text='Withdrawal Requests' />
      {isSuccess ? (
        <>
          <TutorsWithdrawalRequestsTable withdrawalRequests={data} />
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            prev={prev}
            next={next}
          />
        </>
      ) : null}
    </div>
  ) : isError ? (
    <Navigate to='/505' />
  ) : (
    <PageLoading />
  );
};

export default WithdrawalRequests;
