import { Form } from "react-bootstrap";
import { SlotStatus } from "../../entities/availableSlot.entity";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchByDateForm from "../SearchByDateForm";

// booking filter will be false or undefined because old data was not contains this field
type AvailableSlotsFilter = {
    status?: SlotStatus,
    tutorId?: string,
    date?: Date[]
}

const AvailableSlotFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: AvailableSlotsFilter) => void }) => {
    const [searchParams] = useSearchParams();

    const [filter, setFilter] = useState<AvailableSlotsFilter>({ tutorId: searchParams.get('tutorId') || undefined });

    const handleStatusChange = (status: SlotStatus | "all") => {
        setFilterQuery({ ...filter, status: status === "all" ? undefined : status });
        setFilter({ ...filter, status: status === "all" ? undefined : status });
    }

    const handleSearchByDateChange = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
        setFilterQuery({...filter, date: [startDate, endDate] });
        setFilter({...filter, date: [startDate, endDate] });
    }

    return (
        <>
            <Form.Select 
                defaultValue="all"
                onChange={(e) => handleStatusChange(e.target.value as SlotStatus | "all")}
            >
                <option value="all">All slots</option>
                <option value={SlotStatus.AVAILABLE}>{SlotStatus.AVAILABLE.toLowerCase()}</option>
                <option value={SlotStatus.BOOKED}>{SlotStatus.BOOKED.toLowerCase()}</option>
                <option value={SlotStatus.CONFIRMED}>{SlotStatus.CONFIRMED.toLowerCase()}</option>
                <option value={SlotStatus.REQUESTED}>{SlotStatus.REQUESTED.toLowerCase()}</option>
            </Form.Select>
            <div className="mt-3">
                <SearchByDateForm updateQuery={handleSearchByDateChange}/>
            </div>
        </>
    )
}

export default AvailableSlotFilterForm;