import { ChatNavHeader } from '../molecules/chat-nav-header';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { ChatNavNewChats } from '../molecules/chat-nav-newChats';
import { ChatsList } from './ChatsList';

export const ChatNav = () => {
  const {
    states: {
      filter: { filter },

      chats: { isfetching }
    }
  } = useChatSupport();

  return (
    <>
      <ChatNavHeader />
      {/* fetching new chat for realtime experince */}
      {!isfetching &&
        filter.status !== 'Closed' &&
        filter.orderBy &&
        filter.orderBy[0] === 'createdAt' &&
        filter.orderBy[1] === 'desc' && <ChatNavNewChats />}
      {!isfetching && <ChatsList />}
    </>
  );
};
