import {  Alert, Table } from "react-bootstrap";
import { WithdrawalRequest, WithdrawalRequestStatus } from "../../entities/withdrawalRequest.entity";
import AcceptWithdrawalRequest from "./acceptWithdrawalRequest";
import { Link } from "react-router-dom";
import { displayDate } from "../../utils/dateHandler.util";

const TutorsWithdrawalRequestsTable = ({ withdrawalRequests } : { withdrawalRequests: WithdrawalRequest[] }) => {
    return (
        <>
            <Table striped bordered hover responsive>
                <thead className="text-light">
                    <tr className="text-center">
                        <th>amount</th>
                        <th>status</th>
                        <th>date</th>
                        <th>tutor name</th>
                        <th>tutor email</th>
                        <th>approve</th>
                        <th>invoice</th>
                    </tr>
                </thead>
                <tbody>
                    {withdrawalRequests.map((withdrawalRequest, index) => (
                        <tr key={index} className="text-center">
                            <td>{withdrawalRequest.amount}</td>
                            <td>{withdrawalRequest.status}</td>
                            <td>{withdrawalRequest.date ? displayDate(withdrawalRequest.date) : ""}</td>
                            <td><Link to={`/tutors/${withdrawalRequest.tutor?.id}`} className="text-decoration-none">{withdrawalRequest.tutor?.name}</Link></td>
                            <td>{withdrawalRequest.tutor?.email}</td>
                            <td>{
                                withdrawalRequest.status == WithdrawalRequestStatus.PENDING ? 
                                    <AcceptWithdrawalRequest withdrawalRequest={withdrawalRequest}/> 
                                : "..."
                            }</td>
                            <td>
                                {
                                    (withdrawalRequest.status == WithdrawalRequestStatus.APPROVED ||
                                    withdrawalRequest.status == WithdrawalRequestStatus.APPROVED_ON_STAGING ) && 
                                    (withdrawalRequest.tutorFinancalDetails && !withdrawalRequest.tutorFinancalDetails.kvk_number && !withdrawalRequest.tutorFinancalDetails.BTW)  ?
                                         <Link to={`/withdrawal-requests/${withdrawalRequest.id}/invoice`} className="text-decoration-none">invoice</Link>
                                    : "..."
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            { withdrawalRequests.length===0 ? <Alert variant="info">no withdrawal requests</Alert> : null}
        </>
    )
}

export default TutorsWithdrawalRequestsTable;