import { OrderByDirection } from "@firebase/firestore-types";
import {
	CollectionReference,
	query,
	where,
	Query,
	limit,
	DocumentData,
	startAfter,
	getDoc,
	doc,
	endBefore,
	limitToLast,
	orderBy,
	or,
	WhereFilterOp,
	and,
} from "firebase/firestore";
import { RepoistoryFilter } from "../../types/util.types";

export class FilterQueryBuilder {
	private filters: any[] = [];

	constructor(private collection: CollectionReference) {}

	whereFieldEquals(field: string, value: any): FilterQueryBuilder {
		this.filters.push(where(field, "==", value));
		return this;
	}
	limit(num: number): FilterQueryBuilder {
		this.filters.push(limit(num));
		return this;
	}
	afterAt(doc: DocumentData): FilterQueryBuilder {
		this.filters.push(startAfter(doc));
		return this;
	}
	beforeAt(doc: DocumentData): FilterQueryBuilder {
		this.filters.push(endBefore(doc), limitToLast(10));
		return this;
	}
	orderBy(orderByParam: string | [string, OrderByDirection]) {
		const [field, direction = "asc"] =
			typeof orderByParam === "string" ? [orderByParam] : orderByParam;

		this.filters.push(orderBy(field, direction));
		return this;
	}
	or(condations: any[]) {
		this.filters.push(or(...condations));
		return this;
	}
	and(...conditions: any[]): FilterQueryBuilder {
		this.filters.push(and(...conditions));
		return this;
	}
	build(): Query {
		return query(this.collection, ...this.filters);
	}
	greaterThan(field: string, value: any): FilterQueryBuilder {
		this.filters.push(where(field, ">", value));
		return this;
	}
	where(condition: any) {
		this.filters.push(condition);
		return this;
	}
}
export const buildFilterQuery = async (
	collection: CollectionReference,
	fields?: any,
	limit?: number,
	offset?: string,
	orderBy?: string | [string, OrderByDirection],
	condations?: any[]
): Promise<Query> => {
	const newFilterQuery = new FilterQueryBuilder(collection);
	condations?.forEach((condation) => {
		newFilterQuery.where(condation);
	});
	if (fields)
		Object.entries(fields).forEach(([key, value]) => {
			if (key === "or") {
				const orQuery: any[] = [];
				Object.entries(value as any).forEach(
					([orItemKey, orItemValue]) => {
						if (orItemValue)
							orQuery.push(where(orItemKey, "==", orItemValue));
					}
				);
				if (orQuery.length === 1) newFilterQuery.where(orQuery[0]);
				if (orQuery.length > 1) newFilterQuery.or(orQuery);

				return;
			}
			if (value !== undefined)
				newFilterQuery.whereFieldEquals(key, value);
		});

	if (orderBy) newFilterQuery.orderBy(orderBy);

	if (limit) newFilterQuery.limit(limit);

	if (offset) {
		const document = await getDoc(doc(collection, offset));
		if (document.exists()) {
			newFilterQuery.afterAt(document);
		}
	}
	return newFilterQuery.build();
};

export const buildFilterQuery_v2 = <T extends {}>(
	collection: CollectionReference,
	whereFields?: RepoistoryFilter<T>["where"],
	limit?: number,
	docSnapshot?: RepoistoryFilter["docSnapshot"],
	orderBy?: RepoistoryFilter<T>["orderBy"]
): Query<T> => {
	const newFilterQuery = new FilterQueryBuilder(collection);
	if (whereFields?.length) {
		whereFields.forEach((whereClause) => {
			const condition = where(
				...(whereClause as [string, WhereFilterOp, any])
			);
			newFilterQuery.where(condition);
		});
	}

	if (orderBy) {
		const [field, direction = "asc"] =
			typeof orderBy === "string" ? [orderBy] : orderBy;
		newFilterQuery.orderBy([field as string, direction]);
	}

	if (limit) newFilterQuery.limit(limit);

	if (docSnapshot) {
		newFilterQuery.afterAt(docSnapshot);
	}
	return newFilterQuery.build() as Query<T>;
};
