import { FilterQueryBuilder } from "../firebase/firestore/filterQueryBuilder";
import { VoucherCodeStatus } from "../entities/voucherCode.entity";
import { Repository } from "../firebase/firestore/repository";
import { VoucherCode } from "../entities/voucherCode.entity";
import {
	DocumentReference,
	doc,
	increment,
	writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { fireStoreCollectionName } from "../utils/firebase.util";
import { Organization } from "../entities/organization.entity";

export class VoucherCodeRepository extends Repository<VoucherCode> {
	constructor() {
		super("creditVouchers");
	}
	async deleteVoucherVode(voucherCode: VoucherCode) {
		const batch = writeBatch(db);
		const docRefrence = doc(db, this.collectionName, voucherCode.id);
		batch.delete(docRefrence);
		if (voucherCode.organizationId) {
			const organizationRef = doc(
				db,
				fireStoreCollectionName.organizations,
				voucherCode.organizationId
			) as DocumentReference<Organization, Organization>;
			batch.update(organizationRef, {
				vouchersCodesCount: increment(-1),
			});
		}
		await batch.commit();
	}

	async findUnExpiredVouchersCodes(): Promise<{ code: string }[]> {
		const query = new FilterQueryBuilder(this.collection)
			.greaterThan("expiryDate", new Date())
			.whereFieldEquals("status", VoucherCodeStatus.AVAILABLE)
			.build();

		const unExpiredVouchersCodes = await this.findBy(query);
		return unExpiredVouchersCodes.map((voucherCode) => ({
			code: voucherCode.code,
		}));
	}
}
export const voucherCodeRepository = new VoucherCodeRepository();
