import { Navigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import {
  useCountTutorsQuery,
  useGetTutorsQuery
} from '../../store/tutor.store';
import usePagination from '../../customHooks/usePagination.hook';
import { Tutor } from '../../entities/tutor.entity';
import Pagination from '../../components/pagination';
import ApplyingTutorsTable from '../../components/applyingTutors/applyingTutorsTable';
const ApplyingTutors = () => {
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    isLoading,
    isSuccess,
    isError
  } = usePagination<Tutor>({
    useGetDataQuery: useGetTutorsQuery,
    useCountData: useCountTutorsQuery,
    initQuery: { isAccepted: false }
  });

  return (
    <div className='page_container'>
      <PageHeader text='applyingTutors' />
      {isSuccess ? (
        <>
          <ApplyingTutorsTable tutors={data} />
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            prev={prev}
            next={next}
          />
        </>
      ) : isError ? (
        <Navigate to='505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default ApplyingTutors;
