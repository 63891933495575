import { GoSortDesc, GoSortAsc } from 'react-icons/go';
import { OrderByDirection } from 'firebase/firestore';

type Props = {
  sort: OrderByDirection;
  onClick: (newSort: OrderByDirection) => void;
  className?: string;
};

export const SortIconBtn = ({ sort, onClick, className }: Props) => {
  const Component = sort === 'desc' ? GoSortDesc : GoSortAsc;

  return (
    <Component
      onClick={() => onClick(sort === 'desc' ? 'asc' : 'desc')}
      className={className}
    />
  );
};
