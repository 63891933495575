import { FormEvent, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

type SearchDate = {
  startDate: string;
  endDate: string;
};

const SearchByDateForm = ({
  updateQuery
}: {
  updateQuery: (searchDate: any) => void;
}) => {
  const [searchDate, setSearchDate] = useState<SearchDate>({
    startDate: '',
    endDate: ''
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchDate.startDate && searchDate.endDate)
      updateQuery({
        startDate: new Date(searchDate.startDate + 'T00:00:00'),
        endDate: new Date(searchDate.endDate + 'T00:00:00')
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className='align-items-center'>
        <Col xs={5}>
          <Form.Group>
            <Form.Label>From</Form.Label>
            <Form.Control
              className={
                searchDate.startDate &&
                searchDate.endDate &&
                new Date(searchDate.startDate) > new Date(searchDate.endDate)
                  ? 'border-danger'
                  : ''
              }
              type='date'
              placeholder='start date'
              onChange={(e) =>
                setSearchDate({ ...searchDate, startDate: e.target.value })
              }
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group>
            <Form.Label>To</Form.Label>
            <Form.Control
              className={
                searchDate.startDate &&
                searchDate.endDate &&
                new Date(searchDate.startDate) > new Date(searchDate.endDate)
                  ? 'border-danger'
                  : ''
              }
              type='date'
              onChange={(e) =>
                setSearchDate({ ...searchDate, endDate: e.target.value })
              }
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Button
            variant='primary'
            type='submit'
            className='mt-4'
            disabled={!searchDate.endDate || !searchDate.startDate}
          >
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchByDateForm;
