import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TransactionHistoryType } from "../../entities/transactionHistory.entity";
import SearchByDateForm from "../SearchByDateForm";

// booking filter will be false or undefined because old data was not contains this field
type StudentTransactionFilter = {
    type?: TransactionHistoryType,
    studentId?: string,
    date?: Date[]
}

const StudentsTransactionsFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: StudentTransactionFilter) => void}) => {
    const [searchParams] = useSearchParams();

    const [filter, setFilter] = useState<StudentTransactionFilter>({
        studentId: searchParams.get('studentId') || undefined
    });

    const handleTypeChange = (Type: TransactionHistoryType | "all") => {
        setFilterQuery({ ...filter, type: Type === "all" ? undefined : Type });
        setFilter({ ...filter, type: Type === "all" ? undefined : Type });
    }
    const handleSearchByDateChange = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
        setFilterQuery({...filter, date: [startDate, endDate] });
        setFilter({...filter, date: [startDate, endDate] });
    }
    return (
        <>
            <Form.Select 
                defaultValue="all"
                onChange={(e) => handleTypeChange(e.target.value as TransactionHistoryType | "all")}
            >
                <option value="all">All transactions</option>
                <option value={TransactionHistoryType.CREDIT}>{TransactionHistoryType.CREDIT.toLowerCase()}</option>
                <option value={TransactionHistoryType.DEBIT}>{TransactionHistoryType.DEBIT.toLowerCase()}</option>
                <option value={TransactionHistoryType.REFUND}>{TransactionHistoryType.REFUND.toLowerCase()}</option>
                <option value={TransactionHistoryType.RESERVED}>{TransactionHistoryType.RESERVED.toLowerCase()}</option>
            </Form.Select>
            <div className="mt-3">
                <SearchByDateForm updateQuery={handleSearchByDateChange}/>
            </div>
        </>
    )
}

export default StudentsTransactionsFilterForm;