import { baseService } from "./base.store";
import {
	findBookings,
	completeUnCompletedBookingsAdministration,
	countBookings,
} from "../services/booking.service";
import { Booking } from "../entities/booking.entity";

export const bookingService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getBookings: builder.query<
			{ bookings: Booking[] },
			{ where?: any; offset?: string }
		>({
			queryFn: async ({ where = {}, offset }) =>
				await findBookings(where, offset),
			providesTags: ["Booking"],
		}),
		countBookings: builder.query<{ count: number }, { where?: any }>({
			queryFn: async ({ where }) => await countBookings(where),
		}),
		completeUnCompletedBookingsAdministration: builder.mutation<void, void>(
			{
				queryFn: async () =>
					await completeUnCompletedBookingsAdministration(),
				invalidatesTags: ["Booking"],
			}
		),
	}),
});

export const {
	useGetBookingsQuery,
	useCountBookingsQuery,
	useCompleteUnCompletedBookingsAdministrationMutation,
} = bookingService;
