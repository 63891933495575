import { Alert, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  CancellationRequest,
  CancellationRequestBy,
  CancellationRequestStatus
} from '../../entities/cancellationRequest.entity';
import { ApproveCancellationRequestParams } from '../../repositories/cancellationRequest.repository';
import { useApproveCancellationRequestsMutation } from '../../store/cancellationRequest.store';
import { displayDate } from '../../utils/dateHandler.util';

const CancellationRequestsTable = ({
  cancellationRequests
}: {
  cancellationRequests: CancellationRequest[];
}) => {
  const [approveCancellationRequest, _] =
    useApproveCancellationRequestsMutation();

  const handleApprove = ({
    cancellationRequestId,
    bookingId
  }: ApproveCancellationRequestParams) => {
    approveCancellationRequest({
      cancellationRequests: [{ cancellationRequestId, bookingId }]
    });
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead className='text-light'>
          <tr className='text-center'>
            <th>cancellation date</th>
            <th>status</th>
            <th>cancelled by</th>
            <th>approve</th>
            <th>show</th>
          </tr>
        </thead>
        <tbody>
          {cancellationRequests.map((cancellationRequest, index) => (
            <tr key={index} className='text-center'>
              <td>{displayDate(cancellationRequest.cancellationDate)}</td>
              <td>{cancellationRequest.status?.toLocaleLowerCase()}</td>
              <td>
                {cancellationRequest.cancelledBy ==
                CancellationRequestBy.STUDENT
                  ? 'STUDENT'
                  : 'TUTOR'}
              </td>
              <td>
                {cancellationRequest.status ==
                CancellationRequestStatus.PendingCancellationApproval ? (
                  <Button
                    variant='primary'
                    onClick={() =>
                      handleApprove({
                        cancellationRequestId: cancellationRequest.id,
                        bookingId: cancellationRequest.bookingId
                      })
                    }
                  >
                    approve
                  </Button>
                ) : (
                  '...'
                )}
              </td>
              <td>
                <Link
                  to={`/cancellation-requests/${cancellationRequest.id}`}
                  className='text-decoration-none'
                >
                  show
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {cancellationRequests.length === 0 ? (
        <Alert variant='info'>no cancellation requests</Alert>
      ) : null}
    </>
  );
};

export default CancellationRequestsTable;
