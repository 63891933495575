import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useIntersectionObserver } from '../../../customHooks/useIntersectionOpserver';
import { Spinner } from '../../shared/atoms/spinner';
import { ChatSummaryBox } from '../molecules/chat-summary-box';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const ChatsList = () => {
  const [isNewFetch, setIsNewFetching] = useState(false);
  const {
    states: {
      chats: { chats },
      filter: { filter }
    },
    actions
  } = useChatSupport();
  const isMoreToFetch = useMemo(() => {
    const limitToFetch = filter.limit as number;
    // check on inital render only
    if (limitToFetch > chats.length) return false;
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMoreTofetchRef = useRef(isMoreToFetch);

  const { ref, isIntersecting } = useIntersectionObserver({});

  const handleScrollPagination = useCallback(async () => {
    setIsNewFetching(true);
    const isMoreTofetch = await actions.scrollFetchChats.call(actions);
    isMoreTofetchRef.current = isMoreTofetch;
    setIsNewFetching(false);
  }, [actions]);
  useEffect(() => {
    if (isIntersecting && isMoreTofetchRef.current) handleScrollPagination();
  }, [isIntersecting, handleScrollPagination]);
  return (
    <div className='tw-divide-y-4 tw-flex tw-flex-col tw-overflow-y-auto  tw-flex-1 '>
      {chats.map((chat, i) => (
        <ChatSummaryBox key={chat.id} summary={chat} />
      ))}
      {isMoreTofetchRef.current && (
        <div
          ref={ref}
          className={`flex_center ${
            isNewFetch ? 'tw-opacity-100' : 'tw-opacity-0'
          } `}
        >
          <Spinner className=' tw-size-10' />
        </div>
      )}
    </div>
  );
};
