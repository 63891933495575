import { MessageType } from '../../../entities/chat-support.entity';
import {
  ActiveChatMessage,
  ChatSummary,
  PendingMessage
} from '../../../types/chat-support/chat-support.type';
import { dateToHH_MM } from '../../../utils/date.util';
import { ActiveChatImage } from './active-chat-img';

type Props = {
  className?: string;
  user?: ChatSummary['user'];
  message: PendingMessage | ActiveChatMessage;
};

export const ActiveChatMsg = ({ className, user, message }: Props) => {
  return (
    <div
      className={`tw-flex tw-min-w-[100px]  tw-max-w-[150px] md:tw-min-w-[180px] md:tw-max-w-[250px]  ${
        user ? 'ms-1' : 'ms-auto'
      }`}
    >
      {user && (
        <img
          className=' tw-size-[40px] tw-rounded-full  tw-me-2 '
          src={user.profileImage}
          alt='profile_Image'
        />
      )}
      <div className='tw-flex tw-flex-col tw-gap-2 tw-w-full'>
        <div className='tw-flex tw-items-center -tw-justify-between'>
          <span className='tw-text-sm tw-font-medium tw-text-gray-800'>
            {user ? user.name : 'you'}
          </span>

          <span className=' tw-text-xs tw-text-gray-500 tw-ms-auto'>
            {dateToHH_MM(
              (message as ActiveChatMessage).createdAt
                ? (message as ActiveChatMessage).createdAt.toDate()
                : new Date()
            )}
          </span>
        </div>

        {message.type === MessageType.IMAGE ? (
          <ActiveChatImage src={message.content} />
        ) : (
          <p
            className={`${
              user
                ? 'tw-bg-white tw-text-gray-700'
                : 'tw-bg-brand-light_blue tw-text-white'
            }  tw-p-2 tw-rounded-md`}
          >
            {message.content}
          </p>
        )}
      </div>
    </div>
  );
};
