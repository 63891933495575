import { baseService } from "./base.store";
import {
	findStudentTransactionHistoryById,
	findTutorTransactionHistoryById,
	findStudentsTransactionsHistory,
	findTutorsTransactionsHistory,
	countTutorsTransactions,
	countStudentsTransactions,
} from "../services/transactionHistory.service";
import {
	TutorTransactionHistory,
	StudentTransactionHistory,
} from "../entities/transactionHistory.entity";
import { countStudents } from "../services/student.service";

export const transactionHistoryService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getTutorsTransactions: builder.query<
			{ transactions: TutorTransactionHistory[] },
			{ where: any; offset?: string }
		>({
			queryFn: async ({ where, offset }) =>
				await findTutorsTransactionsHistory(where, offset),
		}),
		getStudentsTransactions: builder.query<
			{ transactions: StudentTransactionHistory[] },
			{ where: any; offset?: string }
		>({
			queryFn: async ({ where, offset }) =>
				await findStudentsTransactionsHistory(where, offset),
		}),
		getStudentTransactionHistroy: builder.query<
			{ transactions: StudentTransactionHistory[] },
			string
		>({
			queryFn: async (studentId) =>
				await findStudentTransactionHistoryById(studentId),
		}),
		getTutorTransaction: builder.query<
			{ transactions: TutorTransactionHistory[] },
			string
		>({
			queryFn: async (tutorId) =>
				await findTutorTransactionHistoryById(tutorId),
		}),
		countTutorsTransactions: builder.query<
			{ count: number },
			{ where?: any }
		>({
			queryFn: async ({ where }) => await countTutorsTransactions(where),
		}),
		countStudentsTransactions: builder.query<
			{ count: number },
			{ where?: any }
		>({
			queryFn: async ({ where }) =>
				await countStudentsTransactions(where),
		}),
	}),
});

export const {
	useGetStudentTransactionHistroyQuery,
	useGetTutorTransactionQuery,
	useGetStudentsTransactionsQuery,
	useGetTutorsTransactionsQuery,
	useCountTutorsTransactionsQuery,
	useCountStudentsTransactionsQuery,
} = transactionHistoryService;
