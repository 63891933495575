import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Tutor } from '../../entities/tutor.entity';

const TutorsTable = ({ tutors }: { tutors: Tutor[] }) => {
  return (
    <Table striped bordered hover responsive>
      <thead className='text-light'>
        <tr className='text-center'>
          <th>name</th>
          <th>email</th>
          <th>account type</th>
          <th>total credit</th>
          <th>available credit</th>
          <th>sessions</th>
          <th>isAccepted</th>
          <th>show</th>
        </tr>
      </thead>
      <tbody>
        {tutors.map((tutor) => (
          <tr className='text-center' key={tutor.id}>
            <td data-title='name' className='fs-6'>
              {tutor.name}
            </td>
            <td data-title='email' className='fs-6'>
              {tutor.email}
            </td>
            <td data-title='account type' className='fs-6'>
              {tutor.accountType}
            </td>
            <td data-title='total credit' className='fs-6'>
              {tutor.totalCredit}
            </td>
            <td data-title='available credit' className='fs-6'>
              {tutor.totalAvailableCredit}
            </td>
            <td data-title='sessions' className='fs-6'>
              {tutor.noOfSessions || 0}
            </td>
            <td data-title='isAccepted' className='fs-6'>
              {tutor.isAccepted ? 'yes' : 'no'}
            </td>
            <td data-title='show' className='fs-6'>
              <Link to={'/tutors/' + tutor.id} className='text-decoration-none'>
                show
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TutorsTable;
