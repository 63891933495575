import { useEffect, useState } from 'react';
import { storage } from '../../../firebase';
import { useGetInvoicePdfQuery } from '../../../store/tutorWithdrawalRequest.store';
import { Navigate, useParams } from 'react-router-dom';
import PageHeader from '../../../components/pageHeader';
import PageLoading from '../../../components/pageLoading';
const Invoice = () => {
  let { id = '' } = useParams();
  const { isError, isSuccess, data } = useGetInvoicePdfQuery(id, { skip: !id });
  return isSuccess ? (
    <div className='page_container'>
      <PageHeader text='Invoice' />
      <embed
        src={data.invoice}
        type='application/pdf'
        width='100%'
        height='600px'
      />
    </div>
  ) : isError ? (
    <Navigate to={`/withdrawal-requests/${id}`} />
  ) : (
    <PageLoading />
  );
};

export default Invoice;
