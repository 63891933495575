import { ChatSummary } from '../../types/chat-support/chat-support.type';

export const chatSummaryStateHelper = {
  isUpdateNeeded(oldState: ChatSummary, newChange: ChatSummary): boolean {
    if (newChange.status !== oldState.status) return true;
    const isCounterChange = (() => {
      if (newChange.unreadCount.support !== oldState.unreadCount.support)
        return true;
      if (newChange.unreadCount.user !== oldState.unreadCount.user) return true;
      return false;
    })();
    if (isCounterChange) return true;
    if (newChange.lastMessage.id !== oldState.lastMessage.id) return true;

    return false;
  }
};
