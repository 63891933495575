import { GenericQueryReturnType } from '../store';
import { findForMeNowRequestRepository } from '../repositories/findForMeNowRequest.repository';
import { tutorRepository } from '../repositories/tutor.repository';
import { studentRepository } from '../repositories/student.repository';
import { FindForMeNowRequest } from '../entities/findForMeNow.entity';

export const findFindForMeNowRequests = async (
  where: any,
  offset?: string
): Promise<GenericQueryReturnType<'requests', FindForMeNowRequest[]>> => {
  try {
    const requests = await findForMeNowRequestRepository.findAll(
      where,
      offset,
      ['createdAt', 'desc']
    );

    const requestsWithAttachements = await Promise.all(
      requests.map(async (request) => {
        const tutor = request.tutorId
          ? await tutorRepository.findById(request.tutorId)
          : undefined;
        const student = request.studentId
          ? await studentRepository.findById(request.studentId)
          : undefined;

        return {
          ...request,
          tutor: tutor || undefined,
          student: student || undefined
        };
      })
    );
    return {
      data: {
        requests: requestsWithAttachements
      }
    };
  } catch (error) {
    console.log(error);
    return {
      error: true
    };
  }
};

export const countFindForMeNowRequests = async (
  where?: any
): Promise<GenericQueryReturnType<'count', number>> => {
  try {
    const count = await findForMeNowRequestRepository.count(where);

    return {
      data: {
        count: count
      }
    };
  } catch (_) {
    return { error: true };
  }
};
