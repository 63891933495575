import { Col, Form, Row } from "react-bootstrap";
import { BookingStatus } from "../../entities/booking.entity";
import { useState } from "react";

// booking filter will be false or undefined because old data was not contains this field
type BookingsFilter = {
    bookingStatus?: BookingStatus,
    isCompletedAdministration?: false
}

const BookingsFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: BookingsFilter) => void}) => {
    const [filter, setFilter] = useState<BookingsFilter>({});

    const handleStatusChange = (status: BookingStatus | "all") => {
        setFilterQuery({ ...filter, bookingStatus: status === "all" ? undefined : status });
        setFilter({ ...filter, bookingStatus: status === "all" ? undefined : status });
    }
    
    const handleIsCompletedAdministrationChange = (value: boolean) => {
        setFilterQuery({ ...filter, isCompletedAdministration: value ? false : undefined });
        setFilter({ ...filter, isCompletedAdministration: value ? false : undefined });
    }
    return (
        <Row>
            <Col sm={3}>
                <Form.Check 
                    name="unCompletedAdministration"
                    type="checkbox"
                    label="un completed administration"
                    onChange={(e) => handleIsCompletedAdministrationChange(e.target.checked)}
                />
            </Col>
            <Col sm={9}>
                <Form.Select 
                    defaultValue="all"
                    onChange={(e) => handleStatusChange(e.target.value as BookingStatus | "all")}
                >
                    <option value="all">All Bookings</option>
                    <option value={BookingStatus.INITIATED}>{BookingStatus.INITIATED.toLowerCase()}</option>
                    <option value={BookingStatus.ACCEPTED}>{BookingStatus.ACCEPTED.toLowerCase()}</option>
                    <option value={BookingStatus.REJECTED}>{BookingStatus.REJECTED.toLowerCase()}</option>
                    <option value={BookingStatus.CANCELED}>{BookingStatus.CANCELED.toLowerCase()}</option>
                    <option value={BookingStatus.COMPLETED}>{BookingStatus.COMPLETED.toLowerCase()}</option>
                    <option value={BookingStatus.PENDING_CANCELLATION_APPROVAL}>Pending Cancellation Approval</option>
                </Form.Select>
            </Col>
        </Row>
    )
}

export default BookingsFilterForm;