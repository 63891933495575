import React, { useState } from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';

const CopyTextToClipBoard = ({ text }: { text: string}) => {
    const [isCopied, setCopied] = useState<boolean>();

    const handleCopy = () => {
        setCopied(true);
        navigator.clipboard.writeText(text)
        setTimeout(() => setCopied(false), 800);
    };

    return (
        <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Copied!</Tooltip>}
            trigger={"click"} 
            delay={{ show: 200, hide: 600 }}
            show={isCopied}
        >
            <Alert variant="info" onClick={handleCopy}>{text}</Alert>
        </OverlayTrigger>
    );
};

export default CopyTextToClipBoard;
