import { Col } from "react-bootstrap";
import { Student } from "../../../entities/student.entity";


const StudentInfo = ({ student }: { student: Student }) => {
    return (
        <div className="user-info">
            <div className="info-row">
                <label className="info-label">Name:</label>
                <span className="info-value">{student.name}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Email:</label>
                <span className="info-value">{student.email}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Total Points:</label>
                <span className="info-value">{student.points || 0 + (student.reservedPoints || 0)}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Reserved Points:</label>
                <span className="info-value">{student.reservedPoints || 0}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Country:</label>
                <span className="info-value">{student.country}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Native Language:</label>
                <span className="info-value">{student.nativeLanguage}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Dutch Level:</label>
                <span className="info-value">{student.dutchLevel}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Sessions:</label>
                <span className="info-value">{student.noOfSessions || 0}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Gender:</label>
                <span className="info-value">{student.gender}</span>
            </div>
            <div className="info-row">
                <label className="info-label">Privacy:</label>
                <span className="info-value">{student.isPrivate ? "private" : "public"}</span>
            </div>
            { student.organization ? <div className="info-row">
                <label className="info-label">Organization:</label>
                <span className="info-value">{student.organization.name}</span>
            </div> : null}

        </div>
    )
}

{/* <Col>
            <p>Name: {student.name}</p>
            <p>Email: {student.email}</p>
            <p>points: {student.points }</p>
            <p>Country: {student.country}</p>
            <p>Dutch Level: {student.dutchLevel}</p>
            <p>Gender: {student.gender}</p>
            <p>Number of sessions: {student.noOfSessions}</p>
            { student.organization ? <p>Organization: {student.organization?.name}</p> : null}
            <p>Reserved points: {student.reservedPoints || 0}</p>
            <p>Privacy: {student.isPrivate ? "private" : "public"}</p>
        </Col> */}
export default StudentInfo;