import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FC } from 'react';
import CreateVoucherCodeForm from './CreateStudentVoucherCode';
import { Tab, Tabs } from 'react-bootstrap';
import CreateOrganizationVouchersCodes from './createOrganizationVoucherCode/CreateOrganizationVoucherCode';

interface CreateVoucherCodeProps {
  onHide: () => void;
  [key: string]: any;
}

const CreateVoucherCodeModel: FC<CreateVoucherCodeProps> = ({ onHide, ...props }) => {
  const [displayOrganizationVoucherCodeForm, setDisplayOrganizationVoucherCodeForm] = useState<boolean>();
  
  return (
    <Modal
      {...props}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Tabs
          defaultActiveKey={displayOrganizationVoucherCodeForm ? "organization": "student"}
          id="uncontrolled-tab-example"
          fill
          onSelect={(eventKey) => setDisplayOrganizationVoucherCodeForm(eventKey==="organization")}
        >
          <Tab eventKey="student" title="student"></Tab>
          <Tab eventKey="organization" title="organization">
          </Tab>
        </Tabs>
      </Modal.Header>
      <Modal.Body className='mt-1'>
        {
          displayOrganizationVoucherCodeForm ? <CreateOrganizationVouchersCodes setHide={onHide} /> : <CreateVoucherCodeForm setHide={onHide} />
        }
        
      </Modal.Body>
    </Modal>
  );
};

const CreateVoucherCode = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
            add new voucher code
      </Button>

      <CreateVoucherCodeModel
          show={modalShow}
          onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default CreateVoucherCode;