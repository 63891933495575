import { useState } from 'react';
import { PendingMessage } from '../../../types/chat-support/chat-support.type';
import { ActiveChatMsg } from '../atoms/active-chat-msg';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import { IoMdAlert } from 'react-icons/io';
import { Spinner } from '../../shared/atoms/spinner';
import { useEffectOnce } from '../../../customHooks/useEffect-once';
import { Timestamp } from 'firebase/firestore';
import { scrollToBottom } from '../../../utils/util';
import { ChatMessage } from '../../../entities/chat-support.entity';
import { getCookieWithKey } from '../../../utils/browserStorage.util';

type Props = {
  message: PendingMessage;
};
export const ActiveChatPendingMessage = ({ message }: Props) => {
  const [isErrorSaving, setIsErrorSaving] = useState(false);
  const [uploadProgress, setUploadProgress] = useState('');
  const {
    actions,
    states: {
      activeChatRoom: { setActiveChatRoom }
    }
  } = useChatSupport();
  const {
    states: {
      pending: { setPendingMsgs },
      messages: { msgsContainerRef }
    }
  } = useChatSupportActiveMessages();
  const sendMessage = async () => {
    try {
      isErrorSaving && setIsErrorSaving(false);
      const isImg = !!message.file;
      await actions.sendMessage(message, isImg ? setUploadProgress : undefined);
      setPendingMsgs((old) =>
        old.filter((old) => old.tempId !== message.tempId)
      );

      setTimeout(() => scrollToBottom(msgsContainerRef), 200);
    } catch (error) {
      setIsErrorSaving(true);
      setUploadProgress('');
    }
  };

  useEffectOnce(() => {
    sendMessage();
  });
  return (
    <div className=' tw-flex  tw-gap-2 tw-ms-auto tw-items-center '>
      {uploadProgress && (
        <Spinner className=' tw-size-12'> {uploadProgress}%</Spinner>
      )}
      <ActiveChatMsg message={message} />
      <IoMdAlert
        className={`tw-text-red-500 tw-text-xl tw-cursor-pointer tw-transition-opacity ${
          isErrorSaving ? ' tw-opacity-1' : 'tw-opacity-0'
        }`}
        onClick={sendMessage}
      />
    </div>
  );
};
