import { Repository } from '../firebase/firestore/repository';
import { AvailableSlot } from '../entities/availableSlot.entity';
import { buildFilterQuery } from '../firebase/firestore/filterQueryBuilder';
import {
  getCountFromServer,
  getDocs,
  OrderByDirection,
  where as whereCondation
} from 'firebase/firestore';
export class AvailableSlotRepository extends Repository<AvailableSlot> {
  constructor() {
    super('tutorAvailabilitySlots');
  }
  async findAll(
    where?: any,
    offset?: string,
    orderBy?: string | [string, OrderByDirection] | undefined,
    limit?: number
  ): Promise<AvailableSlot[]> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      limit || 10,
      offset,
      orderBy,
      this.builderFilteCondations(where)
    );
    const snapshot = await getDocs(query);
    return snapshot.docs.map((doc) => this.map(doc));
  }
  async count(where?: any): Promise<number> {
    const query = await buildFilterQuery(
      this.collection,
      {},
      undefined,
      undefined,
      ['date', 'desc'],
      this.builderFilteCondations(where)
    );
    const countResult = await getCountFromServer(query);
    return countResult.data().count;
  }
  private builderFilteCondations(where: any): any[] {
    const condations = [];
    if (where.tutorId)
      condations.push(whereCondation('tutorId', '==', where.tutorId));
    if (where.status)
      condations.push(whereCondation('status', '==', where.status));
    if (where.date) {
      condations.push(whereCondation('date', '>=', where.date[0]));
      condations.push(whereCondation('date', '<=', where.date[1]));
    }
    return condations;
  }
}
export const availableSlotRepository = new AvailableSlotRepository();
