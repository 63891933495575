import { Navigate } from 'react-router-dom';
import AvailableSlotsFilterForm from '../../components/availableSlots/availableSlotsFilterForm';
import AvailableSlotsTable from '../../components/availableSlots/availableSlotsTable';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import Pagination from '../../components/pagination';
import usePagination from '../../customHooks/usePagination.hook';
import { useGetAvailableSlotsQuery, useCountAvailableSlotsQuery } from '../../store/availableSlot.store';
import { AvailableSlot } from '../../entities/availableSlot.entity';
import { useSearchParams } from "react-router-dom"
const AvailableSlots = () => {
  const [searchParams] = useSearchParams();

  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isSuccess,
    isError,
  } = usePagination<AvailableSlot>({
    useGetDataQuery: useGetAvailableSlotsQuery,
    useCountData: useCountAvailableSlotsQuery,
    initQuery: { tutorId: searchParams.get("tutorId") || undefined }
  });

  return (
    <div className='page_container'>
      <PageHeader text='available slots' />
      <div className='mb-3'>
        <AvailableSlotsFilterForm
          setFilterQuery={(newFilterQuery: any) => updateQuery(newFilterQuery)}
        />
      </div>
      {isSuccess ? (
        <>
          <AvailableSlotsTable slots={data} />
          <Pagination
            currentPage={currentPage}
            numberOfPages={numberOfPages}
            next={next}
            prev={prev}
          />
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default AvailableSlots;
