import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  isOpen: boolean,
  deps: any[] = [] // if close callback depends on state you must pass it here
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handler(event);
      }
    },
    [handler, ref]
  );

  useEffect(() => {
    if (isOpen) {
      document['addEventListener']('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [ref, ...deps, isOpen, handleClickOutside]);
};
