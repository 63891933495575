import { Button } from 'react-bootstrap';
import { IoMdPrint } from 'react-icons/io';

type Props = {
  handlePrint(): void;
  disabled?: boolean;
};
export const PrintButton = ({ handlePrint, disabled = false }: Props) => {
  return (
    <Button
      disabled={disabled}
      variant='success'
      onClick={handlePrint}
      className='tw-text-nowrap '
    >
      Print
      <IoMdPrint className='tw-mx-1 tw-text-xl' />
    </Button>
  );
};
