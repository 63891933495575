import { forwardRef, useEffect, useState } from "react";
import { Tutor } from "../../../../entities/tutor.entity";
import SearchForm from "../../../SearchForm";
import { useGetTutorsQuery } from "../../../../store/tutor.store";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import StudentTutorsTableRows from "./studentTutorsTable";
import { useAddTutorsToPrivateStudentMutation, useRemoveTutorFromPrivateStudentMutation } from "../../../../store/student.store";
type Props = {
    studentTutors: Tutor[];
    studentId: string;
}

const TutorsSearch =({ studentTutors, studentId }: Props) => {
    const [selectedTutors, setSelectedTutors] = useState<Tutor[]>([]);
    const [filter, setFilter] = useState<Partial<Tutor>>({});
    const { data: searchResultData, isSuccess } = useGetTutorsQuery({ where: filter }, { skip: !filter.email || !filter.name });
    const [removeTutor, removeTutorResult] = useRemoveTutorFromPrivateStudentMutation()
    const [addTutorsToPrivateStudent, addTutorsToPrivateStudentResult] = useAddTutorsToPrivateStudentMutation()
    const toggleSelectTutor = (tutor: Tutor) => {
        const tutorIsSelected = selectedTutors.findIndex(selectedTutor => selectedTutor.id == tutor.id );
        const newSelectedTutors = tutorIsSelected !== -1? 
            selectedTutors.filter(selectedTutor => selectedTutor.id !== tutor.id)
            : [tutor, ...selectedTutors]

        setSelectedTutors(newSelectedTutors);
    }
    const removeTutorFromPrivateStudent = (tutor: Tutor) => {
        removeTutor({ id: studentId, tutorId: tutor.id });
    }
    const saveNewSelectedTutors = () => {
        addTutorsToPrivateStudent({ id: studentId, tutors: selectedTutors });
    }
    useEffect(() => {
        if (addTutorsToPrivateStudentResult.isSuccess)
            setSelectedTutors([])
    }, [addTutorsToPrivateStudentResult.isSuccess])


    const tutorsFromSearch = searchResultData?.tutors
        .filter(returnedTutor => [...studentTutors, ...selectedTutors].findIndex(studentTutor => studentTutor.id == returnedTutor.id) == -1)  || []
    
    return (
        <div>
            <SearchForm handleSearch={(value) => setFilter({ email: value, name: value })} placeholder="enter tutor name or email"/>
            {isSuccess && searchResultData.tutors.length === 0 && <Alert variant="info">no tutor with name or email</Alert> }

            <Table striped bordered hover responsive>
                <tbody>
                    {  
                        isSuccess && tutorsFromSearch.length > 0 && 
                        <StudentTutorsTableRows tutors={tutorsFromSearch} 
                            additionalColumnAction={toggleSelectTutor}
                            additionalColumnActionType="select"
                        />
                    }
                    <StudentTutorsTableRows tutors={selectedTutors} 
                        additionalColumnAction={toggleSelectTutor}
                        additionalColumnActionType="unselect"
                    />
                    <StudentTutorsTableRows 
                        tutors={studentTutors}
                        additionalColumnAction={removeTutorFromPrivateStudent}
                        additionalColumnActionType="remove"
                    />
                </tbody>
            </Table>
            <div className="mt-4 text-center">
                <Button variant="primary" disabled={selectedTutors.length == 0} onClick={() => saveNewSelectedTutors()}>Save</Button>
            </div>
        </div>
    )
};

export default TutorsSearch;