import { baseService } from "./base.store";
import { findSessions, countSessions } from "../services/session.service";
import { Session } from "../entities/session.entity";

export enum SessionStatus {
	ALL = 0,
	BOOKED = 1,
	UNBOOKED = 2,
}

export enum SessionType {
	ALL = 0,
	FREE = 1,
	PAID = 2,
}

export type SessionFilterQuery = {
	type?: SessionType;
	status?: SessionStatus;
};

export const sessionService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getSessions: builder.query<
			{ sessions: Session[] },
			{ where?: SessionFilterQuery; offset?: string }
		>({
			queryFn: async ({ where, offset }) =>
				await findSessions(where, offset),
		}),
		countSessions: builder.query<{ count: number }, { where: any }>({
			queryFn: async ({ where }) => await countSessions(where),
		}),
	}),
});

export const { useGetSessionsQuery, useCountSessionsQuery } = sessionService;
