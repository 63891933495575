import { Link, Navigate, useParams } from 'react-router-dom';
import PageLoading from '../../../components/pageLoading';
import PageHeader from '../../../components/pageHeader';
import { useGetStudentByIdQuery } from '../../../store/student.store';
import StudentInfo from '../../../components/students/student/studentInfo';
import ConvertStudentToTutor from '../../../components/students/student/convertStudentToTutor';
import MakeStudentPrivate from '../../../components/students/student/makeStudentPrivate';
import { Col, Row } from 'react-bootstrap';

const ShowStudent = () => {
  let { id = '' } = useParams();
  const { data, isSuccess, isError } = useGetStudentByIdQuery(id, {
    skip: id ? false : true
  });

  return isSuccess ? (
    <div className='page_container'>
      <PageHeader text='Student' />
      <StudentInfo student={data.student} />
      <div className='text-center mt-5'>
        <h3 className='mb-4'>Actions</h3>
        <Row className='justify-content-center'>
          <Col xs={12} sm={6} lg={3} className='mb-3'>
            {
              !data.student.isPrivate ? (
                <MakeStudentPrivate student={data.student} />
              ) : (
                <Link className='btn btn-primary w-100' to={`/students/${id}/private`}>
                  Show Private Tutors
                </Link>
              )
            }
          </Col>
          <Col xs={12} sm={6} lg={3} className='mb-3'>
            <ConvertStudentToTutor student={data.student} />
          </Col>
          <Col xs={12} sm={6} lg={3} className='mb-3'>
            <Link to={`/students-transactions?studentId=${data.student.id}`} className='btn btn-info w-100'>
              Transactions
            </Link>
          </Col>
          <Col xs={12} sm={6} lg={3} className='mb-3'>
            <Link to={`/find-for-me-requests?studentId=${data.student.id}`} className='btn btn-secondary w-100'>
              Find For Me Requests
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  ) : isError ? (
    <Navigate to='/404' />
  ) : (
    <PageLoading />
  );
};

export default ShowStudent;
