import { Booking } from "../entities/booking.entity";
import { CancellationRequest } from "../entities/cancellationRequest.entity";
import { Student } from "../entities/student.entity";
import { Tutor } from "../entities/tutor.entity";
import { bookingRepository } from "../repositories/booking.repository";
import {
	ApproveCancellationRequestParams,
	cancellationRequestRepository,
} from "../repositories/cancellationRequest.repository";
import { studentRepository } from "../repositories/student.repository";
import { tutorRepository } from "../repositories/tutor.repository";
import { GenericQueryReturnType, GenericUpdateReturnType } from "../store";

export const findCancellationRequests = async (
	where?: any,
	offset?: string
): Promise<
	GenericQueryReturnType<"cancellationRequests", CancellationRequest[]>
> => {
	try {
		const cancellationRequests =
			await cancellationRequestRepository.findAll(where, offset, [
				"cancellationDate",
				"desc",
			]);
		const bookingCancellationRequests = await Promise.all(
			cancellationRequests.map(async (request) => {
				const booking = await bookingRepository.findById(
					request.bookingId
				);
				return {
					...request,
					booking: booking || undefined,
				};
			})
		);
		return {
			data: {
				cancellationRequests: bookingCancellationRequests,
			},
		};
	} catch (_) {
		return {
			error: true,
		};
	}
};

export const findCancellationRequestById = async (
	id: string
): Promise<
	GenericQueryReturnType<"cancellationRequest", CancellationRequest>
> => {
	try {
		const cancellationRequest =
			await cancellationRequestRepository.findById(id);
		if (!cancellationRequest) return { error: true };
		const cancellationRequestDetails = await findCancellationRequestDetails(
			cancellationRequest
		);
		return {
			data: {
				cancellationRequest: {
					...cancellationRequest,
					...cancellationRequestDetails,
				},
			},
		};
	} catch (error) {
		return { error: true };
	}
};
const findCancellationRequestDetails = async (
	cancellationRequest: CancellationRequest
): Promise<{
	booking?: Booking;
	student?: Student;
	tutor?: Tutor;
}> => {
	const booking = await bookingRepository.findById(
		cancellationRequest.bookingId
	);
	const student = await studentRepository.findById(
		cancellationRequest.studentId
	);
	const tutor = await tutorRepository.findById(cancellationRequest.tutorId);
	return {
		booking: booking || undefined,
		student: student || undefined,
		tutor: tutor || undefined,
	};
};

export const countCancellationRequests = async (
	where?: any
): Promise<GenericQueryReturnType<"count", number>> => {
	try {
		const count = await cancellationRequestRepository.count(where);

		return {
			data: {
				count,
			},
		};
	} catch (_) {
		return { error: true };
	}
};

export const updateCancellationRequestById = async (
	id: string,
	newData: Partial<CancellationRequest>
): Promise<GenericUpdateReturnType> => {
	try {
		await cancellationRequestRepository.update(id, newData);
		return { data: undefined };
	} catch (error) {
		return { error: "unknown" };
	}
};

export const approveCollectionOfCancellationRequests = async (
	cancellationRequests: ApproveCancellationRequestParams[]
): Promise<GenericUpdateReturnType> => {
	try {
		await cancellationRequestRepository.approveCancellationRequests(
			cancellationRequests
		);
		return {
			data: undefined,
		};
	} catch (error) {
		return { error: "unknown" };
	}
};

export const rejectCancellationRequest = async () => {};
