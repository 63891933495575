import { Timestamp } from "firebase/firestore";

export const getDateAfterYear = (): Timestamp => {
	const currentDate = new Date();
	const nextYearDate = new Date(currentDate);
	nextYearDate.setFullYear(currentDate.getFullYear() + 1);
	return Timestamp.fromDate(nextYearDate);
};

export const getCurrentDate = (): Timestamp => Timestamp.fromDate(new Date());

export const displayDate = (date: Timestamp | number): string | number => {
	if (date instanceof Timestamp) return date.toDate().toLocaleString();
	if (typeof date == "number" && date.toString().length < 5) return date;
	if (typeof date == "number") return new Date(date).toDateString();
	return date;
};
