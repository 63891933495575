import { baseService } from "./base.store";
import {
	findWithdrawalRequests,
	countWithdrawalRequests,
	updateWithdrawalRequestById,
	findWithdrawalRequestInvoicePdf,
} from "../services/WithdrawalRequest.service";
import { WithdrawalRequest } from "../entities/withdrawalRequest.entity";

export const withdrawalRequestService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getWithdrawalRequests: builder.query<
			{ withdrawalRequests: WithdrawalRequest[] },
			{ where?: any; offset?: string }
		>({
			queryFn: async ({ where = {}, offset }) =>
				await findWithdrawalRequests(where, offset),
			providesTags: ["WithdrawalRequest"],
		}),
		countWithdrawalReqests: builder.query<{ count: number }, void>({
			queryFn: async () => await countWithdrawalRequests(),
		}),
		updateWithdrawalRequestById: builder.mutation<
			void,
			{ id: string; newData: Partial<WithdrawalRequest> }
		>({
			queryFn: async ({ id, newData }) =>
				await updateWithdrawalRequestById(id, newData),
			invalidatesTags: ["WithdrawalRequest"],
		}),
		getInvoicePdf: builder.query<{ invoice: string }, string>({
			queryFn: async (id) => await findWithdrawalRequestInvoicePdf(id),
		}),
	}),
});

export const {
	useGetWithdrawalRequestsQuery,
	useCountWithdrawalReqestsQuery,
	useUpdateWithdrawalRequestByIdMutation,
	useGetInvoicePdfQuery,
} = withdrawalRequestService;
