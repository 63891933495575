import { useCallback, useEffect, useState } from 'react';
import { useChatSupportActiveMessages } from '../../../context/chat-support/chat-support-active-chat.context';
import { useIntersectionObserver } from '../../../customHooks/useIntersectionOpserver';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { Spinner } from '../../shared/atoms/spinner';
import { Badge } from '../../shared/atoms/badge';
import { scrollToBottom } from '../../../utils/util';

export const ActiveChatOldMSGs = () => {
  const [isFetching, setIsFetching] = useState(false);
  const {
    states: {
      isOldMessages: { isOldMessages, setIsOldMessages },
      messages: {
        limit,
        setMessages,
        messages,
        msgsContainerRef,
        initialFechedRef
      }
    }
  } = useChatSupportActiveMessages();
  const { actions } = useChatSupport();
  const { ref, isIntersecting } = useIntersectionObserver();

  const fetchOldMsgs = useCallback(async () => {
    setIsFetching(true);
    const lastMessage = messages[messages.length - 1];
    const { isMoreOldMSGs } = await actions.getOldMessages(
      lastMessage,
      setMessages,
      limit
    );
    if (!isMoreOldMSGs) setIsOldMessages(isMoreOldMSGs);
    setIsFetching(false);
    scrollToBottom(msgsContainerRef, 50);
  }, [messages, setMessages]);
  useEffect(() => {
    if (isOldMessages && isIntersecting && initialFechedRef.current)
      fetchOldMsgs();
  }, [isIntersecting, isOldMessages]);
  return (
    <div className='flex_center' ref={ref}>
      {!isOldMessages && initialFechedRef.current && (
        <Badge color='yellow'> no more messages </Badge>
      )}
      {isFetching && <Spinner />}
    </div>
  );
};
