import { getDocs, OrderByDirection } from "firebase/firestore";
import { WithdrawalRequest } from "../entities/withdrawalRequest.entity";
import { Repository } from "../firebase/firestore/repository";
import { buildFilterQuery } from "../firebase/firestore/filterQueryBuilder";
import { TutorTransactionStatus } from "../entities/transactionHistory.entity";
import { where as whereCondation } from "firebase/firestore";
export class WithdrawalRequestRepository extends Repository<WithdrawalRequest> {
	constructor() {
		super("tutorsTransactionHistory");
	}
	async findAll(
		where?: any,
		offset?: string,
		orderBy?: string | [string, OrderByDirection] | undefined,
		limit?: number
	): Promise<WithdrawalRequest[]> {
		const query = await buildFilterQuery(
			this.collection,
			{},
			limit || 10,
			offset,
			orderBy,
			[
				whereCondation("status", "in", [
					TutorTransactionStatus.Approved,
					TutorTransactionStatus.PENDING,
				]),
			]
		);
		const snapshot = await getDocs(query);
		return snapshot.docs.map((doc) => this.map(doc));
	}
	async count(where?: any): Promise<number> {
		const query = await buildFilterQuery(
			this.collection,
			{},
			undefined,
			undefined,
			undefined,
			[
				whereCondation("status", "in", [
					TutorTransactionStatus.Approved,
					TutorTransactionStatus.PENDING,
				]),
			]
		);
		const snapshot = await getDocs(query);
		return snapshot.docs.length;
	}
}

export const withdrawalRequestRepository = new WithdrawalRequestRepository();
