import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { ChatStatus } from '../../../entities/chat-support.entity';
import { Badge } from '../../shared/atoms/badge';
import { UserCardWithImageAndName } from '../../shared/molecules/user-card-with-profile-name';
import { ActiveChatHeaderMenu } from './active-chat-header-menu';

export const ActiveChatHeader = () => {
  const {
    states: {
      activeChatRoom: { activeChatRoom }
    }
  } = useChatSupport();

  return (
    <div className='tw-drop-shadow-md tw-flex-col     tw-w-full tw-flex md:tw-flex-row  tw-z-50 tw-items-center tw-gap-2 tw-rounded-t-lg tw-p-4 tw-bg-white tw-border-b'>
      <UserCardWithImageAndName user={activeChatRoom.user}>
        <span className='tw-text-gray-400  tw-text-xs   tw-break-words'>
          {activeChatRoom.user.email}
        </span>
      </UserCardWithImageAndName>

      <div className='md:tw-ms-auto flex_center tw-flex tw-gap-2'>
        <Badge
          className=' tw-me-4  tw-rounded-md  tw-h-max  '
          color={activeChatRoom.tutorId ? 'blue' : 'pink'}
          children={activeChatRoom.tutorId ? 'Tutor' : 'Student'}
        />
        <Badge
          className=' tw-rounded-md'
          isDot
          color={activeChatRoom.status === ChatStatus.OPEN ? 'green' : 'gray'}
          children={
            activeChatRoom.status === ChatStatus.OPEN ? 'Open' : 'Closed'
          }
        />

        <ActiveChatHeaderMenu status={activeChatRoom.status} />
      </div>
    </div>
  );
};
