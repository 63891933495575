import * as voucherCodeGenerator from "voucher-code-generator";

const generateRandomCodes = (count: number = 1): string[] =>
	voucherCodeGenerator.generate({
		length: 6,
		charset: "1234567890",
		count,
	});

const generateVouchersCodes = (
	existingCodes: Set<string>,
	count?: number
): string[] => {
	const vouchersCodes: string[] = [];

	for (let i = 0; i < (count || 1); i++) {
		let newRandomCode: string;

		do {
			newRandomCode = generateRandomCodes()[0];
		} while (existingCodes.has(newRandomCode));

		vouchersCodes.push(newRandomCode);
		existingCodes.add(newRandomCode);
	}

	return vouchersCodes;
};

export const generateRandomVoucherCode = (existingCodes: Set<string>): string =>
	generateVouchersCodes(existingCodes)[0];

export const generateRandomVouchersCodesByCount = (
	existingCodes: Set<string>,
	count: number
): string[] => generateVouchersCodes(existingCodes, count);
