import { Navigate } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import PageLoading from '../../components/pageLoading';
import {
  useCountVouchersCodesQuery,
  useGetVouchersCodesQuery
} from '../../store/voucherCode.store';
import CreateVoucherCode from '../../components/vouchersCodes/voucherCode/CreateVoucherCode';
import VoucherCodesTable from '../../components/vouchersCodes/vouchersCodesTable';
import SearchForm from '../../components/SearchForm';
import usePagination from '../../customHooks/usePagination.hook';
import SelectByOrganization from '../../components/selectByOrganization';
import { useState } from 'react';
import Pagination from '../../components/pagination';
import { VoucherCode } from '../../entities/voucherCode.entity';
import { Alert } from 'react-bootstrap';
import { VoucherCodesOrganization } from '../../components/vouchersCodes/voucherCode/voucher-code-organization';

type VoucherCodeFilterQuery = {
  organizationId?: string;
  code?: string;
  id?: string;
};
const VouchersCodes = () => {
  const [filterQuery, setFilterQuery] = useState<VoucherCodeFilterQuery>({});
  const {
    data,
    numberOfPages,
    currentPage,
    next,
    prev,
    updateQuery,
    isLoading,
    isSuccess,
    isError
  } = usePagination<VoucherCode>({
    useGetDataQuery: useGetVouchersCodesQuery,
    useCountData: useCountVouchersCodesQuery
  });

  const handleUpdateQuery = (newValues: any) => {
    updateQuery({
      ...filterQuery,
      ...newValues
    });
    setFilterQuery({
      ...filterQuery,
      ...newValues
    });
  };

  return (
    <div className='page_container'>
      <PageHeader text='Vouchers codes' />
      <SelectByOrganization
        setOrganizationId={(organizationId) =>
          handleUpdateQuery({ organizationId })
        }
        defaultText='all vouchers codes'
      />
      {!filterQuery.organizationId && (
        <div className='mt-2'>
          <SearchForm
            placeholder='Enter code or id'
            handleSearch={(value) =>
              handleUpdateQuery({ id: value, code: value })
            }
          />
        </div>
      )}
      {isSuccess ? (
        <>
          {filterQuery.organizationId ? (
            <VoucherCodesOrganization codesData={data} />
          ) : (
            <>
              <VoucherCodesTable vouchersCodes={data} />
              {data.length == 0 ? (
                <Alert variant='info'>no matched vouchers codes</Alert>
              ) : null}
              <Pagination
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                prev={prev}
                next={next}
              />
            </>
          )}
        </>
      ) : isError ? (
        <Navigate to='/505' />
      ) : (
        <PageLoading />
      )}
      <div className='text-center'>
        <CreateVoucherCode />
      </div>
    </div>
  );
};

export default VouchersCodes;
