import { useSearchParams } from "react-router-dom";
import { useGetOrganizationsQuery } from "../store/organization.store";
import { useEffect } from "react";
type Props = {
    defaultText: string;
    setOrganizationId: (organizationId?: string) => void
}
const SelectVouchersCodesByOrganization = ({ setOrganizationId, defaultText }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const organizations = useGetOrganizationsQuery();
    const handleSelectChanges = (value: string) => {
        setSearchParams({});
        setOrganizationId(value == "all" ? undefined : value)
    }

    return (
            organizations.isSuccess ? <select className="form-control form-control-lg mb-3" aria-label="select select grade"
                        onChange={(e) => {
                            handleSelectChanges(e.target.value)
                        }}
                        value={searchParams.get("organization") || undefined}
                >
                        <option value="all">{defaultText}</option>
                    {
                        organizations.data.organizations.map(organization => {
                            return (
                                <option value={organization.id} key={organization.id} >{organization.name}</option>
                            )
                        })
                    }
                </select>
            : null
    )
}

export default SelectVouchersCodesByOrganization;
