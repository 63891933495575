import { baseService } from "./base.store";
import { Admin, CreateAdmin } from "../entities/admin.entity";
import { createAdmin, findAdminsByQuery } from "../services/admin.service";

export const adminService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		addNewAdmin: builder.mutation<Admin, CreateAdmin>({
			queryFn: async (newAdmin) => await createAdmin(newAdmin),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				try {
					const result = await queryFulfilled;
					if (result.data)
						dispatch(
							adminService.util.updateQueryData(
								"getAllAdmins",
								undefined,
								(draftAdmins) => {
									draftAdmins.admins.push(result.data);
								}
							)
						);
				} catch (_) {}
			},
		}),
		getAllAdmins: builder.query<{ admins: Admin[] }, void>({
			queryFn: async () => await findAdminsByQuery({}),
		}),
	}),
});
export const { useGetAllAdminsQuery, useAddNewAdminMutation } = adminService;
