import { Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { SessionFilterQuery, SessionStatus, SessionType } from "../../store/session.store";


const SessionsFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: SessionFilterQuery) => void }) => {
	const [filter, setFilter] = useState<SessionFilterQuery>({});

	const handleStatusChange = (status: SessionStatus) => {
		setFilterQuery({ ...filter, status });
		setFilter({ ...filter, status });
	};

	const handleTypeChange = (type: SessionType) => {
		setFilterQuery({ ...filter, type });
		setFilter({ ...filter, type });
	};
	
	return (
		<Row>
			<Col sm={6}>
				<Form.Select
					onChange={(e) => handleTypeChange(parseInt(e.target.value) as SessionType)}
				>
					<option value={SessionType.ALL}>All</option>
					<option value={SessionType.FREE}>free</option>
					<option value={SessionType.PAID}>paid</option>
				</Form.Select>
			</Col>
			<Col sm={6}>
				<Form.Select
					onChange={(e) => handleStatusChange(parseInt(e.target.value) as SessionStatus)}
				>
					<option value={SessionStatus.ALL}>all</option>
					<option value={SessionStatus.BOOKED}>booked</option>
					<option value={SessionStatus.UNBOOKED}>un booked</option>
				</Form.Select>
			</Col>
		</Row>
	);
};

export default SessionsFilterForm;
