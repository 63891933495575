import { GenericQueryReturnType } from '../store';
import { sessionRepository } from '../repositories/session.repository';
import { Session } from '../entities/session.entity';
import { SessionFilterQuery } from '../store/session.store';
import { tutorRepository } from '../repositories/tutor.repository';
import { studentRepository } from '../repositories/student.repository';

export const findSessions = async (
  where: SessionFilterQuery = {},
  offset?: string
): Promise<GenericQueryReturnType<'sessions', Session[]>> => {
  try {
    const sessions = await sessionRepository.findAll(where, offset);
    const sessionsWithAttachments = await Promise.all(
      sessions.map(async (session) => {
        const tutor = await tutorRepository.findById(session.tutorId);
        const student = await studentRepository.findById(session.studentId);

        return {
          ...session,
          tutor: tutor || undefined,
          student: student || undefined
        };
      })
    );
    return {
      data: {
        sessions: sessionsWithAttachments
      }
    };
  } catch (error) {
    console.log(error);
    return {
      error: true
    };
  }
};

export const countSessions = async (
  where?: any
): Promise<GenericQueryReturnType<'count', number>> => {
  try {
    const count = await sessionRepository.count(where);

    return {
      data: {
        count: count
      }
    };
  } catch (_) {
    return { error: true };
  }
};
