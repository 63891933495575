import { collection, doc, writeBatch } from "firebase/firestore";
import { Repository } from "../firebase/firestore/repository";
import { db } from "../firebase";
import { BookingStatus } from "../entities/booking.entity";
import {
	CancellationRequest,
	CancellationRequestStatus,
} from "../entities/cancellationRequest.entity";

export type ApproveCancellationRequestParams = {
	cancellationRequestId: string;
	bookingId: string;
};
export class CancellationRequestRepository extends Repository<CancellationRequest> {
	private bookingCollectionName = "tutorBookings";

	constructor() {
		super("cancellationRequests");
	}

	async approveCancellationRequests(
		requests:
			| ApproveCancellationRequestParams[]
			| ApproveCancellationRequestParams
	): Promise<void> {
		const batch = writeBatch(db);

		// Normalize requests to an array if single request is provided
		const cancellationRequests = Array.isArray(requests)
			? requests
			: [requests];

		cancellationRequests.forEach(({ cancellationRequestId, bookingId }) => {
			batch.update(doc(this.collection, cancellationRequestId), {
				status: CancellationRequestStatus.CANCELED,
			});
			batch.update(
				doc(collection(db, this.bookingCollectionName), bookingId),
				{
					bookingStatus: BookingStatus.CANCELED,
				}
			);
		});
		await batch.commit();
	}
}

export const cancellationRequestRepository =
	new CancellationRequestRepository();
