import { baseService } from "./base.store";
import {
	CreateOrganization,
	Organization,
} from "../entities/organization.entity";
import {
	createOrganization,
	findOrganizationById,
	findOrganizations,
} from "../services/organization.service";
// Define a service using a base URL and expected endpoints
export const organizationService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		createOrganization: builder.mutation<
			{ newOrganization: Organization },
			CreateOrganization
		>({
			queryFn: async ({ email, name, password }: CreateOrganization) =>
				await createOrganization({ email, name, password }),
			onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
				try {
					const result = await queryFulfilled;
					if (result.data)
						dispatch(
							organizationService.util.updateQueryData(
								"getOrganizations",
								undefined,
								(draftOrganizations) => {
									draftOrganizations.organizations.push(
										result.data.newOrganization
									);
								}
							)
						);
				} catch (_) {}
			},
		}),
		getOrganizations: builder.query<
			{ organizations: Organization[] },
			void
		>({
			queryFn: async () => await findOrganizations(),
		}),
		getOrganizationById: builder.query<
			{ organization: Organization },
			string
		>({
			queryFn: async (id) => await findOrganizationById(id),
		}),
		// addStudentToOrganization: builder.mutation<{success: boolean}, { student: Partial<OrganizationStudent>, organizationId: string} >({
		//     queryFn: async ({ organizationId, student }) => {
		//         try {
		//             console.log(organizationId, student)
		//             await addDoc(collection(db, "students"), { organizationId, ...student })
		//             return { data: { success: true } }
		//         } catch (err) {
		//             console.log(err)
		//             return { error: true }
		//         }
		//     },
		//     invalidatesTags: ["Organization"]
		// })
	}),
});
export const {
	useCreateOrganizationMutation,
	useGetOrganizationsQuery,
	useGetOrganizationByIdQuery,
} = organizationService;
