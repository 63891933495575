import { useCallback, useEffect, useRef, useState } from 'react';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { ActiveChatHeader } from '../molecules/active-chat-header';
import styles from './active-chat.module.css';
import { ActiveChatInput } from '../molecules/active-chat-input';
import {
  ChatSupportActiveChatContextType,
  ChatSupportActiveContext
} from '../../../context/chat-support/chat-support-active-chat.context';
import {
  ActiveChatMessage,
  PendingMessage
} from '../../../types/chat-support/chat-support.type';
import { ActiveChatPendingMessage } from '../molecules/active-chat-pending-msg';
import { ActiveChatMessagesContainer } from './active-chat-messages-container';
import { ActiveChatOldMSGs } from '../atoms/active-chat-old-msgs';
import { NewMsgScrollHelper } from '../atoms/active-chat-new-msg-helper';
export const ActiveChat = () => {
  const [messages, setMessages] = useState<ActiveChatMessage[]>([]);
  const [pendingMsgs, setPendingMsgs] = useState<PendingMessage[]>([]);
  const [newRecivedMessagesCount, setNewRecivedMessagesCount] = useState(0);
  const [isOldMessages, setIsOldMessages] = useState(false);
  const msgsContainerBottomRef = useRef<HTMLDivElement>(null);
  const initialFechedRef = useRef<boolean | null>(null);
  const lastActiveChatDocId = useRef<string>('');
  const contextState: ChatSupportActiveChatContextType = {
    states: {
      messages: {
        messages,
        setMessages,
        limit: 30,
        newRecivedMessagesCount,
        setNewRecivedMessagesCount,
        msgsContainerRef: msgsContainerBottomRef,
        initialFechedRef
      },
      pending: {
        pendingMsgs,
        setPendingMsgs
      },
      isOldMessages: {
        isOldMessages,
        setIsOldMessages
      }
    }
  };
  const {
    actions,
    states: {
      activeChatRoom: { activeChatRoom, setActiveChatRoom }
    }
  } = useChatSupport();

  const resetInitalState = useCallback(() => {
    if (lastActiveChatDocId.current === activeChatRoom.id) return; // normal rerended like update last message

    lastActiveChatDocId.current = activeChatRoom.id;
    setMessages([]);
    setPendingMsgs([]);
    setNewRecivedMessagesCount(0);
    setIsOldMessages(false);
    initialFechedRef.current = false;
  }, [lastActiveChatDocId, activeChatRoom]);

  useEffect(() => {
    if (messages.length && activeChatRoom) {
      const lastMessage = messages[0];
      setActiveChatRoom((old) => ({ ...old, lastMessage }));
    }
  }, [messages]);

  useEffect(() => {
    if (activeChatRoom.unreadCount.support > 0) {
      actions.updateServerUnRead();
    }
    resetInitalState();
  }, [activeChatRoom, actions]);

  return (
    <ChatSupportActiveContext.Provider value={contextState}>
      <div className='tw-flex tw-flex-col tw-h-full    tw-w-full'>
        <ActiveChatHeader />

        <div
          className={`${styles['chat-container']} tw-flex-1  t-flex tw-flex-col tw-relative `}
        >
          <div
            ref={msgsContainerBottomRef}
            className='  tw-absolute tw-left-0 tw-top-0  tw-p-1  tw-flex  tw-flex-col  tw-gap-4 tw-overflow-y-auto  tw-h-full tw-w-full'
          >
            {/* old message */}
            <ActiveChatOldMSGs />
            <ActiveChatMessagesContainer />
            {/* pending messages */}

            {pendingMsgs.map((message) =>
              // saving message promise take time and listner snapshot trigger reRender without promise resolve reRender happen twice
              message.tempId ? (
                <ActiveChatPendingMessage
                  key={message.tempId}
                  message={message}
                />
              ) : null
            )}
            <NewMsgScrollHelper />
          </div>
        </div>
        <div className='tw-mt-auto tw-flex  tw-w-full'>
          <ActiveChatInput />
        </div>
      </div>
    </ChatSupportActiveContext.Provider>
  );
};
