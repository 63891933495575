import { collection } from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link, useLocation } from 'react-router-dom';
import { db } from '../../../firebase';
import { buildFilterQuery_v2 } from '../../../firebase/firestore/filterQueryBuilder';
import { PiChatCircleDotsFill } from 'react-icons/pi';

import { ChatSupportDoc } from '../../../entities/chat-support.entity';
import { fireStoreCollectionName } from '../../../utils/firebase.util';
import Draggable from 'react-draggable';
export const ChatNotification = () => {
  const chatPageUrl = useMemo(() => '/chat-support', []);
  const { pathname } = useLocation();
  const isChatPage = useMemo(() => {
    return pathname.includes(chatPageUrl);
  }, [pathname]);

  const [counter, setCounter] = useState(0);
  const newChatQuery = useMemo(() => {
    const collectionRef = collection(db, fireStoreCollectionName.chat_support);
    const query = buildFilterQuery_v2<ChatSupportDoc>(
      collectionRef,
      [['unreadCount.support' as keyof ChatSupportDoc, '>', 0]],
      30,
      undefined,
      ['createdAt', 'desc']
    );
    return query;
  }, []);
  const [data, loading, error, snapshot] = useCollectionData(newChatQuery);

  const handleData = useCallback(() => {
    if (!data) return;
    if (data.length >= 10 && counter >= 10) return;
    setCounter(data.length);
  }, [data, counter]);

  useEffect(() => {
    handleData();
  }, [data, handleData]);

  const nodeRef = useRef(null);

  if (isChatPage) return null;

  return (
    <Draggable nodeRef={nodeRef} cancel='.tw-relative' bounds='parent'>
      <div
        ref={nodeRef}
        className='    tw-absolute  tw-end-8 tw-bottom-7  tw-drop-shadow-lg  tw-cursor-move tw-bg-sky-100 tw-p-2 tw-rounded-3xl  '
      >
        <div className=' tw-relative tw-cursor-pointer'>
          <Link to={chatPageUrl}>
            <div
              className={`  tw-text-xs  tw-font-medium tw-bg-rose-400 tw-size-6 flex_center tw-text-white tw-p-1  tw-rounded-full  tw-end-[-6px]  tw-top-[-9px]  tw-absolute ${
                counter ? 'tw-opacity-100' : 'tw-opacity-0'
              }`}
            >
              {counter}
            </div>
            <PiChatCircleDotsFill className=' tw-size-8   tw-text-sky-700' />
          </Link>
        </div>
      </div>
    </Draggable>
  );
};
