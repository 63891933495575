import { RefObject } from 'react';
import loadsh_isEqual from 'lodash.isequal';

export const isDevlopment = process.env.NODE_ENV === 'development';
export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const scrollToBottom = (
  elmentRef: RefObject<HTMLDivElement>,
  heightToScroll?: number
) => {
  if (elmentRef.current) {
    elmentRef.current!.scrollTo(
      0,
      heightToScroll ?? elmentRef.current!.scrollHeight
    );
  }
};

export const isEqual = <T>(obj: T, obj2: T) => {
  return loadsh_isEqual(obj, obj2);
};

export const removeDuplicatesFromOldArrById = <T extends { id: string }>(
  old: T[],
  newArr: T[]
) => {
  return old.filter((old) => !newArr.find((newItem) => newItem.id === old.id));
};
