import { baseService } from "./base.store";
import {
	countCancellationRequests,
	findCancellationRequests,
	findCancellationRequestById,
	updateCancellationRequestById,
	approveCollectionOfCancellationRequests,
} from "../services/cancellationRequest.service";
import { CancellationRequest } from "../entities/cancellationRequest.entity";
import { ApproveCancellationRequestParams } from "../repositories/cancellationRequest.repository";

export const cancellationRequestService = baseService.injectEndpoints({
	endpoints: (builder) => ({
		getCancellationRequests: builder.query<
			{ cancellationRequests: CancellationRequest[] },
			{ offset?: string; where?: any }
		>({
			queryFn: async ({ where, offset }) =>
				await findCancellationRequests(where, offset),
			providesTags: ["CancellationRequest"],
		}),
		getCancellationRequestById: builder.query<
			{ cancellationRequest: CancellationRequest },
			string
		>({
			queryFn: async (id) => await findCancellationRequestById(id),
		}),
		approveCancellationRequests: builder.mutation<
			void,
			{ cancellationRequests: ApproveCancellationRequestParams[] }
		>({
			queryFn: async ({ cancellationRequests }) =>
				await approveCollectionOfCancellationRequests(
					cancellationRequests
				),
			invalidatesTags: ["CancellationRequest"],
		}),
		rejectCancellationRequest: builder.mutation<
			void,
			{ id: string; newData: Partial<CancellationRequest> }
		>({
			queryFn: async ({ id, newData }) =>
				await updateCancellationRequestById(id, newData),
		}),
		countCancellationRequests: builder.query<
			{ count: number },
			{ where?: any }
		>({
			queryFn: async ({ where }) =>
				await countCancellationRequests(where),
			providesTags: ["CancellationRequest"],
		}),
	}),
});

export const {
	useGetCancellationRequestsQuery,
	useGetCancellationRequestByIdQuery,
	useCountCancellationRequestsQuery,
	useApproveCancellationRequestsMutation,
} = cancellationRequestService;
