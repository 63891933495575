import { Navigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/pageHeader';
import { useGetVoucherCodeByIdQuery } from '../../store/voucherCode.store';
import PageLoading from '../../components/pageLoading';
import VoucherCodeDetails from '../../components/vouchersCodes/voucherCode/voucherCodeDetails';

const ShowVoucherCode = () => {
  const { id: voucherCodeId } = useParams<{ id: string }>();
  const { data, isLoading, isSuccess, isError } = useGetVoucherCodeByIdQuery(
    voucherCodeId || '',
    { skip: !voucherCodeId }
  );

  return isLoading ? (
    <PageLoading />
  ) : isError ? (
    <Navigate to='/404' />
  ) : isSuccess ? (
    <div className='page_container'>
      <PageHeader text='Vouchers codes' />
      <VoucherCodeDetails voucherCode={data.voucherCode} />
    </div>
  ) : null;
};

export default ShowVoucherCode;
