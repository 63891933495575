import { useMemo, useRef } from 'react';
import { VoucherCode } from '../../../entities/voucherCode.entity';
import {
  ReusableTable,
  TableHeader
} from '../../shared/organisms/resuable-table';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from '../../shared/atoms/print-button';

type Props = {
  codesData: VoucherCode[];
};

export const VoucherCodesOrganization = ({ codesData }: Props) => {
  const tableHeader = useMemo(() => {
    const headers: TableHeader<VoucherCode>[] = [
      {
        key: 'code',
        label: 'code'
      },
      {
        key: 'noOfPoints',
        label: 'code Points'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'student.email' as keyof VoucherCode,
        label: 'email',
        customGetter: (data) => {
          return data.student?.email ?? '';
        }
      },
      {
        key: 'user.remaining' as keyof VoucherCode,
        label: 'remaining points',
        customGetter(data) {
          return data.student?.points
            ? data.student?.points.toFixed(0)
            : data.noOfPoints;
        }
      },
      {
        key: 'organization',
        label: 'organization',
        customGetter(data) {
          return data.organization?.name;
        }
      }
    ];
    return headers;
  }, []);
  const navigate = useNavigate();
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!
  });
  return (
    <>
      <PrintButton handlePrint={handlePrint} disabled={codesData.length < 1} />

      <div className=' tw-mt-2' ref={componentRef}>
        <ReusableTable
          onLineCick={(voucherCode) =>
            navigate('/vouchers-codes/' + voucherCode.id)
          }
          columns={tableHeader}
          rows={codesData}
        />
      </div>
    </>
  );
};
