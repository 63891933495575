import {
  DocumentReference,
  Timestamp,
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter
} from 'firebase/firestore';
import {
  ChatMessage,
  ChatStatus,
  ChatSupport,
  ChatSupportCollectionName
} from '../entities/chat-support.entity';
import { Repository } from '../firebase/firestore/repository';
import { RepoistoryFilter } from '../types/util.types';
import {
  ActiveChatMessage,
  ChatSummary,
  NewMessageBeforeSave
} from '../types/chat-support/chat-support.type';
import { findStudentById } from '../services/student.service';
import { Student } from '../entities/student.entity';
import { extractFields } from '../utils/objectSelector.util';
import { buildFilterQuery_v2 } from '../firebase/firestore/filterQueryBuilder';
import { fireStoreCollectionName } from '../utils/firebase.util';
import { Tutor } from '../entities/tutor.entity';

export class ChatSupportRepository extends Repository<ChatSupport> {
  constructor() {
    super(ChatSupportCollectionName);
  }

  async getChatsByState(
    filter: RepoistoryFilter<ChatSupport>,
    status?: ChatStatus
  ): Promise<ChatSummary[]> {
    const snapShots = await this.getChatsByStatus(filter, status);
    return Promise.all(
      snapShots.docs.map(async (doc) => {
        const chatData = doc.data() as ChatSupport;
        const id = doc.id;
        const docRef = doc.ref;
        const chatDoc = { id, docRef, ...chatData };
        const [chatSummary] =
          await this.getChatSummariesWithLastMessageAndUserData([chatDoc]);
        return chatSummary;
      })
    );
  }
  async getChatCountsByState(status?: ChatStatus): Promise<number> {
    const filter: RepoistoryFilter<ChatSupport> = {};
    if (typeof status === 'number') {
      filter.where = [['status', '==', status]];
    }
    const query = buildFilterQuery_v2(this.collection, filter.where);
    return this.countByQuery(query);
  }

  private getChatsByStatus(
    { limit = 20, offsetDocId, orderBy }: RepoistoryFilter<ChatSupport>,
    status?: ChatStatus
  ) {
    if (typeof status === 'number') {
      return this.findByFilter({
        where: [['status', '==', status]],
        limit,
        offsetDocId,
        orderBy
      });
    }
    return this.findByFilter({
      limit,
      offsetDocId,
      orderBy
    });
  }

  async saveNewMessage(chatDoc: ChatSummary, newMessage: NewMessageBeforeSave) {
    const messagesRef = collection(chatDoc.docRef, 'messages');
    delete newMessage.tempId;
    const newMessageData: Omit<ChatMessage, 'id'> = {
      ...newMessage,
      createdAt: serverTimestamp() as Timestamp
    };

    await addDoc(messagesRef, newMessageData);
    const clientUnReadCount: ChatSummary['unreadCount'] = chatDoc.unreadCount
      ? chatDoc.unreadCount
      : { support: 0, user: 0 };
    clientUnReadCount.user++;
    clientUnReadCount.support = 0;
    await this.update(chatDoc.id, {
      unreadCount: clientUnReadCount
    });
  }

  async fetchOldMessagesByRef(
    chatDocRef: DocumentReference,
    lastMessageDocId: string,
    limitedDocs: number
  ): Promise<Omit<ActiveChatMessage, 'student'>[]> {
    const messagesRef = collection(chatDocRef, 'messages');
    const docSnapshot = await this.getDocByCollectionAndDocId(
      messagesRef,
      lastMessageDocId
    );
    const lastMessageQuery = query(
      messagesRef,
      orderBy('createdAt', 'desc'),
      startAfter(docSnapshot),
      limit(limitedDocs)
    );
    const messageSnapshot = await getDocs(lastMessageQuery);

    const fetchedDocs: Omit<ActiveChatMessage, 'student'>[] =
      messageSnapshot.docs.map((doc) =>
        this.mapToDataWithIdAndRef<Omit<ActiveChatMessage, 'student'>>(doc)
      );
    return fetchedDocs;
  }

  async getLastMessageByRef(chatDocRef: DocumentReference) {
    const messagesRef = collection(chatDocRef, 'messages');
    const lastMessageQuery = query(
      messagesRef,
      orderBy('createdAt', 'desc'),
      limit(1)
    );
    const messageSnapshot = await getDocs(lastMessageQuery);
    const message = messageSnapshot.docs[0].data() as ChatMessage;
    message.id = messageSnapshot.docs[0].id;
    return message;
  }

  async getChatSummariesWithLastMessageAndUserData(
    chats: Omit<ChatSummary, 'lastMessage' | 'user'>[]
  ): Promise<ChatSummary[]> {
    const chatSummaryPromises = chats.map(async (doc) => {
      const lastMessage = await this.getLastMessageByRef(doc.docRef);

      const res = doc.tutorId
        ? await this.findByCollectionAndId<Tutor>(
            fireStoreCollectionName.tutor,
            doc.tutorId
          )
        : await this.findByCollectionAndId<Student>(
            fireStoreCollectionName.students,
            doc.id
          );

      const user: ChatSummary['user'] = {
        ...extractFields(res, ['email', 'name', 'profileImage']),
        userType: doc.tutorId ? 'tutor' : 'student',
        userId: res.id
      };
      const chatSummary: ChatSummary = {
        ...doc,
        lastMessage,
        user
      };
      return chatSummary;
    });
    const summaries = await Promise.all(chatSummaryPromises);
    return summaries;
  }

  updateChatSupportStatus(docId: string, status: ChatStatus) {
    return this.update(docId, { status });
  }
  updateChatSupportReadCounter(
    docId: string,
    docUnreadCount: ChatSupport['unreadCount']
  ) {
    return this.update(docId, {
      unreadCount: {
        ...docUnreadCount,
        support: 0
      }
    });
  }

  // getChatMessage //before pagination
}
