import React from 'react'
import { useLogoutMutation } from '../../store/auth.store';
import { Navigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
export default function Logout() {
    const [logout, logoutResult] = useLogoutMutation()

    let logoutHanlder = () => {
        logout();
    }
    
    return (
        <>
            <Button variant="outline-light" className='fw-bold' style={{ padding: "5px 10px"}} onClick={logoutHanlder}>logout</Button>
            { logoutResult.isSuccess ? <Navigate to={"/login"}/>  : null }
            {/* <button className="btn text-start fs-6 fw-bold" style={{ color: "white" }} onClick={logoutHanlder}>logout</button>
            { logoutResult.isSuccess ? <Navigate to={"/login"}/>  : null } */}
        </>
    )
}
