import { useState } from 'react';
import { RepoistoryFilter } from '../types/util.types';

export const useRepoistoryFilter = <T extends RepoistoryFilter<any>>(
  repoFilter: T
) => {
  const [filter, setFilter] = useState(repoFilter);

  const updateFilter = (updatedFilter: T) => {
    setFilter({ ...filter, ...updatedFilter });
  };

  return { filter, updateFilter };
};
