import { Table } from 'react-bootstrap';
import {
  VoucherCode,
  VoucherCodeStatus
} from '../../entities/voucherCode.entity';
import { Link } from 'react-router-dom';
import DeleteVoucherCode from './voucherCode/deleteVoucherCode/deleteVoucherCode';

const VoucherCodesTable = ({
  vouchersCodes
}: {
  vouchersCodes: VoucherCode[];
}) => {
  return (
    <Table striped bordered hover responsive>
      <thead className='text-light'>
        <tr className='text-center'>
          <th>code</th>
          <th>points</th>
          <th>status</th>
          <th>organization</th>
          <th>show</th>
          <th>delete</th>
        </tr>
      </thead>
      <tbody>
        {vouchersCodes.map((voucherCode) => (
          <tr className='text-center' key={voucherCode.id}>
            <td data-title='code' className='fs-6'>
              {voucherCode.code}
            </td>
            <td data-title='points' className='fs-6'>
              {voucherCode.noOfPoints}
            </td>
            <td data-title='status' className='fs-6'>
              {voucherCode.status === VoucherCodeStatus.AVAILABLE &&
              voucherCode.expiryDate?.toDate() < new Date()
                ? 'EXPIRED'
                : voucherCode.status}
            </td>
            <td data-title='organizationId' className='fs-6'>
              {voucherCode.organizationId ? (
                <Link
                  to={'/organizations/students/' + voucherCode.organization?.id}
                  state={{ organization: voucherCode.organization }}
                  className='text-decoration-none'
                >
                  {voucherCode.organization?.name}
                </Link>
              ) : (
                '...'
              )}
            </td>
            <td data-title='points' className='fs-6'>
              <Link
                to={'/vouchers-codes/' + voucherCode.id}
                className='text-decoration-none'
              >
                show
              </Link>
            </td>
            <td data-title='points' className='fs-6'>
              <DeleteVoucherCode voucherCode={voucherCode} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default VoucherCodesTable;
