import { Timestamp } from "firebase/firestore";
import { Student } from "./student.entity";
import { Tutor } from "./tutor.entity";

export type FindForMeNowRequest = {
	id: string;
	studentId: string;
	tutorId: string;
	createdAt: Timestamp;
	status: FindForMeNowRequestStatus;
	createdBy: FindForMeNowRequestBy;
	student?: Student;
	tutor?: Tutor;
};
export enum FindForMeNowRequestBy {
	TUTOR = 0,
	STUDENT = 1,
}
export enum FindForMeNowRequestStatus {
	INITIATED = "INITIATED",
	ACCEPTED = "ACCEPTED",
	CANCELED = "CANCELED",
}
