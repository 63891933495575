import { CreateAdmin } from "../entities/admin.entity";
import { adminRepository } from "../repositories/admin.repository";
import { Admin } from "../entities/admin.entity";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { DocumentData } from "@firebase/firestore-types";

export const createAdmin = async ({
	email,
	password,
	name,
}: CreateAdmin): Promise<{ data: Admin } | { error: boolean }> => {
	try {
		const emailIsUsed = await checkIsEmailIsUsed(email);
		if (emailIsUsed) return { error: true };

		await createUserWithEmailAndPassword(auth, email, password);
		const newAdmin = await adminRepository.create({ name, email });
		return {
			data: {
				id: newAdmin,
				email,
				name,
			},
		};
	} catch (error) {
		return { error: true };
	}
};

export type FindAdminsOptions = {
	offset: DocumentData;
	limit: number;
};

export const findAdminsByQuery = async (
	query: FindAdminsOptions | {}
): Promise<{ data: { admins: Admin[] } } | { error: boolean }> => {
	try {
		const admins = await adminRepository.findAll();
		return { data: { admins } };
	} catch (error) {
		return { error: true };
	}
};

const checkIsEmailIsUsed = async (email: string): Promise<boolean> => {
	const admin = await adminRepository.checkFieldIsUnique("email", email);
	return !admin;
};
