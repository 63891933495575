import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaFileImage } from 'react-icons/fa6';
import { ChatSummary } from '../../../types/chat-support/chat-support.type';
import { useChatSupport } from '../../../context/chat-support/chat-support.context';
import { UserCardWithImageAndName } from '../../shared/molecules/user-card-with-profile-name';
import { ChatStatus, MessageType } from '../../../entities/chat-support.entity';
import { timeAgo } from '../../../utils/date.util';
import { ChatSummaryReadingIcon } from '../atoms/chat-summary-reading-icon';
import { chatSummaryStateHelper } from '../../../services/chat-support/chat-support-state.service';
import { useDocument } from 'react-firebase-hooks/firestore';
import { FireDoc } from '../../../types/util.types';

type Props = {
  summary: ChatSummary;
};
export const ChatSummaryBox = ({ summary }: Props) => {
  const [chatSummary, setChatSummary] = useState<ChatSummary>(summary);

  const [snapshot, loading, error] = useDocument(summary.docRef, {
    snapshotListenOptions: {
      includeMetadataChanges: true
    }
  });

  const {
    states: {
      activeChatRoom: { setActiveChatRoom, activeChatRoom },
      filter: { filter },
      chats: { setChats }
    },
    actions
  } = useChatSupport();

  const isActiveChat = useMemo(
    () => activeChatRoom && activeChatRoom.id === chatSummary.id,
    [activeChatRoom, chatSummary]
  );

  const handleListner = useCallback(async () => {
    if (!snapshot || snapshot!.metadata.hasPendingWrites) return;
    const docData: Omit<ChatSummary, 'user' | 'lastMessage'> = {
      docRef: snapshot!.ref,
      id: snapshot!.id,
      ...(snapshot!.data() as FireDoc<
        Omit<ChatSummary, 'user' | 'lastMessage'>
      >['DocNoIdAndRef'])
    };
    const fullDoc: ChatSummary = docData as ChatSummary; // i will add types later
    fullDoc.lastMessage = await actions.getchatSummaryLastMessage(fullDoc);
    fullDoc.user = chatSummary.user;
    const isStateUpdateNeed = chatSummaryStateHelper.isUpdateNeeded(
      chatSummary,
      fullDoc
    );
    if (isStateUpdateNeed) {
      const newChatSummary = fullDoc;
      setChatSummary(newChatSummary);
      if (isActiveChat) {
        const isActiveRoomNeedUpdate = chatSummaryStateHelper.isUpdateNeeded(
          activeChatRoom,
          newChatSummary
        );
        isActiveRoomNeedUpdate && setActiveChatRoom(newChatSummary);
      }
    }
  }, [snapshot, activeChatRoom, filter, setChats]);
  useEffect(() => {
    if (snapshot) handleListner();
  }, [snapshot]);

  useEffect(() => {
    if (!isActiveChat) return;
    const isStateUpdateNeed = chatSummaryStateHelper.isUpdateNeeded(
      activeChatRoom,
      chatSummary
    );
    if (isStateUpdateNeed) setChatSummary(activeChatRoom);
  }, [activeChatRoom, isActiveChat, chatSummary]);
  useEffect(() => {
    if (error) {
      alert(error.message + '  firestore listner error');
    }
  }, [error]);
  const cleanChatSummaryFromChatListNav = useCallback(() => {
    if (filter.status === 'All') return;
    const currentChatFilter: ChatStatus =
      filter.status === 'Open' ? ChatStatus.OPEN : ChatStatus.CLOSED;
    const isChatListShouldfilter = currentChatFilter !== chatSummary.status;
    if (isChatListShouldfilter) {
      setChats((old) => old.filter((chat) => chat.id !== chatSummary.id));
    }
  }, [chatSummary, filter, setChats]);
  useEffect(() => {
    cleanChatSummaryFromChatListNav();
  }, [cleanChatSummaryFromChatListNav]);
  return (
    <div
      className={`tw-p-2 tw-flex  tw-items-center  hover:tw-bg-brand-gray/10 tw-cursor-pointer  ${
        isActiveChat ? ' hover:tw-bg-brand-gray/80  tw-bg-brand-gray/80' : ''
      }  `}
      onClick={() => {
        setActiveChatRoom({ ...chatSummary });
      }}
    >
      <UserCardWithImageAndName user={chatSummary.user}>
        {chatSummary.lastMessage.type === MessageType.IMAGE ? (
          <FaFileImage />
        ) : (
          <span className={` tw-text-ellipsis	 tw-text-xs text-slate-400 `}>
            {chatSummary.lastMessage.content}
          </span>
        )}
      </UserCardWithImageAndName>

      <div className='tw-ms-auto tw-flex tw-flex-col tw-items-center tw-text-xs'>
        <span className='tw-block tw-mb-2 tw-text-slate-400'>
          {timeAgo(chatSummary.lastMessage.createdAt.toDate())}
        </span>

        <div className='tw-text-[1.1rem]'>
          <ChatSummaryReadingIcon
            isActiveChat={isActiveChat}
            unreadCount={chatSummary.unreadCount}
          />
        </div>
      </div>
    </div>
  );
};
