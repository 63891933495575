import { Col, Form, Row } from "react-bootstrap";
import { FindForMeNowRequestStatus, FindForMeNowRequest } from "../../entities/findForMeNow.entity";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchByDateForm from "../SearchByDateForm";

// booking filter will be false or undefined because old data was not contains this field
type FindForMeNowRequestsFilter = {
    status?: FindForMeNowRequestStatus;
    tutorId?: string;
    studentId?: string;
    createdAt?: Date[]
}

const FindForMeNowRequestsFilterForm = ({ setFilterQuery }: { setFilterQuery: (filter: FindForMeNowRequestsFilter) => void}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [filter, setFilter] = useState<FindForMeNowRequestsFilter>({
        tutorId: searchParams.get('tutorId') || undefined,
        studentId: searchParams.get('studentId') || undefined,
    });

    const handleStatusChange = (status: FindForMeNowRequestStatus | "all") => {
        setFilterQuery({ ...filter, status: status === "all" ? undefined : status });
        setFilter({ ...filter, status: status === "all" ? undefined : status });
    }
    
    const handleSearchByDateChange = ({ startDate, endDate }: { startDate: Date, endDate: Date }) => {
        setFilterQuery({...filter, createdAt: [startDate, endDate] });
        setFilter({...filter, createdAt: [startDate, endDate] });
    }

    return (
        <>
            <Form.Select
                defaultValue="all"
                onChange={(e) => handleStatusChange(e.target.value as FindForMeNowRequestStatus | "all")}
            >
                <option value="all">all requests</option>
                <option value={FindForMeNowRequestStatus.INITIATED}>{FindForMeNowRequestStatus.INITIATED.toLowerCase()}</option>
                <option value={FindForMeNowRequestStatus.ACCEPTED}>{FindForMeNowRequestStatus.ACCEPTED.toLowerCase()}</option>
                <option value={FindForMeNowRequestStatus.CANCELED}>{FindForMeNowRequestStatus.CANCELED.toLowerCase()}</option>
            </Form.Select>
            <div className="mt-3">
                <SearchByDateForm updateQuery={handleSearchByDateChange}/>
            </div>
        </>
    )
}

export default FindForMeNowRequestsFilterForm;