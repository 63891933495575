import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { WithdrawalRequest, WithdrawalRequestStatus } from "../../entities/withdrawalRequest.entity";
import { useUpdateWithdrawalRequestByIdMutation } from "../../store/tutorWithdrawalRequest.store";

interface AcceptWithdrawalRequest {
    onHide: () => void;
    withdrawalRequest: WithdrawalRequest;
    [key: string]: any;
}
  
const AcceptWithdrawalRequestModal: FC<AcceptWithdrawalRequest> = ({ onHide, withdrawalRequest, ...props }) => {
    const [updateWithdrawalRequest, updateWithdrawalRequestResult] = useUpdateWithdrawalRequestByIdMutation();
    useEffect(() => {
        if (updateWithdrawalRequestResult.isSuccess)
            onHide()
    },[updateWithdrawalRequestResult, onHide]);

    const handleAccept = () => {
        updateWithdrawalRequest({ id: withdrawalRequest.id, newData: { status: WithdrawalRequestStatus.APPROVED} })
    }
    return (
        <Modal
            {...props}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-6'>Do you accept tutor withdrawal request ?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center fw-bold">
                &euro;{ withdrawalRequest.amount } 
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button 
                    variant="danger" 
                    onClick={handleAccept}
                    disabled={updateWithdrawalRequestResult.isLoading}
                >Accept</Button>
            </Modal.Footer>
        </Modal>
    );
};

const AcceptWithdrawalRequest = ({ withdrawalRequest }: { withdrawalRequest: WithdrawalRequest}) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Approve
            </Button>

            <AcceptWithdrawalRequestModal
                show={modalShow}
                withdrawalRequest={withdrawalRequest}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default AcceptWithdrawalRequest;