import React, { FormEvent, useState } from "react"
import { InputGroup, Button, Form } from "react-bootstrap"

type Props = {
    handleSearch: (value: string) => void,
    placeholder: string
}

const SearchForm = ({ handleSearch, placeholder } : Props) => {
    const [lastSubmitedValue, setLastSubmitedValue] = useState<string>();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const inputValue = (event.target as any).elements["input"].value.trim();
        if (inputValue===lastSubmitedValue)
            return;
        setLastSubmitedValue(inputValue);
        handleSearch(inputValue); 

    }
    return (
        <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder={placeholder}
                    name="input"
                />
                <Button variant="outline-secondary" type="submit" >
                    Search
                </Button>
            </InputGroup>
        </Form>
    )
}

export default SearchForm;