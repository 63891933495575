import { FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import { useUpdateStudentByIdMutation } from "../../../store/student.store";
import { Student } from "../../../entities/student.entity";
import { Navigate } from "react-router-dom";

interface MakeStudentPrivateProps {
    onHide: () => void;
    student: Student;
    [key: string]: any;
}

const MakeStudentPrivateModal: FC<MakeStudentPrivateProps> = ({ onHide, student, ...props }) => {
    const [updateStudentById, updateStudentByIdResult] = useUpdateStudentByIdMutation();
    const [isCorrectName, setIsCorrectName] = useState<boolean>();

    useEffect(() => {
        if (updateStudentByIdResult.isSuccess)
            onHide()
    },[updateStudentByIdResult, onHide]);

    const handleAccept = () => {
        updateStudentById({ id: student.id, newData: { isPrivate: true } })
    }
    
    return (
        <Modal
            {...props}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='fs-6'>Do you accept make the student account private ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>student name: {student.name}</p>
                <InputGroup className="mt-2 mb-3">
                    <Form.Control
                        placeholder="Enter Student Name"
                        onChange={(e) => setIsCorrectName(e.target.value == student.name)}
                    />
                </InputGroup>
            </Modal.Body>
            <Modal.Footer >
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button 
                    variant="danger" 
                    onClick={handleAccept} 
                    disabled={!isCorrectName || updateStudentByIdResult.isLoading}
                >Make Private</Button>
            </Modal.Footer>
        </Modal>
    );
};

const MakeStudentPrivate = ({ student }: { student: Student}) => {
    const [modalShow, setModalShow] = useState(false);
    return (
        <>
            <Button variant="danger" onClick={() => setModalShow(true)}>
                make student private
            </Button>

            <MakeStudentPrivateModal
                show={modalShow}
                student={student}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default MakeStudentPrivate;