import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FC } from 'react';
import {
  VoucherCode,
  VoucherCodeStatus
} from '../../../../entities/voucherCode.entity';
import { useDeleteVoucherCodeMutation } from '../../../../store/voucherCode.store';
import { showToast } from '../../../../utils/toast.util';

interface DeleteVoucherCodeProps {
  onHide: () => void;
  voucherCode: VoucherCode;
  [key: string]: any;
}

const DeleteVoucherCodeModal: FC<DeleteVoucherCodeProps> = ({
  onHide,
  voucherCode,
  ...props
}) => {
  const [deleteVoucherCode, deleteVoucherCodeResult] =
    useDeleteVoucherCodeMutation();
  useEffect(() => {
    if (deleteVoucherCodeResult.isSuccess) onHide();
  }, [deleteVoucherCodeResult, onHide]);
  return (
    <Modal
      {...props}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6'>
          do you want to delete the voucher code ?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center fw-bold'>
        {voucherCode.code}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
        <Button
          variant='danger'
          onClick={() => {
            if (voucherCode.status === VoucherCodeStatus.REDEEMED) {
              showToast('warrning', 'you cannot remove redeemed voucher');
              return;
            }

            deleteVoucherCode(voucherCode);
          }}
          disabled={deleteVoucherCodeResult.isLoading}
        >
          delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteVoucherCode = ({ voucherCode }: { voucherCode: VoucherCode }) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant='danger' onClick={() => setModalShow(true)}>
        delete
      </Button>

      <DeleteVoucherCodeModal
        show={modalShow}
        voucherCode={voucherCode}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default DeleteVoucherCode;
