import { ChatSupportContextType } from '../../context/chat-support/chat-support.context';
import {
  ChatMessage,
  ChatStatus,
  ChatSupport
} from '../../entities/chat-support.entity';
import { ChatSupportRepository } from '../../repositories/chat-support.repository';
import {
  ActiveChatMessage,
  ChatStatusFilter,
  ChatSummary,
  NewMessageBeforeSave
} from '../../types/chat-support/chat-support.type';
import { RepoistoryFilter } from '../../types/util.types';
import { Dispatch, SetStateAction } from 'react';
import { showToast } from '../../utils/toast.util';
import { ChatSupportServiceI } from '../../types/chat-support/ChatSupportServiceI';
import { regex } from '../../utils/regex.util';
import { removeDuplicatesFromOldArrById } from '../../utils/util';

export class ChatSupportService implements ChatSupportServiceI {
  private contextState: ChatSupportContextType['states'];
  private static ChatSupportStorageFolder = 'chat_support_messages/';
  constructor(private chatSupportRepoistory: ChatSupportRepository) {}

  bindContextState(contextState: ChatSupportContextType['states']) {
    this.contextState = contextState;
    return this;
  }

  //  chat nav - header component methods
  async getChatSummaryies(
    filter: RepoistoryFilter<ChatSupport>,
    statusFilter: ChatStatusFilter
  ) {
    try {
      const status = this.statusFilterToEnum(statusFilter);
      const data = await this.chatSupportRepoistory.getChatsByState(
        filter,
        status
      );
      const docsCount = await this.chatSupportRepoistory.getChatCountsByState(
        status
      );
      this.contextState.chats.setChats(data);
      return { totalDocsCount: docsCount };
    } catch (error) {
      console.log(error);
      showToast('error', 'fetch chats faild');
    } finally {
      this.contextState.chats.setIsFetching(false);
    }
  }

  // chat list component methods
  async scrollFetchChats() {
    const currentFilter = this.contextState.filter.filter;
    currentFilter.offsetDocId =
      this.contextState.chats.chats[
        this.contextState.chats.chats.length - 1
      ].id;

    const fetchedChats = await this.chatSupportRepoistory.getChatsByState(
      currentFilter,
      this.statusFilterToEnum(currentFilter.status)
    );

    this.contextState.chats.setChats((old) => {
      const filterFetchedFromDuplicates = removeDuplicatesFromOldArrById(
        fetchedChats,
        old
      );
      return [...old, ...filterFetchedFromDuplicates];
    });

    const isMoreToFetch =
      fetchedChats.length === this.getCurrentFilter('limit');
    return isMoreToFetch;
  }

  getChatSummariesWithLastMessageAndUserData(
    chats: Omit<ChatSummary, 'lastMessage' | 'user'>[]
  ): Promise<ChatSummary[]> {
    return this.chatSupportRepoistory.getChatSummariesWithLastMessageAndUserData(
      chats
    );
  }

  async getchatSummaryLastMessage(doc: ChatSummary): Promise<ChatMessage> {
    const lastMessage =
      this.getActiveChatRoom() && this.getActiveChatRoom().id === doc.id
        ? this.getActiveChatRoom().lastMessage
        : await this.chatSupportRepoistory.getLastMessageByRef(doc.docRef);
    return lastMessage;
  }

  // active chat components methods
  async updateServerUnRead() {
    const currentDoc = this.contextState.activeChatRoom.activeChatRoom;
    await this.chatSupportRepoistory.update(currentDoc.id, {
      unreadCount: {
        support: 0,
        user: currentDoc.unreadCount.user
      }
    });
  }
  async changeChatStatus(newStatus: ChatStatus) {
    const currentDocId = this.contextState.activeChatRoom.activeChatRoom.id;
    //  update doc
    await this.chatSupportRepoistory.update(currentDocId, {
      status: newStatus
    });
    this.contextState.activeChatRoom.setActiveChatRoom((old) => ({
      ...old,
      status: newStatus
    }));
  }

  async sendMessage(
    newMessage: NewMessageBeforeSave,
    setProgress: Dispatch<SetStateAction<string>>
  ): Promise<void> {
    if (newMessage.file) {
      try {
        const imgUrl = await this.chatSupportRepoistory.uploadFileToStorage(
          newMessage.file,
          ChatSupportService.ChatSupportStorageFolder + Date.now() + File.name,
          setProgress
        );
        newMessage.content = imgUrl;

        delete newMessage.file;
      } catch (e) {
        showToast('error', 'Image Upload Faild');
        console.error(e, 'img upload errro');
        throw e;
      }
    }
    const currentActiveChat = this.getActiveChatRoom();

    await this.chatSupportRepoistory.saveNewMessage(
      currentActiveChat,
      newMessage
    );
  }

  async getOldMessages(
    lastMessage: ActiveChatMessage,
    setMessages: Dispatch<SetStateAction<ActiveChatMessage[]>>,
    limitedDocs: number
  ): Promise<{ isMoreOldMSGs: boolean }> {
    const currentdocRef = this.getActiveChatRoom().docRef;
    const studentData = this.getActiveChatRoom().user;

    const messages = await this.chatSupportRepoistory.fetchOldMessagesByRef(
      currentdocRef,
      lastMessage.id,
      limitedDocs
    );
    const messagesWithStudents = this.mapChatMessageWithUser(
      messages,
      studentData
    );
    setMessages((descMSGs) => [...descMSGs, ...messagesWithStudents]);

    return { isMoreOldMSGs: messagesWithStudents.length === limitedDocs };
  }

  validateImg(imageFile: File): boolean {
    let isImgValid = true;
    const imgLarge = imageFile.size > 8 * 1024 * 1024;
    if (imgLarge) {
      showToast('warrning', 'Image must be less than 8 MB.');
      isImgValid = false;
    }
    const isImgType = regex.img.test(imageFile.type);
    if (!isImgType) {
      showToast('warrning', 'Make sure to upload image only');
      isImgValid = false;
    }
    return isImgValid;
  }
  // helper

  mapChatMessageWithUser(
    data: Omit<ActiveChatMessage, 'user'>[],
    userData: ChatSummary['user']
  ): ActiveChatMessage[] {
    const userId = this.getActiveChatRoom().user.userId;
    return data.map((message) => {
      const user = message.senderId === userId ? userData : undefined;

      return { ...message, user };
    });
  }
  getActiveChatRoom() {
    return this.contextState.activeChatRoom.activeChatRoom;
  }

  private getCurrentFilter<K extends keyof RepoistoryFilter>(
    key: K
  ): RepoistoryFilter<ChatSupport>[K] | undefined {
    return this.contextState.filter.filter[key];
  }

  private statusFilterToEnum(status: ChatStatusFilter): ChatStatus | undefined {
    if (status === 'All') return undefined;
    switch (status) {
      case 'Open':
        return ChatStatus.OPEN;
      case 'Closed':
        return ChatStatus.CLOSED;
    }
  }
}

// get all => push data state

// snapShot on fetchedData
