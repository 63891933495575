import { useLocation, useNavigate } from 'react-router-dom';
import { Organization } from '../../../entities/organization.entity';
import PageHeader from '../../pageHeader';
import { useMemo, useRef, useState } from 'react';
import { OrganizationsStudentsTableHeader } from './molecules/organization-students-table-header';
import {
  ReusableTable,
  TableHeader
} from '../../shared/organisms/resuable-table';
import { useReactToPrint } from 'react-to-print';
import { OrganizationStudent } from '../../../types/organization/organization-students.types';

type Props = {
  allStudentsData: OrganizationStudent[];
};

export const OrganizationStudents = ({ allStudentsData }: Props) => {
  const { state } = useLocation() as { state: { organization: Organization } };
  const [serachFilter, setSearchFilter] = useState('');

  const tableHeaders: TableHeader<OrganizationStudent>[] = useMemo(
    () => [
      {
        key: 'name',
        label: 'name'
      },
      {
        key: 'email',
        label: 'email'
      },
      {
        key: 'noOfSessions',
        label: 'sessions count'
      },
      {
        key: 'minsCount',
        label: 'duration  mins'
      },
      {
        key: 'totalPoints',
        label: 'total Points'
      },
      {
        key: 'points',
        label: 'remaining points'
      },
      {
        key: 'activationDate',
        label: 'activation Date'
      }
      // minutes num is missing
    ],
    []
  );
  const navigate = useNavigate();

  const studentDataByFilter = useMemo(() => {
    if (!serachFilter) return allStudentsData;
    const regex = new RegExp(serachFilter, 'i');

    return allStudentsData.filter(
      (student) => regex.test(student.email) || regex.test(student.name)
    );
  }, [serachFilter, allStudentsData]);

  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!
  });

  return (
    <div className=' tw-p-4'>
      <PageHeader text={state.organization.name + ' / students'} />
      <OrganizationsStudentsTableHeader
        handlePrint={() => {
          if (allStudentsData.length < 1) return;
          handlePrint();
        }}
        setSearchFilter={setSearchFilter}
      />

      <div className=' tw-mt-2' ref={componentRef}>
        <ReusableTable<OrganizationStudent>
          rows={studentDataByFilter}
          columns={tableHeaders}
          onLineCick={(student) => navigate('/students/' + student.id)}
        />
      </div>
    </div>
  );
};
