import { Repository } from "../firebase/firestore/repository";
import { Admin } from "../entities/admin.entity";

export class AdminRepository extends Repository<Admin> {
	constructor() {
		super("admins");
	}
	async findByEmail(email: string): Promise<Admin | null> {
		const adminQueryResult = await this.findAll({ email });
		return adminQueryResult.length == 1 ? adminQueryResult[0] : null;
	}
}

export const adminRepository = new AdminRepository();
